<template>

<div id="spotlight-container">
  <a id="spotlight" name="spotlight">IN THE SPOTLIGHT</a>
  <div id="spotlight-panels-container">
    <div class="spotlight-panel"
      v-for="panel in spotlightPanels" :key="panel.url">
      <div class="spotlight-image">
        <img :src="panel.image" />
      </div>
      <div class="spotlight-title" v-if="panel.title" v-html="panel.title"></div>
      <div class="spotlight-date" v-if="panel.date">{{ panel.date }}</div>
      <div class="spotlight-text" v-html="panel.text"></div>
      <a class="spotlight-link" :href="panel.url">Read More</a>
    </div>
  </div>
  <div id="spotlight-pages" v-if="showPages">
    <div id="spotlight-button-container">
      <button 
        @click="getSpotlightPanels(previous)"
        :disabled="previous === '' || previous === null">
        <font-awesome-icon :icon="['fas', 'arrow-left']" />
      </button>
      <button 
        @click="getSpotlightPanels(next)"
        :disabled="meta['current_page'] === meta['last_page']">
        <font-awesome-icon :icon="['fas', 'arrow-right']" />
      </button>
    </div>
  </div>
</div>

</template>



<script>

import media from "../mixins/media.js";
export default {
  name: "SpotlightComponent",
  mixins: [media],
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      spotlightPanels: [],
      showPages: false,
      previous: "",
      next: "",
      meta: {}
    }
  },
  methods: {
    getSpotlightPanels: function(url) {
      return axios({ method: "GET", "url": url }).then(result => {
        let data = result.data.data;
        this.spotlightPanels = data.items;
        this.meta = data.meta;
        if (this.meta["last_page"] > 1) {
          this.previous = data.links.prev;
          this.next = data.links.next;
          this.showPages = true;
        } else {
          this.previous = "";
          this.next = "";
          this.showPages = false;
        }
      }, error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.getSpotlightPanels(process.env.VUE_APP_API_ENDPOINT + "/spotlight");
  }
}

</script>



<style lang="scss">

#spotlight-container {
  @include flex-center;
  flex-direction: column;
  width: 100%;
}

.spotlight-text b {
  @include roboto;
}

#spotlight {
  @include flex-center;
  @include panel-header;
  @include scroll-top;
  height: 80px;
  width: 100%;
  font-weight: bold;
  background-color: $spotlight-color;
  margin-bottom: 0;
}

#spotlight-panels-container {
  @include flex-center;
  @include panel-details;
  align-items: flex-start;
  width: 100%;
  padding: 50px 100px;
}
.spotlight-panel {
  width: 25%;
  margin: 0px 10px;
}
  .spotlight-panel:first-child {
    margin-left: 0;
  }
  .spotlight-panel:last-child {
    margin-right: 0;
  }
  .spotlight-image {
    height: 225px;
    width: 100%;
    margin-bottom: 10px;
  }
    .spotlight-image img {
      @include image-scale;
      object-fit: cover;
    }
  .spotlight-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .spotlight-date {
    margin-bottom: 10px;
  }
  .spotlight-text {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    margin-bottom: 10px;
  }
    // .spotlight-text b {
    //   font-size: 16px;
    // }
  .spotlight-link {
    font-weight: bold;
    color: black;
  }

#spotlight-pages {
  @include flex-center;
  width: 100%;
  margin-bottom: 50px;
}
  #spotlight-button-container {
    @include flex-center;
    justify-content: space-between;
    width: 100px;
  }
  #spotlight-pages button {
    @include button-styles;
    padding: 10px 12px;
    border: 1px solid $mwnf-color;
  }
  
@media only screen and (max-width: 1274px) {
  #spotlight-panels-container {
    padding: 50px 60px;
  }
}

@media only screen and (max-width: 1199px) {
  #spotlight {
    @include scroll-top-increased;
  }
}

@media only screen and (max-width: 974px) {
  #spotlight {
    @include panel-header-reduced;
    margin-bottom: 0;
  }
  #spotlight-panels-container {
    @include panel-details-reduced;
  }
  .spotlight-image {
    height: 175px;
  }
}

@media only screen and (max-width: 874px) {
  #spotlight-panels-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    padding: 30px;
  }
    .spotlight-panel {
      width: 100%;
      margin: 0;
    }
}

@media only screen and (max-width: 599px) {
  #spotlight {
    @include scroll-top-decreased;
  }
}

@media only screen and (max-width: 424px) {
  #spotlight {
    @include panel-header-smallest;
    margin-bottom: 0;
  }
  #spotlight-panels-container {
    grid-gap: 20px;
    padding: 20px;
  }
}

</style>
