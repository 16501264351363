<template>

<div id="profile-container">
  <LoaderComponent v-if="!loaded" />
  <div id="profile-content" v-else>
    <div class="profile-title">{{ profile.pageTitle }}</div>
    <div class="profile-text" v-html="profile.atrium_profile_text"></div>
    <div class="profile-title">{{ profile.atrium_profile_text2_head }}</div>
    <div class="profile-text" v-html="profile.atrium_profile_text2"></div>
  </div>
</div>

</template>


<script>

import LoaderComponent from "./LoaderComponent.vue";
import media from "../mixins/media.js";
export default { 
  name: "ProfileComponent",
  mixins: [media],
  components: {
    "LoaderComponent": LoaderComponent
  },
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      profile: {},
      loaded: false
    }
  },
  methods: {
    getProfile: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/atrium-about" }).then(result => {
        this.profile = result.data.data;
        this.loaded = true;
      }, error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.getProfile();
  }
}

</script>



<style scoped lang="scss">

#profile-container {
  @include flex-center;
  width: 100%;
  color: $light-text;
  background-color: $about-color;
  padding: 50px;
}
.loader-container {
  height: 100%;
  background-color: $about-color;
}
#profile-content {
  width: 50%;
}
  // #profile-content :deep(*) {
  //   @include roboto;
  // }
.profile-title {
  @include about-title;
}
.profile-text {
  @include about-text;
  margin-bottom: 15px;
}
  .profile-text :deep(a) {
    color: $light-text;
  }

@media only screen and (max-width: 1199px) {
  #profile-content {
    width: 60%;
  }
}

@media only screen and (max-width: 974px) {
  #profile-content {
    width: 75%;
  }
}

@media only screen and (max-width: 699px) {
  #profile-content {
    width: 100%;
  }
  .profile-title {
    @include about-title-reduced;
  }
  .profile-text {
    @include about-text-reduced;
  }
}

@media only screen and (max-width: 499px) {
  #profile-container {
    padding: 30px;
  }
}

</style>