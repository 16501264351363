<template>

<div id="newsletter-signup-wrapper">

  <BackComponent />

  <div id="newsletter-success" v-if="success">
    Thank you for signing up!
    <br>
    <br>
    A confirmation email should be delivered to your inbox shortly.
  </div>

  <div id="newsletter-container" v-else>
    <div id="newsletter-required">
      Please fill out the form below in order to complete your newsletter sign-up.
      All fields are required.
    </div>
    <form>
      <div class="field-wrapper">
        <label for="first">First Name</label>
        <input type="text" 
          id="newsletter-first" 
          name="first" 
          v-model="first"
          placeholder="First Name">
      </div>
      <div class="field-wrapper">
        <label for="last">Last Name</label>
        <input type="text" 
          id="newsletter-last" 
          name="last" 
          v-model="last"
          placeholder="Last/Family Name">
      </div>
      <div class="field-wrapper">
        <label for="country">Country</label>
        <select id="newsletter-country" 
          name="country" v-model="country"
          :class="{ 'country-placeholder': country === '' }">
          <option
            value="" disabled selected>
            Select Country
          </option>
          <option 
            v-for="country in countryList" :key="country"
            :value="country">
            {{ country }}
          </option>
        </select>
      </div>
      <button id="newsletter-sign-up-button" type="submit"
        @click="signup($event)">
        Sign Up
      </button>
    </form>
  </div>

</div>

</template>



<script>

import BackComponent from "../components/BackComponent.vue";
import media from "../mixins/media.js";
export default {
  name: "SignUp",
  props: ["email"],
  components: {
    "BackComponent": BackComponent
  },
  mixins: [media],
  data() {
    return {
      first: "",
      last: "",
      country: "",
      countryList: [],
      success: false
    }
  },
  methods: {
    getCountries: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/country-list" }).then(result => {
        this.countryList = result.data;
      }, error => {
        console.error(error);
      });
    },
    signup: function(e) {
      e.preventDefault();
      return axios({ method: "POST",
        url: process.env.VUE_APP_API_ENDPOINT + "/newsletter2",
        data: {
          email: this.email,
          first_name: this.first,
          last_name: this.last,
          country: this.country
        } 
      }).then(response => {
        this.success = true;
        this.$emit("signup", this.success);
      }, error => {
        alert ("Please fill out all fields!");
        console.error(error);
      })
    },
  },
  mounted() {
    this.getCountries();
  }
}

</script>



<style lang="scss">

#newsletter-signup-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 100px 75px 100px;
}

#newsletter-success {
  @include flex-center;
}

#newsletter-container {
  @include flex-center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

#newsletter-required {
  margin-bottom: 50px;
}

#newsletter-container form {
  display: flex;
  flex-direction: column;
  width: 350px;
}
.field-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}
.field-wrapper label {
  font-weight: bold;
}
.field-wrapper input,
.field-wrapper select {
  height: 50px;
  width: 100%;
  color: $light-text;
  background-color: rgb(65, 55, 136);
  border-radius: 5px;
  border-style: none;
  padding: 10px;
}
  .field-wrapper input::placeholder {
    color: $light-text;
    opacity: 0.6;
  }
  #newsletter-country.country-placeholder {
    color: rgba($light-text, 0.6);
  }
    #newsletter-country option {
      color: $light-text;
    }

#newsletter-sign-up-button {
  @include button-styles;
  display: inline-block;
  height: 50px;
  width: 100%;
  background-color: $bright-yellow;
  border-radius: 5px;
}

@media only screen and (max-width: 599px) {
  #newsletter-signup-wrapper {
    padding: 0 30px 75px 30px;
  }
}

@media only screen and (max-width: 499px) {
  #newsletter-container form {
    width: 100%;
  }
}

</style>
