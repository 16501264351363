<template>

<div id="newsletters-container">
  <LoaderComponent v-if="!loaded" />
  <div id="newsletters-wrapper" v-else>
    <BackComponent v-if="$route.params.newsletter" />
    <div v-if="!$route.path.includes('archive') && !$route.params.newsletter"
      class="newsletter-type-link">
      <router-link :to="{ name: 'archive' }">See Archived Newsletters</router-link>
    </div>
    <div v-else-if="!$route.params.newsletter" class="newsletter-type-link">
      <router-link :to="{ name: 'newsletters' }">See Current Newsletters</router-link>
    </div>
    <div id="newsletters-list-container" v-if="!$route.params.newsletter">
      <div id="newsletter-panels">
        <a @click="goToNewsletter(newsletter.read_more)" class="newsletter-wrapper" 
          v-for="newsletter in newsletters" :key="newsletter.title">
          <div class="newsletter-image newsletter-item">
            <img :src="newsletter.image" />
          </div>
          <div v-html="newsletter.title" class="newsletter-title newsletter-item"></div>
          <div v-html="newsletter.date" class="newsletter-date newsletter-item"></div>
          <div v-html="truncate(150, newsletter.message)" class="newsletter-message newsletter-item"></div>
          <div class="newsletter-institution newsletter-item">
            <span v-if="newsletter.institution">{{ $i18n.t('newsletterInstitution') }}&nbsp;</span><span v-html="newsletter.institution"></span>
          </div>
        </a>
      </div>
      <div id="newsletter-page-navigation" class="page-navigation-wrapper">
        <PagesComponent :pageInfo="pageInfo" :navigatePage="navigatePage" :navigatePageDirect="navigatePageDirect"></PagesComponent>
      </div>
    </div>
    <div id="full-newsletter-container" v-else>
      <div class="full-newsletter-image full-newsletter-item">
        <img :src="newsletter.image" />
      </div>
      <div id="full-newsletter-text-container">
        <div v-html="newsletter.title" class="newsletter-title newsletter-item"></div>
        <div v-html="newsletter.date" class="full-newsletter-date newsletter-item"></div>
        <div v-html="newsletter.message" class="full-newsletter-message newsletter-item"></div>
        <div class="full-newsletter-institution newsletter-item">
          <span v-if="newsletter.institution">{{ $i18n.t('newsletterInstitution') }}&nbsp;</span><span v-html="newsletter.institution"></span>
        </div>
      </div>
    </div>
  </div>
</div>

</template>


<script>

import { loadLocaleMessages } from "../i18n.js";
import PagesComponent from "./PagesComponent.vue";
import LoaderComponent from "./LoaderComponent.vue";
import BackComponent from "./BackComponent.vue";
import functions from "../mixins/functions.js";
import media from "../mixins/media.js";
export default { 
  name: "NewslettersPage",
  components: {
    "PagesComponent": PagesComponent,
    "LoaderComponent": LoaderComponent,
    "BackComponent": BackComponent
  },
  mixins: [functions, media],
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      newsletters: [],
      newsletter: {},
      pageInfo: {},
      pages: {},
      loaded: false
    }
  },
  watch: {
    $route(to, from) {
      this.loaded = false;
      this.getNewslettersInfo();
    }
  },
  methods: {
    goToNewsletter: function(link) {
      let code = this.getNewsletterCode(link);
      this.$router.push({ name: "newsletters", params: { newsletter: code } });
    },
    getNewslettersInfo: function() {
      let apiURL;
      if (this.$route.path.includes("archive")) {
        apiURL = "/newsletter-archive";
      } else {
        apiURL = "/newsletter-info"
      }
      if (this.$route.params.newsletter) {
        return axios({ method: "GET", "url": `${process.env.VUE_APP_API_ENDPOINT}${apiURL}/${this.$route.params.newsletter}` }).then(result => {
          this.newsletter = result.data.data;
          this.loaded = true;
          console.log(this.newsletter);
        }, error => {
          console.error(error);
        });
      } else {
        let pageNumber;
        if (!this.$route.params.page) {
          pageNumber = 1;
        } else if (this.$route.params.page) {
          pageNumber = this.$route.params.page;
        }
        return axios({ method: "GET", "url": `${process.env.VUE_APP_API_ENDPOINT}${apiURL}?page=${pageNumber}` }).then(result => {
          this.newsletters = result.data.data.items;
          this.pageInfo = result.data.data.meta;
          this.pages = result.data.data.links;
          this.loaded = true;
        }, error => {
          console.error(error);
        });
      }
    },
    getPageNumber: function(url) {
      let start = url.lastIndexOf("=") + 1;
      return url.substring(start);
    },
    navigatePage: function(page) {
      let number;
      if (page === "first") {
        number = this.getPageNumber(this.pages.first);
      } else if (page === "last") {
        number = this.getPageNumber(this.pages.last);
      } else if (page === "next") {
        number = this.getPageNumber(this.pages.next);
      } else if (page === "previous") {
        number = this.getPageNumber(this.pages.prev);
      }
      if (this.$route.path.includes("archive")) {
        this.$router.push({ name: "archive", params: { page: number } });
      } else {
        this.$router.push({ name: "newsletters", params: { page: number } });
      }
      // this.$router.push({ name: "newsletters", params: { page: number } });
    },
    // Navigate directly to a page by clicking a number
    navigatePageDirect: function(page) {
      // this.$router.push({ name: "newsletters", params: { page: page } });
      if (this.$route.path.includes("archive")) {
        this.$router.push({ name: "archive", params: { page: page } });
      } else {
        this.$router.push({ name: "newsletters", params: { page: page } });
      }
    },
    getNewsletterCode: function (text) {
      let index = text.lastIndexOf("/") + 1;
      return text.substring(index);
      // console.log(code);
    },
  },
  mounted() {
    console.log(this.$route.params);
    this.getNewslettersInfo();
  },
  // Load i18n messages in correct language
  beforeRouteEnter(to, from, next) {
    loadLocaleMessages("en").then(() => next()); 
  },
  beforeRouteUpdate(to, from, next) {
    loadLocaleMessages("en").then(() => next());
  }
}

</script>



<style scoped lang="scss">

#newsletters-container {
  @include flex-center;
  flex-direction: column;
  width: 100%;
  color: $light-text;
  background-color: $about-color;
  padding: 50px 100px;
}
#newsletters-wrapper {
  @include flex-center;
  flex-direction: column;
}
.loader-container {
  height: 100%;
  background-color: $about-color;
}

.newsletter-type-link {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: $light-text;
  margin-bottom: 30px;
}
  .newsletter-type-link a {
    color: $light-text;
  }

#newsletter-panels {
  @include flex-center;
  align-items: flex-start;
  width: 100%;
}

.newsletter-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 20px;
  cursor: pointer;
}
  .newsletter-wrapper:first-child {
    margin-left: 0;
  }
  .newsletter-wrapper:last-child {
    margin-right: 0;
  }
  .newsletter-image {
    height: 200px;
    width: 100%;
    background-color: rgba($mwnf-color, 0.1);
  }
    .newsletter-image img {
      @include image-scale;
      object-fit: contain;
    }
  .newsletter-title {
    font-weight: bold;
    color: $bright-yellow;
  }
  .newsletter-date,
  .newsletter-message,
  .newsletter-institution {
    font-size: 14px;
  }
  .newsletter-institution span {
    white-space: pre;
  }
  .newsletter-item {
    margin-bottom: 10px;
  }

#newsletter-page-navigation {
  margin-top: 50px;
}

#full-newsletter-container {
  display: flex;
  width: 80%;
}
  .full-newsletter-image {
    height: 100%;
    width: 35%;
  }
    .full-newsletter-image img {
      @include image-scale;
      object-fit: contain;
    }
  #full-newsletter-text-container {
    width: 65%;
    padding-left: 30px;
  }
  #full-newsletter-text-container :deep(a) {
    color: $light-text;
  }

#newsletters-container #back {
  width: 80%;
}

@media only screen and (max-width: 1199px) {
  #newsletters-container {
    padding: 50px;
  }
}

@media only screen and (max-width: 899px) {
  #newsletter-panels {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
  .newsletter-wrapper {
    margin: 0;
  }
  #full-newsletter-container {
    width: 100%;
  }
  #newsletters-container #back {
    width: 100%;
  }
}

@media only screen and (max-width: 674px) {
  #full-newsletter-container {
    flex-direction: column;
  }
  .full-newsletter-image {
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
  }
  #full-newsletter-text-container {
    width: 100%;
    padding-left: 0;
  }
}

@media only screen and (max-width: 549px) {
  #newsletter-panels {
    @include flex-center;
    flex-direction: column;
  }
}

@media only screen and (max-width: 499px) {
  #newsletters-container {
    padding: 30px;
  }
}

</style>