<template>

<div id="important-information-wrapper">

  <BackComponent />

  <div id="important-information-container">
    <div id="important-information" v-html="$i18n.t('searchExplanation')"></div>
  </div>

</div>

</template>



<script>

import { loadLocaleMessages } from "../i18n.js";
import BackComponent from "../components/BackComponent.vue";
import media from "../mixins/media.js";
export default {
  name: "ImportantInformation",
  components: {
    "BackComponent": BackComponent
  },
  mixins: [media],
  // Load i18n messages in correct language
  beforeRouteEnter(to, from, next) {
    loadLocaleMessages("en").then(() => next()); 
  },
  beforeRouteUpdate(to, from, next) {
    loadLocaleMessages("en").then(() => next());
  }
}

</script>



<style lang="scss">

#important-information-wrapper {
  padding: 30px 50px 100px 50px;
}

#important-information-container {
  @include flex-center;
  height: 100%;
  width: 100%;
}
  #important-information {
    width: 60%;
  }

@media only screen and (max-width: 1099px) {
  #important-information {
    width: 80%;
  }
}

@media only screen and (max-width: 749px) {
  #important-information {
    width: 100%;
  }
}

</style>
