import { createApp } from "vue"
import { createI18n } from "vue-i18n"
// import { createI18n } from 'vue-i18n/dist/vue-i18n.esm-bundler.js';

// Vue.use(VueI18n)

export function loadLocaleMessages(language) {
    if (!i18n.global.save) {
        i18n.global.save = {};
    }
    if (!i18n.global.save[language]) {
        i18n.global.locale = language;
        return axios({ method: "GET", "url": `${process.env.VUE_APP_API_ENDPOINT}/site-lang` }).then(result => {
            i18n.global.setLocaleMessage(language, result.data[`${language}`]);
            i18n.global.save[language] = result.data[`${language}`];
        }, error => {
            console.error(error);
        });
    } else {
        i18n.global.setLocaleMessage(language, i18n.global.save[language]);
        return Promise.resolve();
    }
}

const i18n = createI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: {}
})

export default i18n;
