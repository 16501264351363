<template>

<div id="resources-wrapper">

  <BackComponent />

  <div id="language-filter-container">
    <select id="language-filter"
      v-model="selected" @change="languageSelect(selected)">
      <option value="" selected disabled>Filter By Language</option>
      <option value="all">All Languages</option>
      <option v-for="(language, key) in languages" :key="language"
        :value="key">
        {{ language }}
      </option>
    </select>
  </div>

  <div id="page-navigation" class="page-navigation-wrapper">
    <PagesComponent :pageInfo="pageInfo" :navigatePage="navigatePage" :navigatePageDirect="navigatePageDirect"></PagesComponent>
  </div>

  <LoaderComponent v-if="!loaded" />
  <div v-else id="resources-container">
    <div id="resources">
      <div class="resource-wrapper"
        v-for="resource in resources" :key="resource.title">
        <div class="resource-image">
          <img :src="resource.image" />
        </div>
        <div class="resource-info-wrapper"
          :dir="resource.displayLang === 'ar' ?  'rtl' : 'ltr'">
          <a class="resource-title" 
            :href="resource.resourceLink" 
            target="_blank" download>
            <span class="resource-icon"
              v-if="resource.resourceType === 'Videos'">
              <font-awesome-icon :icon="['fab', 'youtube-square']" />
            </span>
            <span class="resource-icon"
              v-else-if="resource.resourceType === 'PDF'">
              <font-awesome-icon :icon="['fas', 'file-pdf']" />
            </span>
            <span class="resource-icon"
              v-else-if="resource.resourceType === 'Books'">
              <font-awesome-icon :icon="['fas', 'external-link-square-alt']" />
            </span>
            <span class="resource-icon"
              v-else>
              <font-awesome-icon :icon="['fas', 'file-download']" />
            </span>
            <span class="resource-title-text"
              :class="{ 'rtl': resource.displayLang === 'ar' }">{{ resource.title.toUpperCase() }}
            </span>
          </a>
          <div v-if="resource.subTitle" class="resource-subtitle">
            {{ resource.subTitle }}
          </div>
          <div class="resource-description"
            v-html="resource.description">
          </div>
          <div class="resource-info-container">
            <div class="resource-label">Type | </div>
            <div class="resource-type">{{ resource.resourceType }}</div>
          </div>
          <div class="resource-info-container"
            v-if="resource.author && resource.author.length > 0">
            <div class="resource-label">Author(s) | </div>
            <div class="resource-author">{{ resource.author }}</div>
          </div>
          <div class="resource-info-container">
            <div class="resource-label">Languages | </div>
            <div class="resource-languages">
              <div class="resource-language"
                v-for="language in resource.languages" :key="language">
                {{ language }}
              </div>
            </div>
          </div>
          <div class="resource-info-container"
            v-if="resource.reference && resource.reference.length > 0">
            <div class="resource-label">Topic | </div>
            <div class="resource-topic">{{ resource.reference }}</div>
          </div>
          <div class="resource-info-container"
            v-if="resource.target && resource.target.length > 0">
            <div class="resource-label">Target | </div>
            <div class="resource-target">{{ resource.target }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page-navigation" class="page-navigation-wrapper">
    <PagesComponent :pageInfo="pageInfo" :navigatePage="navigatePage" :navigatePageDirect="navigatePageDirect"></PagesComponent>
  </div>

</div>

</template>



<script>

import LoaderComponent from "../components/LoaderComponent.vue";
import PagesComponent from "../components/PagesComponent.vue";
import BackComponent from "../components/BackComponent.vue";
import functions from "../mixins/functions.js";
import media from "../mixins/media.js";
export default {
  name: "ResourcesPage",
  components: {
    "PagesComponent": PagesComponent,
    "BackComponent": BackComponent,
    "LoaderComponent": LoaderComponent
  },
  mixins: [functions, media],
  data() {
    return {
      languages: {},
      resources: [],
      pageInfo: {},
      pages: {},
      selected: "all",
      loaded: false,
    }
  },
  watch: {
    $route(to, from) {
      this.getResources();
    }
  },
  methods: {
    languageSelect: function(selected) {
      this.$router.push({ name: "resources", params: { page: 1, language: selected } });
    },
    getResources: function() {
      let url = process.env.VUE_APP_API_ENDPOINT + `/resource?page=${this.$route.params.page}`;
      if (this.$route.params.language !== "all") {
        url += `&lang=${this.selected}`;
      }
      return axios({ method: "GET", "url": url }).then(result => {
        this.languages = result.data.data.filterLanguages;
        this.resources = result.data.data.items;
        this.pageInfo = result.data.data.meta;
        this.pages = result.data.data.links;
        this.loaded = true;
      }, error => {
        console.error(error);
      });
    },
    getPageNumber: function(url) {
      let start = url.lastIndexOf("=") + 1;
      return url.substring(start);
    },
    navigatePage: function(page) {
      let number;
      if (page === "first") {
        number = this.getPageNumber(this.pages.first);
      } else if (page === "last") {
        number = this.getPageNumber(this.pages.last);
      } else if (page === "next") {
        number = this.getPageNumber(this.pages.next);
      } else if (page === "previous") {
        number = this.getPageNumber(this.pages.prev);
      }
      this.$router.push({ name: "resources", params: { page: number, language: this.selected } });
    },
    // Navigate directly to a page by clicking a number
    navigatePageDirect: function(page) {
      this.$router.push({ name: "resources", params: { page: page, language: this.selected } });
    },
  },
  mounted() {
    this.getResources();
  }
}

</script>



<style lang="scss">

#resources-wrapper {
  width: 100%;
  position: relative;
  padding: 0 100px 50px 100px;
}

#language-filter-container {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 100px;
}
  #language-filter {
    font-size: 14px;
    color: $light-text;
    background-color: $mwnf-color;
  }

.loader-container {
  height: 350px;
  width: 100%;
}

#resources-container {
  @include flex-center;
  height: 100%;
  width: 100%;
}

#resources {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 20px 0;
}

.resource-wrapper {
  display: flex;
  margin-bottom: 30px;
}

.resource-image {
  @include flex-center;
  height: 200px;
  width: 30%;
  background-color: rgba($mwnf-color, 1);
}
  .resource-image img {
    @include image-scale;
    object-fit: contain;
  }
.resource-info-wrapper {
  width: 60%;
  margin-left: 50px;
}

.resource-title {
  @include link-styles;
  display: block;
  font-size: 20px;
  font-weight: bold;
  color: $dark-text;
}
  .resource-title-text {
    @include roboto;
    margin-left: 5px;
  }
    .resource-title-text.rtl {
      margin-left: 0;
      margin-right: 5px;
    }
  .resource-title-text:hover {
    @include link-hover-styles;
  }
  .resource-icon {
    color: rgba($mwnf-color, 0.7);
  }
.resource-subtitle {
  @include panel-details;
  margin-top: 2px;
}
.resource-description {
  @include panel-details;
  margin: 10px 0;
}
.resource-info-container {
  @include panel-details;
  display: flex;
  margin-top: 5px;
}
  .resource-label {
    font-weight: bold;
    white-space: pre;
  }
  .resource-languages {
    @include panel-details;
    display: flex;
  }
    .resource-language {
      margin: 0 10px;
    }
    .resource-language:first-child {
      margin-left: 0;
    }
    .resource-language:last-child {
      margin-right: 0;
    }

@media only screen and (max-width: 1099px) {
  #resources-wrapper {
    padding: 0 50px 50px 50px;
  }
  #resources {
    width: 100%;
  }
  #language-filter-container {
    right: 50px;
  }
}

@media only screen and (max-width: 749px) {
  .resource-wrapper {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .resource-image {
    width: 100%;
    margin-bottom: 10px;
  }
  .resource-info-wrapper {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 599px) {
  #resources-wrapper {
    padding: 0 30px 30px 30px;
  }
  #language-filter-container {
    right: 30px;
  }
}

@media only screen and (max-width: 499px) {
  #resources-wrapper {
    padding: 0 20px 20px 20px;
  }
  #language-filter-container {
    right: 20px;
  }
}

</style>
