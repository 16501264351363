<template>

<div id="header-container">
  <div id="main-header-container">
    <div id="logo-container">
      <div id="logo">
        <router-link :to="{ name: 'home' }">
          <img src="../assets/logo-blue.png" />
        </router-link>
      </div>
    </div>
    <div id="links-container">
      <!-- <div id="header-slogan">Building Bridges Through Knowledge And Understanding</div> -->
      <div id="header-slogan">BUILDING BRIDGES THROUGH KNOWLEDGE AND UNDERSTANDING</div>
      <div id="links">
        <!-- <a v-if="!lessThan599" :href="mwnf_website + '/atrium_about.php'">ABOUT</a> -->
        <router-link v-if="!lessThan599" to="/about" class="menu-item" 
          @click="toggleHamburgerMenu">ABOUT</router-link>
        <a v-if="!lessThan599" :href="mwnf_website + '/about/contact'">CONTACT</a>
        <div v-if="lessThan599" id="hamburger-menu-icon"
          @click="toggleHamburgerMenu">
          <font-awesome-icon :icon="['fas', 'bars']"/>
        </div>
        <button id="header-search" @click="showSearchOptions = !showSearchOptions">
          <font-awesome-icon :icon="['fas', 'search']" /><span v-if="!lessThan749"> Search</span>
        </button>
      </div>
    </div>
  </div>
  <div v-if="!lessThan599 || lessThan599 && showHamburgerMenu" id="menu-container">
    <div v-if="lessThan599"
      id="hamburger-menu-close" class="menu-item-container"
      @click="toggleHamburgerMenu">
      <font-awesome-icon :icon="['fas', 'times']"/>
    </div>
    <div class="menu-item-container" v-for="item in menu" :key="item.name">
      <a class="menu-item" :href="item.hash" v-if="home"
        @click="closeOnSelect(item.hash)"
        :class="{ 'menu-item-active': currentHash === item.hash }">
        {{ item.name.toUpperCase() }}
      </a>
      <router-link :to="{ name: 'home', hash: `${item.hash}` }" class="menu-item" v-else>
        {{ item.name.toUpperCase() }}
      </router-link>
    </div>
    <div v-if="lessThan599" class="menu-item-container">
      <!-- <a class="menu-item" :href="mwnf_website + '/atrium_about.php'" -->
      <router-link to="/about" class="menu-item" 
        @click="toggleHamburgerMenu">ABOUT</router-link>
    </div>
    <div v-if="lessThan599" class="menu-item-container">
      <a class="menu-item" :href="mwnf_website + '/about/contact'"
        @click="toggleHamburgerMenu">CONTACT</a>
    </div>
  </div>
  <div id="search-options-container" v-if="showSearchOptions">
    <button @click="showSearch = true, showSearchOptions = false">
      Search entire MWNF website
    </button>
    <a :href="mwnf_website + '/database_searchform.php'"
      @click="showSearchOptions = false">
      Search MWNF Database
    </a>
  </div>
  <transition name="fade" mode="out-in">
    <div id="search-container" v-if="showSearch">
      <button id="search-close"
        v-if="showSearch === true"
        @click="showSearch = false">
        <font-awesome-icon :icon="['fas', 'times']"/>
      </button>
      <form id="search" @submit.prevent>
        <input id="search-input" 
          type="search" 
          v-model="input"
          placeholder='Enter search term(s) here'>
        <button class="search-button" 
          type="submit"
          @click="search($event)">
          <font-awesome-icon :icon="['fas', 'search']" id="search-icon"/>
        </button>
      </form>
      <router-link 
        id="important-information-link"
        :to="{ name: 'importantinformation' }">
        Important Information
      </router-link>
    </div>
  </transition>
</div>

</template>



<script>

import media from "../mixins/media.js";
export default {
  name: "HeaderComponent",
  mixins: [media],
  data() {
    return {
      lessThan599: window.matchMedia("(max-width: 599px)").matches,
      lessThan749: window.matchMedia("(max-width: 749px)").matches,
      showHamburgerMenu: false,
      showSearchOptions: false,
      showSearch: false,
      input: "",
      home: true,
      mwnf_website: process.env.VUE_APP_MWNF_WEBSITE,
      api_website: process.env.VUE_APP_API_ENDPOINT,
      gallery_partners_website: process.env.VUE_APP_GALLERYPARTNERS_WEBSITE,
      cookies_website: process.env.VUE_APP_MWNF_COOKIES,
      menu: [
        {
          name: "Virtual Museums",
          hash: "#virtual-museums"
        },
        {
          name: "Galleries",
          hash: "#galleries"
        },
        {
          name: "Partners",
          hash: "#related"
        },
        {
          name: "Exhibitions",
          hash: "#exhibitions"
        },
        {
          name: "Explore",
          hash: "#explore"
        },
        {
          name: "Books",
          hash: "#see-also"
        },
        {
          name: "Travel",
          hash: "#see-also"
        },
        {
          name: "Videos",
          hash: "#videos"
        },
        {
          name: "Learning",
          hash: "#learning"
        },
        {
          name: "Spotlight",
          hash: "#spotlight"
        },
      ],
      currentHash: ""
    }
  },
  watch: {
    "$route" (to, from) {
      this.setHome(this.$route.name);
      let hash = this.$route.hash;
      if (hash === "") {
        this.currentHash = "";
      } else {
        this.currentHash = hash;
      }
      this.showHamburgerMenu = false;
    },
  },
  methods: {
    closeOnSelect: function(hash) {
      this.currentHash = hash;
      this.showHamburgerMenu = false;
    },
    toggleHamburgerMenu: function() {
      this.showHamburgerMenu = !this.showHamburgerMenu;
    },
    setHome: function(name) {
      if (name === "home") {
        this.home = true;
      } else {
        this.home = false;
      }
    },
    search: function(e) {
      if (this.input === "") {
        alert ("Please enter a search term!");
        return;
      }
      if (this.input === this.$route.query.q) {
        alert("The results for this search term are already being displayed!");
        return;
      }
      this.showSearch = false;
      e.preventDefault();
      this.$router.push({ name: "results", query: { q: this.input } });
    },
  },
  mounted() {
    this.setHome(this.$route.name);
  }
}

</script>



<style lang="scss">

#header-container {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  width: 100%;
  margin-bottom: 30px;
  z-index: 90;
}
#main-header-container {
  display: flex;
  height: 70px;
  width: 100%;
}

#logo-container {
  @include flex-center;
  height: 100%;
  width: 25%;
  background-color: $background-color;
}
  #logo {
    width: 35%;
  }
    #logo-container img {
      @include image-scale;
    }

#links-container {
  display: flex;
  justify-content: space-between;
  height: 70px;
  width: 75%;
  color: $light-text;
  background-color: $mwnf-color;
  padding: 15px 30px;
}
  #header-slogan {
    display: flex;
    align-items: flex-end;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.05rem;
    color: $bright-yellow;
    white-space: pre;
  }
  #links {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
  }
    #links a {
      @include link-styles;
      color: $light-text;
    }
    #links a,
    #links button {
      font-weight: bold;
      margin: 0 10px;
    }

#menu-container {
  @include panel-details;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  color: $light-text;
  background-color: $footer-color-1;
}
  .menu-item {
    @include link-styles;
    color: $light-text;
    cursor: pointer;
  }
    .menu-item:hover {
      @include link-hover-styles;
    }
    .menu-item-active {
      color: $bright-yellow;
    }

#header-search {
  @include button-styles;
  font-weight: bold;
  color: $light-text;
}
  #header-search:focus {
    outline: none;
  }

#search-options-container {
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 280px;
  background-color: $mwnf-color;
  padding: 20px;
}
  #search-options-container button {
    @include button-styles;
    color: $light-text;
    font-weight: bold;
    margin-bottom: 10px;
  }
  #search-options-container a {
    @include link-styles;
    font-weight: bold;
    color: $light-text;
  }

#search-container {
  @include flex-center;
  flex-direction: column;
  position: relative;
  height: 120px;
  width: 100%;
  background-color: rgba($mwnf-color, 0.8);
  z-index: 5;
}
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  #search-close {
    @include button-styles;
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    padding: 10px 12px;
    z-index: 5;
  }
    #search-close:focus {
      outline: none;
    }
  #search {
    @include flex-center;
    width: 100%;
    position: relative;
    margin-bottom: 10px;
  }
    #search-input {
      height: 50px;
      width: 75%;
      border: 1px solid $shadow-grey;
      border-radius: 5px;
      box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
      padding: 6px 10px;
      margin-right: 5px;
    }
      #search-input::placeholder {
        font-weight: normal;
        color: $shadow-grey;
      }
      #search-input:focus {
        color: $mwnf-color;
      }
      .search-button {
        @include button-styles;
        height: 50px;
        width: 50px;
        background-color: white;
        color: $mwnf-color;
        border-radius: 5px;
      }
    #important-information-link {
      font-weight: bold;
      color: $light-text;
    }

@media only screen and (max-width: 1099px) {
  #logo-container {
    width: 18%;
  }
    #logo {
      width: 55%;
    }
  #links-container {
    width: 82%;
  }
  #menu-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    height: 60px;
  }
    .menu-item-container {
      @include flex-center;
      height: 30px;
    }
    .menu-item-container:nth-child(n+6) {
      border-top: 3px solid $mwnf-color;
    }
}

@media only screen and (max-width: 974px) {
  #header-slogan {
    width: 250px;
    white-space: pre-wrap;
  }
  #menu-container {
    @include panel-details-reduced;
  }
}

@media only screen and (max-width: 874px) {
  #logo {
    width: 75%;
  }
  #links-container {
    padding: 15px 20px;
  }
}

@media only screen and (max-width: 599px) {
  #logo-container {
    width: 30%;
  }
    #logo {
      width: 60%;
    }
  #links-container {
    width: 70%;
    padding: 10px 20px;
  }
    #header-slogan {
      width: 175px;
    }
  #links {
    align-items: center;
  }
    #hamburger-menu-icon,
    #header-search {
      font-size: 22px;
    }
      #links button {
        margin-right: 0;
        margin-left: 20px;
      }
  #menu-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    top: 100%;
    height: calc(100vh - 70px);
    width: 60%;
    font-size: 16px;
  }
    #menu-container::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100vw;
      background-color: rgba($footer-color-1, 0.5);
      z-index: -1;
    }
    .menu-item-container {
      height: 35px;
      width: 100%;
      border-bottom: 2px solid $mwnf-color;
    }
    .menu-item-container:nth-child(n+6) {
      border-top: none;
    }
    #hamburger-menu-close {
      display: flex;
      justify-content: flex-end;
      padding-right: 15px;
    }
}

@media only screen and (max-width: 499px) {
  #header-container {
    margin-bottom: 20px;
  }
  #logo {
    width: 70%;
  }  
  #search-container {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 424px) {
  #header-container {
    margin-bottom: 10px;
  }
    #logo {
      width: 85%;
    }
    #header-slogan {
      display: flex;
      align-items: center;
      width: 150px;
      font-size: 12px;
    }
  #links-container {
    padding: 10px;
  }
    #links {
      flex-direction: column;
    }
    #links a,
    #links button {
      margin: 0;
    }
    #hamburger-menu-icon,
    #header-search {
      font-size: 18px;
    }
    #hamburger-menu-icon {
      margin-bottom: 10px;
    }
  #menu-container {
    width: 75%;
  }
  #search-options-container {
    width: 100%;
  }
}

</style>
