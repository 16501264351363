<template>

<div id="support-container">
  <LoaderComponent v-if="!loaded" />
  <div id="support-wrapper" v-else>
    <BackComponent v-if="$route.params.subpage" />
    <div id="support-content-wrapper">
      <div id="support-title" v-html="support.pageTitle"></div>
      <div id="support-content">
        <div id="support-content-text" v-html="support.pageContent"></div>
        <div id="support-links-wrapper" v-if="!$route.params.subpage">
          <router-link :to="{ name: 'support', params: { subpage: 'donations' } }">
            <font-awesome-icon :icon="['fas', 'share']" />{{ support.donations }}
          </router-link>
          <router-link :to="{ name: 'support', params: { subpage: 'involvement' } }">
            <font-awesome-icon :icon="['fas', 'share']" />{{ support.cooperative_involvement }}
          </router-link>
          <router-link :to="{ name: 'volunteers' }">
            <font-awesome-icon :icon="['fas', 'share']" />{{ support.become_mwnf_volunteer }}
          </router-link>
        </div>
        <div id="support-image" v-if="support.image">
          <img :src="support.image" />
        </div>
      </div>
    </div>
  </div>
</div>

</template>


<script>

import LoaderComponent from "./LoaderComponent.vue";
import BackComponent from "./BackComponent.vue";
import media from "../mixins/media.js";
export default { 
  name: "SupportPage",
  components: {
    "LoaderComponent": LoaderComponent,
    "BackComponent": BackComponent
  },
  mixins: [media],
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      support: {},
      loaded: false
    }
  },
  watch: {
    "$route" (to, from) {
      this.loaded = false;
      this.getSupportInfo();
    },
  },
  methods: {
    getSupportInfo: function() {
      if (!this.$route.params.subpage) {
        return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/atrium_support" }).then(result => {
          this.support = result.data.data;
          this.loaded = true;
        }, error => {
          console.error(error);
        });
      } else {
        let url;
        switch (this.$route.params.subpage) {
          case "donations":
            url = process.env.VUE_APP_API_ENDPOINT + "/atrium_joinin_donations";
            break;
          case "involvement":
            url = process.env.VUE_APP_API_ENDPOINT + "/atrium_joinin_involvement";
            break;
          default:
            break;
        }
        return axios({ method: "GET", "url": url }).then(result => {
          this.support = result.data.data;
          this.loaded = true;
        }, error => {
          console.error(error);
        });
      }
    },
  },
  mounted() {
    this.getSupportInfo();
  }
}

</script>



<style scoped lang="scss">

#support-container {
  @include flex-center;
  flex-direction: column;
  width: 100%;
  color: $light-text;
  background-color: $about-color;
  padding: 50px 100px;
}
  #support-wrapper {
    @include flex-center;
    flex-direction: column;
  }
  .loader-container {
    height: 100%;
    background-color: $about-color;
  }
  #support-container :deep(a) {
    color: $light-text;
  }
  #support-container .fa-share {
    font-size: 75%;
    margin-right: 5px;
  }

#support-content-wrapper {
  @include flex-center;
  flex-direction: column;
  width: 60%;
}
#support-title {
  @include about-title;
  @include flex-center;
  text-align: center;
  margin-bottom: 15px;
}
#support-content {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: rgba($mwnf-color, 0.1);
  padding: 50px;
}
#support-links-wrapper {
  display: flex;
  flex-direction: column;
}
  #support-content-text,
  #support-links-wrapper {
    z-index: 5;
  }
#support-image {
  @include flex-center;
  margin-top: 30px;
}
  #support-image img {
    @include image-scale;
    object-fit: cover;
  }

@media only screen and (max-width: 1199px) {
  #support-content-wrapper {
    width: 80%;
  }
}

@media only screen and (max-width: 849px) {
  #support-content-wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 749px) {
  #support-container {
    padding: 50px;
  }
}

@media only screen and (max-width: 499px) {
  #support-container {
    padding: 30px;
  }
  #support-content {
    padding: 30px;
  }
}

</style>