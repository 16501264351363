<template>

<div id="footer-container">
  <div id="footer-top-container">
    <div v-if="!lessThan599" class="footer-column">
      <div class="footer-column-logo">
        <img src="../assets/logo-white.png" />
      </div>
      <div id="footer-slogan">Museum With No Frontiers (MWNF)</div>
    </div>
    <div class="footer-column"
      v-for="section in footer" :key="section[0]">
      <div class="footer-section-name">{{ section[0].toUpperCase() }}</div>
      <div v-for="link in section" :key="link.title">
        <a v-if="!link.isEmail" class="footer-section-link"
          :href="link.url">
          {{ link.title }}
        </a>
        <a v-else class="footer-section-link"
          :href="`mailto:${link.url}`">
          {{ link.title }}
        </a>
      </div>
    </div>
  </div>
  <div id="footer-bottom-container">
    <a class="footer-bottom-link"
      v-for="link in bottom" :key="link.title"
      :href="link.url">
      {{ link.title.toUpperCase() }}
    </a>
  </div>
</div>

</template>



<script>

import media from "../mixins/media.js";
export default {
  name: "FooterComponent",
  mixins: [media],
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      lessThan599: window.matchMedia("(max-width: 599px)").matches,
      footer: {},
      bottom: []
    }
  },
  methods: {
    getFooterLinks: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/footer" }).then(result => {
        let footer = result.data.data.footer;
        this.bottom = result.data.data.footerBottom;
        delete footer.footerBottom;
        let sections = Object.keys(footer);
        // Add name of section to each section's array
        for (let i = 0; i < sections.length; i++) {
          footer[sections[i]].unshift(sections[i]);
        }
        this.footer = footer;
      }, error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.getFooterLinks();
  }
}

</script>



<style lang="scss">

#footer-container {
  width: 100%;
  color: $light-text;
  background-color: $footer-color-1;
}

#footer-top-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
  width: 100%;
  padding: 50px 100px;
}

.footer-column {
  display: flex;
  flex-direction: column;
}
  .footer-column-logo img {
    @include image-scale;
    max-width: 120px;
  }
  #footer-slogan {
    font-size: 11px;
  }
  .footer-column > * {
    margin-bottom: 5px;
  }
  .footer-section-name {
    font-weight: bold;
  }
  .footer-contact {
    margin-bottom: 5px;
  }
    .footer-contact:first-child {
      margin-bottom: 0;
    }
  .footer-column a,
  .footer-column span {
    @include panel-details;
    color: $light-text;
    text-decoration: none;
  }

#footer-bottom-container {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  background-color: $footer-color-2;
  padding: 0 100px;
}
  .footer-bottom-link {
    font-size: 11px;
    color: $light-text;
    text-decoration: none;
    margin: 0 20px;
  }
    .footer-bottom-link:first-child {
      margin-left: 0;
    }

@media only screen and (max-width: 1274px) {
  #footer-top-container {
    padding: 50px 60px;
  }
  #footer-bottom-container {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 974px) {
  .footer-column a,
  .footer-column span {
    @include panel-details-reduced;
  }
}

@media only screen and (max-width: 874px) {
  #footer-top-container {
    padding: 50px 30px;
  }
  #footer-bottom-container {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 599px) {
  #footer-top-container {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 25px;
    padding: 30px;
  }
}

@media only screen and (max-width: 499px) {
  #footer-top-container {
    grid-template-columns: repeat(2, 1fr);
  }
  #footer-bottom-container {
    justify-content: space-between;
  }
  .footer-bottom-link {
    margin: 0 10px;
  }
    .footer-bottom-link:first-child {
      margin-left: 0;
    }
    .footer-bottom-link:last-child {
      margin-right: 0;
    }
}

@media only screen and (max-width: 424px) {
  #footer-top-container {
    grid-gap: 20px;
    padding: 20px;
  }
  #footer-bottom-container {
    height: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 20px;
  }
    .footer-bottom-link {
      margin: 0;
    }
}

</style>
