<template>

<div id="language-policy-container">
  <LoaderComponent v-if="!loaded" />
  <div id="language-policy-wrapper" :dir="rightToLeft ? 'rtl' : 'ltr'" v-else>
    <select id="language-policy-select" v-model="currentLanguage">
      <option v-for="language in languageList" :key="language"
        :value="language">
        {{ language.toUpperCase() }}
      </option>
    </select>
    <div id="language-policy-title" v-html="currentInfo.pageTitle"></div>
    <div id="language-policy-text" v-html="currentInfo.pageText"></div>
  </div>
</div>

</template>


<script>

import LoaderComponent from "./LoaderComponent.vue";
import media from "../mixins/media.js";
export default { 
  name: "LanguagePolicy",
  mixins: [media],
  components: {
    "LoaderComponent": LoaderComponent
  },
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      info: {},
      languageList: [],
      currentLanguage: "en",
      currentInfo: "",
      rightToLeft: false,
      loaded: false
    }
  },
  watch: {
    // $route(to, from) {
    //   this.getBoardMembers();
    // }
    currentLanguage() {
      if (this.currentLanguage === "ar") {
        this.rightToLeft = true;
      } else {
        this.rightToLeft = false;
      }
      this.currentInfo = this.info[this.currentLanguage];
    }
  },
  methods: {
    getLanguagePolicyInfo: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/language-policy" }).then(result => {
        this.info = result.data.data;
        this.languageList = Object.keys(result.data.data);
        // console.log(this.languageList);
        this.currentInfo = this.info[this.currentLanguage];
        this.loaded = true;
      }, error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.getLanguagePolicyInfo();
  }
}

</script>



<style scoped lang="scss">

#language-policy-container {
  @include flex-center;
  flex-direction: column;
  width: 100%;
  color: $light-text;
  background-color: $about-color;
  padding: 50px;
}
.loader-container {
  height: 100%;
  background-color: $about-color;
}

#language-policy-wrapper {
  @include flex-center;
  flex-direction: column;
  position: relative;
  width: 55%;
  background-color: rgba($mwnf-color, 0.1);
  padding: 50px;
}

#language-policy-select {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  // margin-bottom: 15px;
}

#language-policy-title {
  @include about-title;
  @include flex-center;
  margin-bottom: 15px;
}
#language-policy-text :deep(a) {
  color: $light-text;
}

@media only screen and (max-width: 1199px) {
  #language-policy-wrapper {
    width: 75%;
  }
}

@media only screen and (max-width: 899px) {
  #language-policy-wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 649px) {
  #language-policy-container {
    padding: 30px;
  }
  #language-policy-wrapper {
    padding: 30px;
  }
}

</style>