<template>

<div id="homepage-container">
  <NewPanel />
  <VirtualMuseums />
  <GalleriesComponent />
  <RelatedComponent />
  <ExhibitionsComponent />
  <ExploreComponent />
  <!-- <Exhibitions /> -->
  <VideosComponent />
  <LearnComponent />
  <SpotlightComponent />
</div>

</template>



<script>

import NewPanel from "../components/NewPanel.vue";
import VirtualMuseums from "../components/VirtualMuseums.vue";
import GalleriesComponent from "../components/GalleriesComponent.vue";
import RelatedComponent from "../components/RelatedComponent.vue";
import ExploreComponent from "../components/ExploreComponent.vue";
import ExhibitionsComponent from "../components/ExhibitionsComponent.vue";
import VideosComponent from "../components/VideosComponent.vue";
import LearnComponent from "../components/LearnComponent.vue";
import SpotlightComponent from "../components/SpotlightComponent.vue";

import media from "../mixins/media.js";
export default {
  name: "HomepagePage",
  components: {
    "NewPanel": NewPanel,
    "VirtualMuseums": VirtualMuseums,
    "GalleriesComponent": GalleriesComponent,
    "RelatedComponent": RelatedComponent,
    "ExploreComponent": ExploreComponent,
    "ExhibitionsComponent": ExhibitionsComponent,
    "VideosComponent": VideosComponent,
    "LearnComponent": LearnComponent,
    "SpotlightComponent": SpotlightComponent,
  },
  mixins: [media],
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
    }
  },
}

</script>



<style lang="scss">

#homepage-container {
  height: 100%;
  width: 100%;
}

</style>
