<template>

<div id="videos-container">
  <div id="video-panel">
    <a id="videos" name="videos">VIDEOS</a>
    <div id="all-videos-button">
      <a :href="channelUrl" target="_blank">+ MWNF YouTube Channel</a>
    </div>
    <div id="videos-wrapper">
      <div class="video-wrapper" 
        :dir="video.lang === 'ar' ? 'rtl' : 'ltr'"
        v-for="video in videos" :key="video.videoUrl">
        <div class="video-thumbnail">
          <a :href="video.videoUrl" target="_blank">
            <img :src="video.videoThumbnailImageUrl" />
          </a>
          <div class="video-flag" v-if="video.soonFlag">SOON</div>
          <div class="video-flag" v-if="video.liveFlag">LIVE</div>
        </div>
        <div class="video-title">
          <a :href="video.videoUrl" target="_blank">
            {{ video.videoTitle }}
          </a>
        </div>
        <div class="video-date">{{ video.videoDate }}</div>
        <div class="video-description" v-html="video.description"></div>
        <div class="video-participants"
          v-if="video.participantsName && video.participantsName.length > 0 && siteLangs['en']">
          {{ siteLangs[video.lang].VideoParticipants }} {{ video.participantsName }}
        </div>
      </div>
    </div>
  </div>
</div>

</template>



<script>

// import { loadLocaleMessages } from "../i18n.js";
import media from "../mixins/media.js";
export default {
  name: "VideosComponent",
  mixins: [media],
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      videos: [],
      channelUrl: "",
      siteLangs: {}
    }
  },
  methods: {
    getVideos: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/videos" }).then(result => {
        console.log(result.data.data);
        this.videos = result.data.data.videos;
        this.channelUrl = result.data.data.channelURL;
      }, error => {
        console.error(error);
      });
    },
    getSiteLangs: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/site-lang" }).then(result => {
        this.siteLangs = result.data;
      }, error => {
        console.error(error);
      });
    }
  },
  mounted() {
    this.getVideos();
    this.getSiteLangs();
  }
}

</script>



<style lang="scss">

#videos-container {
  width: 100%;
  color: $light-text;
  padding: 50px 100px;
}

.video-title a {
  @include roboto;
}

#video-panel {
  @include flex-center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: rgba(black, 0.9);
  padding: 50px 60px;
}

#videos {
  @include panel-header;
  @include scroll-top;
  margin-bottom: 0;
}
#all-videos-button {
  align-self: flex-end;
}
  #all-videos-button a {
    @include link-styles;
    @include panel-details;
    display: block;
    color: $light-text;
    background-color: $mwnf-color;
    padding: 8px 15px;
  }
    #all-videos-button a:hover {
      @include link-hover-styles;
    }

#videos-wrapper {
  display: flex;
  width: 100%;
}
  .video-wrapper {
    width: 50%;
  }
  .video-wrapper:first-child {
    margin-right: 15px;
  }
  .video-wrapper:last-child {
    margin-left: 15px;
  }

.video-thumbnail {
  @include flex-center;
  position: relative;
  height: 275px;
  width: 100%;
  background-color: rgba($mwnf-color, 0.2);
  margin-bottom: 20px;
}
  .video-thumbnail a {
    display: block;
    height: 100%;
    width: 100%;
  }
  .video-thumbnail img {
    @include image-scale;
    object-fit: contain;
  }
  .video-flag {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    font-weight: bold;
    color: $dark-text;
    background-color: $dia-color;
    padding: 12px 20px;
  }

.video-title,
.video-date,
.video-description,
.video-participants {
  align-self: flex-start;
}
.video-title {
  font-weight: bold;
  margin-bottom: 10px;
}
  .video-title a {
    @include link-styles;
    color: $light-text;
  }
    .video-title a:hover {
      @include link-hover-styles;
    }
.video-date {
  width: 100%;
  font-size: 12px;
  color: $dia-color;
  border-bottom: 1px solid $light-text;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.video-description {
  @include panel-details;
  margin-bottom: 10px;
}
.video-participants {
  @include panel-details;
}

@media only screen and (max-width: 1274px) {
  #videos-container {
    padding: 50px 60px;
  }
}

@media only screen and (max-width: 1199px) {
  #videos {
    @include scroll-top-increased;
  }
  #video-panel {
    padding: 40px 50px;
  }
}

@media only screen and (max-width: 974px) {
  #videos {
    @include panel-header-reduced;
  }
  #all-videos-button a,
  .video-description,
  .video-participants {
    @include panel-details-reduced;
  }
}

@media only screen and (max-width: 874px) {
  #videos-container {
    padding: 30px;
  }
  #video-panel {
    padding: 30px;
  }
  #videos-wrapper {
    flex-direction: column;
  }
    .video-wrapper {
      width: 100%;
    }
    .video-wrapper:first-child {
      margin-right: 0;
      margin-bottom: 15px;
    }
    .video-wrapper:last-child {
      margin-left: 0;
    }
  .video-title,
  .video-date,
  .video-description {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media only screen and (max-width: 599px) {
  #videos {
    @include scroll-top-decreased;
  }
  .video-title,
  .video-date,
  .video-description {
    padding-right: 0;
    padding-left: 0;
  }
}

@media only screen and (max-width: 424px) {
  #videos-container {
    padding: 0;
    margin-bottom: 20px;
  }
  #videos {
    @include panel-header-smallest;
  }
  #video-panel {
    padding: 20px;
  }
}

</style>
