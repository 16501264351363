<template>

<div id="cookies-container">
  <LoaderComponent v-if="!loaded" />
  <div id="cookies-container-wrapper" v-else>
    <div id="cookies-container-title">{{ info.pageHeading }}</div>
    <div id="cookies-container-text" v-html="info.pageText"></div>
  </div>
</div>

</template>


<script>

import LoaderComponent from "./LoaderComponent.vue";
import media from "../mixins/media.js";
export default { 
  name: "CookiesPage",
  mixins: [media],
  components: {
    "LoaderComponent": LoaderComponent
  },
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      info: {},
      loaded: false
    }
  },
  methods: {
    getCookiesInfo: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/cookie_policy" }).then(result => {
        this.info = result.data.data;
        this.loaded = true;
      }, error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.getCookiesInfo();
  }
}

</script>



<style scoped lang="scss">

#cookies-container {
  @include flex-center;
  flex-direction: column;
  width: 100%;
  color: $light-text;
  background-color: $about-color;
  padding: 50px;
}
.loader-container {
  height: 100%;
  background-color: $about-color;
}
  #cookies-container :deep(a) {
    color: $light-text;
  }

#cookies-container-wrapper {
  width: 55%;
}

#cookies-container-title {
  @include about-title;
  @include flex-center;
  margin-bottom: 15px;
}

@media only screen and (max-width: 1199px) {
  #cookies-container-wrapper {
    width: 75%;
  }
}

@media only screen and (max-width: 699px) {
  #cookies-container-wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 499px) {
  #cookies-container {
    padding: 30px;
  }
}

</style>