<template>

<div id="app">

  <div id="page-container">

    <HeaderComponent />
    <keep-alive>
      <router-view 
        :email="email"
        @signup="setSignUp">
      </router-view>
    </keep-alive>
    <SocialComponent 
      :confirm="signupSuccess"
      @newsletter="saveEmail" 
    />
    <FooterComponent />

  </div>
</div>

</template>



<script>

import HeaderComponent from "./components/HeaderComponent.vue";
import SocialComponent from "./components/SocialComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";

import media from "./mixins/media.js";
export default {
  name: "App",
  props: ["signup"],
  components: {
    "HeaderComponent": HeaderComponent,
    "SocialComponent": SocialComponent,
    "FooterComponent": FooterComponent
  },
  mixins: [media],
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      currentYear: new Date().getFullYear(),
      email: "",
      signupSuccess: false,
    }
  },
  methods: {
    saveEmail: function(email) {
      this.email = email;
    },
    setSignUp: function(success) {
      this.signupSuccess = success;
    }
  },
  mounted() {
    // Cookie Consent by https://www.FreePrivacyPolicy.com
    document.addEventListener("DOMContentLoaded", function () {
    /*global cookieconsent */
    /*eslint no-undef: "error"*/
    cookieconsent.run({
        "notice_banner_type":"simple",
        "consent_type":"express",
        "palette":"dark",
        "language":"en",
        "page_load_consent_levels":["strictly-necessary"],
        "notice_banner_reject_button_hide":false,
        "preferences_center_close_button_hide":false,
        "website_name":"Museums With No Frontiers",
        "website_privacy_policy_url":process.env.VUE_APP_MWNF_COOKIES,
      });
    });
  }
}

</script>



<style lang="scss">

/* See borders while designing pages */
// * {
//   border: 1px dashed red;
// }

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 100%;
}
*, ::before, ::after {
  box-sizing: border-box;
}

body {
  @include flex-center;
  align-items: flex-start;
  background-color: ivory;
  overflow-x: hidden;
}

#page-container {
  @include flex-center;
  flex-direction: column;
  justify-content: flex-start;
  width: 1350px;
  max-width: 1350px;
  box-shadow: 0 2px 8px 2px $shadow-grey;
  background-color: $background-color;
}

// Override CSS rules for cookie pop-up to assign MWNF colors and fonts
.freeprivacypolicy-com---nb-simple {
  min-width: 100% !important;
}
.freeprivacypolicy-com---palette-dark.freeprivacypolicy-com---nb {
  background-color: rgba($mwnf-color, 0.95) !important;
}
.freeprivacypolicy-com---palette-dark .cc-nb-title,
.freeprivacypolicy-com---pc-dialog .cc-pc-head-title-headline,
.freeprivacypolicy-com---pc-dialog .cc-cp-body-content-entry-title {
  @include roboto;
}
.freeprivacypolicy-com---palette-dark .cc-nb-okagree, 
.freeprivacypolicy-com---palette-dark .cc-nb-reject {
  background-color: $bright-yellow !important;
}
.freeprivacypolicy-com---palette-dark .cc-pc-head,
.freeprivacypolicy-com---palette-dark .cc-cp-foot {
  background-color: $footer-color-1 !important;
}
.freeprivacypolicy-com---palette-dark .cc-pc-head-close:active,
.freeprivacypolicy-com---palette-dark .cc-pc-head-close:focus {
  border: none !important;
}
.freeprivacypolicy-com---palette-dark .cc-pc-head-lang select:focus,
.freeprivacypolicy-com---palette-dark .cc-cp-foot-save:focus {
  box-shadow: none !important;
}
.freeprivacypolicy-com---palette-dark .cc-cp-body {
  background: $mwnf-color !important;
}
.freeprivacypolicy-com---palette-dark .cc-cp-body-tabs .cc-cp-body-tabs-item[active=true],
.freeprivacypolicy-com---palette-dark .cc-cp-body .cc-cp-body-content {
  background: $mwnf-color !important;
}
.freeprivacypolicy-com---pc-dialog input[type="checkbox"].cc-custom-checkbox:checked+label:before {
  background: $bright-yellow !important;
}
.freeprivacypolicy-com---palette-dark .cc-cp-foot-save {
  background: $bright-yellow !important;
}

@media only screen and (max-width: 1349px) {
  #page-container {
    width: 100%;
  }
}

@media print {
  #page-container {
    width: auto;
    max-width: none;
    box-shadow: none;
  }
}

</style>
