<template>

<div id="board-container">
  <LoaderComponent v-if="!loaded" />
  <!-- <div v-html="contact.pageContent"></div> -->
  <div id="board-wrapper" v-else>
    <div v-if="!$route.params.profile" id="board-list-container">
      <div id="board-title">{{ title }}</div>
      <div v-for="member in board" :key="member.link" class="board-member-link">
        <a @click="goToProfile(member.link)">
          <font-awesome-icon :icon="['fas', 'share']" /><span>{{ member.Name }}</span><span v-if="member.Position">, {{ member.Position }}</span>
        </a>
      </div>
    </div>
    <div v-else id="board-profile-container">
      <BackComponent />
      <div id="board-profile-information-container">
        <div id="board-profile-image">
          <img :src="profile.image" />
        </div>
        <div id="board-profile-information">
          <div>{{ profile.Name }}</div>
          <div>{{ profile.Position }}</div>
        </div>
      </div>
      <div id="board-profile-description" v-html="profile.Description"></div>
    </div>
  </div>
</div>

</template>


<script>

import media from "../mixins/media.js";
import LoaderComponent from "./LoaderComponent.vue";
import BackComponent from "./BackComponent.vue";
export default { 
  name: "BoardPage",
  components: {
    "LoaderComponent": LoaderComponent,
    "BackComponent": BackComponent
  },
  mixins: [media],
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      title: "",
      board: [],
      profile: {},
      loaded: false
    }
  },
  watch: {
    $route(to, from) {
      this.loaded = false;
      this.getBoardMembers();
    }
  },
  methods: {
    getBoardMembers: function() {
      if (this.$route.params.profile) {
        return axios({ method: "GET", "url": `${process.env.VUE_APP_API_ENDPOINT}/atrium_board/${this.$route.params.profile}` }).then(result => {
          this.profile = result.data.data;
          console.log(this.profile);
          this.loaded = true;
        }, error => {
          console.error(error);
        });
      } else {
        return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/atrium_board" }).then(result => {
          this.title = result.data.data.pageTitle;
          this.board = result.data.items;
          this.loaded = true;
        }, error => {
          console.error(error);
        });
      }
    },
    getProfileCode: function (text) {
      let index = text.lastIndexOf("/") + 1;
      return text.substring(index);
      // console.log(code);
    },
    goToProfile: function (link) {
      let code = this.getProfileCode(link);
      this.$router.push({ name: "board", params: { profile: code } });
    }
  },
  mounted() {
    this.getBoardMembers();
  }
}

</script>



<style scoped lang="scss">

#board-container {
  @include flex-center;
  flex-direction: column;
  width: 100%;
  color: $light-text;
  background-color: $about-color;
  padding: 50px;
}
  #board-container .fa-share {
    font-size: 75%;
    margin-right: 5px;
  }

#board-wrapper {
  @include flex-center;
}
.loader-container {
  height: 100%;
  background-color: $about-color;
}

#board-title {
  @include about-title;
}

.board-member-link {
  margin-bottom: 5px;
}
  .board-member-link a {
    text-decoration: underline;
    cursor: pointer;
  }

#board-profile-container {
  display: flex;
  flex-direction: column;
  width: 60%;
  background-color: rgba($mwnf-color, 0.1);
  padding: 50px;
}
  #board-profile-information-container {
    @include flex-center;
    margin-bottom: 20px;
  }
    #board-profile-image {
      margin-right: 20px;
    }
      #board-profile-image img {
        @include image-scale;
        object-fit: cover;
      }
  #board-profile-description :deep(a) {
    color: $light-text;
  }

@media only screen and (max-width: 1199px) {
  #board-profile-container {
    width: 70%;
  }
}

@media only screen and (max-width: 974px) {
  #board-profile-container {
    width: 80%;
  }
} 

@media only screen and (max-width: 799px) {
  #board-profile-container {
    width: 100%;
  }
  #board-profile-information-container {
    flex-direction: column;
    margin-bottom: 20px;
  }
    #board-profile-image {
      margin: 10px 0 20px 0;
    }
} 

@media only screen and (max-width: 599px) {
  #board-container {
    padding: 30px;
  }
} 

@media only screen and (max-width: 499px) {
  #board-profile-container {
    padding: 30px;
  }
} 

</style>