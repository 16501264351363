<template>

<div id="chronology-container">
  <LoaderComponent v-if="!loaded" />
  <div id="chronology-content" v-else>
    <div id="chronology-navigation">
      <BackComponent />
      <div id="chronology-main-select-container">
        <select id="chronology-main-select" name="chronology-main"
          v-model="selectedItem" @change="goToNext()">
          <option selected disabled value="" v-html="$i18n.t('chronologyDropdown')"></option>
          <option v-for="item in chronologyHome" :key="item.readMore"
            :title="`${item.date}, ${item.place}`"
            :value="{ type: getChronologyType(item.readMore), 
              code: getChronologyCode(item.readMore), 
              title: item.title }">
            {{ item.title }}
          </option>
        </select>
      </div>
    </div>
    <!-- <div id="chronology-title">{{ chronologyTitle }}</div> -->

    <!-- <div class="chronology-item" v-for="item in chronologyItems" :key="item.readMore">
      <div>{{ item.date }}</div>
      <div>{{ item.place }}</div>
      <div>{{ item.title }}</div>
      <div>{{ item.subtitle }}</div>
      <img :src="item.logo_small_1" />
      <img :src="item.logo_small_2" />
    </div> -->
    <!-- <router-view></router-view> -->
    <div id="chronology-inner-container"
      :class="{ 'inner-list': !$route.params.type || $route.params.type === 'list',
        'inner-photos': $route.params.type === 'photos' }">
      <div v-if="!$route.params.type || $route.params.type === 'list'">
        <div class="chronology-item" v-for="item in chronologyItems" :key="item.readMore">
          <div class="chronology-item-margin chronology-title">
            <span :class="{ 'bold-yellow': $route.path == '/about/chronology' }">{{ item.date }}</span>, {{ item.place }}
          </div>
          <a class="chronology-item-link chronology-item-margin" 
            :class="{ 'bold': $route.path !== '/about/chronology' }"
            @click="seeMore(getChronologyType(item.readMore), getChronologyCode(item.readMore))">
            <font-awesome-icon :icon="['fas', 'share']" /><span v-html="item.title"></span>
          </a>
          <div v-if="item.subtitle" class="chronology-item-margin">{{ item.subtitle }}</div>
          <!-- <div v-if="item.logo_small_1 || item.logo_small_2"
            class="chronology-item-logo-container chronology-item-margin">
            <img :src="item.logo_small_1" :alt="item.logo_small_1_alt" :title="item.logo_small_1_alt" />
            <img :src="item.logo_small_2" :alt="item.logo_small_2_alt" :title="item.logo_small_2_alt" />
          </div> -->
          <div v-if="item.logos && item.logos.length > 0"
            class="chronology-item-logo-container chronology-item-margin">
            <img v-for="logo in item.logos" :key="logo.logo" :src="logo.logo" :alt="logo.logoAlt" :title="logo.logoAlt" />
          </div>
        </div>
      </div>
      <div v-else-if="$route.params.type === 'photos'">
        <div class="chronology-photos-info-container">
          <div class="chronology-photos-info-title" v-html="chronologyItems.title"></div>
          <div class="chronology-photos-info-date-location">{{ chronologyItems.place }}, {{ chronologyItems.date }}</div>
        </div>
        <div class="chronology-photos-description" v-html="chronologyItems.description"></div>
        <div class="chronology-thumbnails-container" v-if="chronologyItems.images && chronologyItems.images.length > 0">
          <div class="chronology-thumbnail" 
            v-for="item in chronologyItems.images" :key="item.imgSmall"
            @click="selectPhoto(item)">
            <img :src="item.imgSmall" />
            <!-- include name, caption, authors, copyright -->
            <!-- <div>{{ item.caption }}</div> -->
          </div>
        </div>
        <div class="chronology-thumbnails-container" v-else>
          <div>No images available for this Project.</div>
        </div>
        <div id="chronology-photos-overlay" v-if="showOverlay"
          @click="showOverlay = false">
          <div id="chronology-photo">
            <img :src="selectedPhoto.imgLarge" />
          </div>
          <div id="chronology-photo-info">
            <div v-if="selectedPhoto.name" class="photo-info" v-html="selectedPhoto.name"></div>
            <div v-if="selectedPhoto.caption" class="photo-info" v-html="selectedPhoto.caption"></div>
            <div v-if="selectedPhoto.authors" class="photo-info" v-html="selectedPhoto.authors"></div>
            <div v-if="selectedPhoto.copyright" class="photo-info" v-html="selectedPhoto.copyright"></div>
          </div>
        </div>
      </div>
      <div class="chronology-read-more" v-if="chronologyItems.readMore && chronologyItems.readMore.length > 0">
        <a @click="seeMore(getChronologyType(chronologyItems.readMore), getChronologyCode(chronologyItems.readMore))">
          <font-awesome-icon :icon="['fas', 'share']" /><span class="bold-yellow">See more about </span><span v-html="chronologyItems.title"></span>
        </a>
      </div>
      <div class="chronology-pdfs" v-if="chronologyItems.pdf && chronologyItems.pdf.length > 0">
        <div class="chronology-pdfs-header">Related Content</div>
        <div class="chronology-pdf" v-for="pdf in chronologyItems.pdf" :key="pdf.pdfPath">
          <a class="chronology-pdf-link"
            :href="pdf.pdfPath" target="_blank">
            <font-awesome-icon :icon="['fas', 'file-pdf']" /> {{ pdf.name }}
          </a>
        </div>
      </div>
      <!-- <div class="chronology-item" v-for="item in chronologyItems" :key="item.readMore">
        <div>{{ item.date }}</div>
        <div>{{ item.place }}</div>
        <div>{{ item.title }}</div>
        <div>{{ item.subtitle }}</div>
        <img :src="item.logo_small_1" />
        <img :src="item.logo_small_2" />
      </div> -->
    </div>

  </div>
</div>

</template>


<script>

import { loadLocaleMessages } from "../i18n.js";
import LoaderComponent from "./LoaderComponent.vue";
import BackComponent from "./BackComponent.vue";
import media from "../mixins/media.js";
export default { 
  name: "ChronologyPage",
  components: {
    "LoaderComponent": LoaderComponent,
    "BackComponent": BackComponent
  },
  mixins: [media],
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      // profile: {}
      // chronologyTitle: "",
      chronologyHome: [],
      chronologyItems: [],
      selectedItem: "",
      showOverlay: false,
      selectedPhoto: {},
      loaded: false
    }
  },
  watch: {
    "$route" (to, from) {
      // console.log("route change");
      this.getChronologyHome();
    },
  },
  methods: {
    selectPhoto: function (photo) {
      this.selectedPhoto = photo;
      this.showOverlay = true;
    },
    // checkRoute: function() {
    //   if (this.$route.name === "newsletter") {
    //     this.onSignUpPage = true;
    //   } else {
    //     this.onSignUpPage = false;
    //   }
    // },
    getChronologyCode: function (text) {
      let index = text.lastIndexOf("/") + 1;
      let code = text.substring(index);
      // console.log(code);
      return code;
    },
    getChronologyType: function (text) {
      let code = text.lastIndexOf("/");
      let removed = text.substring(0, code);
      let index = removed.lastIndexOf("/") + 1;
      let type = removed.substring(index);
      let param;
      if (type.includes("txt")) {
        param = "photos";
      } else {
        param = "list";
      }
      // console.log(type);
      return param;
    },
    goToNext: function () {
      console.log(this.selectedItem);
      this.$router.push({ name: "chronology", params: { type: this.selectedItem.type, code: this.selectedItem.code } });
    },
    seeMore: function (type, code) {
      console.log(type, code);
      this.$router.push({ name: "chronology", params: { type: type, code: code } });
    },
    getChronologyInfo: function () {
      // return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/atrium_chronology_home" }).then(result => {
      //   this.chronologyTitle = result.data.data.pageTitle;
      //   this.chronologyItems = result.data.items;
      //   // this.getChronologyCode(this.chronologyItems[0].readMore);
      //   this.getChronologyType(this.chronologyItems[0].readMore);
      // }, error => {
      //   console.error(error);
      // });
      let url;
      console.log(this.$route.params);
      if (this.$route.params.type === "list") {
        url = `/atrium_chronology/${this.$route.params.code}`;
      } else if (this.$route.params.type === "photos") {
        url = `/atrium_chronology_txt/${this.$route.params.code}`;
      }
      let photos = this.$route.params.type === "photos";
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + url }).then(result => {
        console.log(process.env.VUE_APP_API_ENDPOINT + url);
        let title;
        if (!photos) {
          this.chronologyItems = result.data.items;
          title = result.data.data.mainTitle;
        } else {
          this.chronologyItems = result.data.data;
          if (this.chronologyItems.mainTitle.length === 0) {
            title = this.chronologyItems.title;
          } else {
            title = this.chronologyItems.mainTitle;
          }
        }
        this.loaded = true;
        for (let i = 0; i < this.chronologyHome.length; i++) {
          if (this.chronologyHome[i].title === title) {
            this.selectedItem = {
              type: this.getChronologyType(this.chronologyHome[i].readMore), 
              code: this.getChronologyCode(this.chronologyHome[i].readMore), 
              title: this.chronologyHome[i].title
            }
            return;
          } else {
            this.selectedItem = "";
          }
        }
      }, error => {
        console.error(error);
      });
    },
    getChronologyHome: function () {
      this.loaded = false;
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/atrium_chronology_home" }).then(result => {
        console.log(process.env.VUE_APP_API_ENDPOINT + "/atrium_chronology_home");
        // this.chronologyTitle = result.data.data.pageTitle;
        this.chronologyHome = result.data.items;
        if (this.$route.params.type) {
          this.getChronologyInfo();
        } else {
          this.chronologyItems = result.data.items;
          this.loaded = true;
        }
        this.selectedItem = "";
      }, error => {
        console.error(error);
      });
    }
  },
  mounted() {
    // this.getProfile();
    this.getChronologyHome();
    // this.getChronologyInfo();
  },
  // Load i18n messages in correct language
  beforeRouteEnter(to, from, next) {
    loadLocaleMessages("en").then(() => next()); 
  },
  beforeRouteUpdate(to, from, next) {
    loadLocaleMessages("en").then(() => next());
  }
}

</script>



<style scoped lang="scss">

.bold {
  font-weight: bold;
}
.bold-yellow {
  color: $bright-yellow;
  font-weight: bold;
}

#chronology-container {
  color: $light-text;
  background-color: $about-color;
  padding: 50px 100px;
}
.loader-container {
  height: 100%;
  background-color: $about-color;
}
  #chronology-container a {
    cursor: pointer;
  }
    #chronology-container a:hover {
      text-decoration: underline;
    }
    #chronology-container .fa-share {
      font-size: 75%;
      margin-right: 5px;
    }

#chronology-navigation {
  @include flex-center;
  align-items: flex-start;
  width: 100%;
}
#chronology-main-select {
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
// #chronology-title {
//   @include about-title;
// }

#chronology-content {
  @include flex-center;
  flex-direction: column;
}
  .inner-list {
    width: 50%;
  }
  .inner-photos {
    width: 75%;
  }

.chronology-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
  .chronology-item-margin {
    margin-bottom: 5px;
  }
  .chronology-title span {
    white-space: pre;
  }

.chronology-item-logo-container img {
  margin-right: 5px;
}

.chronology-photos-info-container {
  @include flex-center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 30px;
}
  .chronology-photos-info-title {
    @include about-title;
    margin-bottom: 0;
  }

.chronology-photos-description {
  margin-bottom: 15px;
}
  .chronology-photos-description :deep(a) {
    color: white;
  }

.chronology-thumbnails-container {
  @include flex-center;
  flex-wrap: wrap;
  // line-height: 0;
  // -webkit-column-count: 5;
  // -webkit-column-gap: 0px;
  // -moz-column-count: 5;
  // -moz-column-gap: 0px;
  // column-count: 5;
  // column-gap: 0px;
  background-color: rgba($mwnf-color, 0.1);
  padding: 10px;
  margin-bottom: 30px;
}
  .chronology-thumbnail {
    // height: 100px;
    cursor: pointer;
    padding: 4px;
  }
    .chronology-thumbnail img {
      @include image-scale;
    }

#chronology-photos-overlay {
  @include flex-center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(black, 0.8);
  z-index: 100;
}
  #chronology-photo {
    max-height: 90%;
    max-width: 90%;
    margin-bottom: 15px;
  }
    #chronology-photo img {
      @include image-scale;
    }
  #chronology-photo-info {
    max-width: 50%;
  }
    .photo-info {
      margin-bottom: 5px;
    }
  
.chronology-pdfs-header {
  font-weight: bold;
  margin-bottom: 10px;
}
  .chronology-pdf-link {
    color: white;
  }

@media only screen and (max-width: 1199px) {
  .inner-list {
    width: 60%;
  }
  .inner-photos {
    width: 100%;
  }
}

@media only screen and (max-width: 949px) {
  #chronology-container {
    padding: 50px;
  }
  .inner-list {
    width: 70%;
  }
}

@media only screen and (max-width: 649px) {
  .inner-list {
    width: 80%;
  }
}

@media only screen and (max-width: 549px) {
  #chronology-container {
    padding: 30px;
  }
  .inner-list {
    width: 90%;
  }
}

@media only screen and (max-width: 449px) {
  #chronology-main-select {
    width: 200px;
  }
}

</style>