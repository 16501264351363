<template>

<div id="gallery-container">
  <LoaderComponent v-if="!loaded" />
  <div v-else id="gallery-wrapper">
    <div class="gallery-image"
      v-if="gallery && gallery.image && gallery.image.image"
      @mouseover="showCaption = true"
      @mouseleave="showCaption = false">
      <img :src="gallery.image.image" />
      <div class="gallery-image-caption" v-if="showCaption">
        <span class="gallery-image-caption-name"
          v-html="gallery.image.item.itemName">
        </span>
        <span>, </span>
        <span v-if="gallery.image.item.caption.length > 0">{{ gallery.image.item.caption }}, </span>
        <span v-if="gallery.image.item.dynasty.length > 0"
          class="gallery-image-caption-dynasty"
          v-html="gallery.image.item.dynasty">
        </span>
        <span v-if="gallery.image.item.dynasty.length > 0">, </span>
        <span v-if="gallery.image.item.holdingMuseum.length > 0"
          class="gallery-image-caption-holding">{{ gallery.image.item.holdingMuseum }}</span>
        <span v-if="gallery.image.item.holdingMuseum.length > 0">, </span>
        <span class="gallery-image-caption-location">{{ gallery.image.item.location }}, </span>
        <span class="gallery-image-caption-country">{{ gallery.image.item.country }}</span>
        <span class="gallery-image-caption-detail"> (Detail)</span>
      </div>
      <div class="gallery-image-label">
        {{ gallery.title.toUpperCase() }}
      </div>
    </div>
    <div class="gallery-info">
      <a id="galleries" class="gallery-info-header"
        :href="`${gallery.galleriesUrl}/1`" name="galleries">
        <div>MWNF GALLERIES</div>
        <font-awesome-icon :icon="['fas', 'arrow-right']" />
      </a>
      <div class="gallery-featured-container" v-if="gallery && gallery.galleries">
        <div class="gallery-numbers-container">
          <div class="gallery-number">
            <div class="number">{{ gallery.galleries.number }}</div>
            <div class="label">Galleries</div>
          </div>
          <div class="gallery-number">
            <div class="number">{{ gallery.artefacts.number }}</div>
            <div class="label">Artefacts</div>
          </div>
          <div class="gallery-number">
            <div class="number">{{ gallery.countries.number }}</div>
            <div class="label">Countries</div>
          </div>
        </div>
        <div class="gallery-description">{{ gallery.description }}</div>
        <div class="gallery-featured">FEATURED GALLERY</div>
        <a class="gallery-featured-link" :href="gallery.url">
          <div class="gallery-name">{{ gallery.title.toUpperCase() }}</div>
          <font-awesome-icon :icon="['fas', 'arrow-right']" />
        </a>
      </div>
      <div id="gallery-more-container">
        <div id="gallery-more">MORE</div>
        <a id="gallery-new-partners" :href="gallery_partners_website">
        <!-- <a id="gallery-new-partners" href="http://galleries.museumwnf.org/partners"> -->
          <div>NEW PARTNERS</div>
          <font-awesome-icon :icon="['fas', 'arrow-right']" />
        </a>
      </div>
      <div id="gallery-discover-museums">
        <div id="gallery-discover-related">RELATED</div>
        <a v-for="related in gallery.relatedLinks" :key="related.title"
          class="gallery-discover-museum" :href="related.url">
          <div>{{ related.title.toUpperCase() }}</div>
          <font-awesome-icon :icon="['fas', 'arrow-right']" />
        </a>
      </div>
    </div>
  </div>
</div>

</template>



<script>

import LoaderComponent from "./LoaderComponent.vue";
import functions from "../mixins/functions.js";
import media from "../mixins/media.js";
export default {
  name: "GalleriesComponent",
  mixins: [functions, media],
  components: {
    "LoaderComponent": LoaderComponent
  },
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      gallery: {},
      showCaption: false,
      loaded: false,
      mwnf_website: process.env.VUE_APP_MWNF_WEBSITE,
      api_website: process.env.VUE_APP_API_ENDPOINT,
      gallery_partners_website: process.env.VUE_APP_GALLERYPARTNERS_WEBSITE,
      cookies_website: process.env.VUE_APP_MWNF_COOKIES,
    }
  },
  methods: {
    getGallery: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/mwnf-gallery" }).then(result => {
        this.gallery = result.data.data;
        this.audit(this.gallery.image.item);
        this.loaded = true;
      }, error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.getGallery();
  }
}

</script>



<style lang="scss">

#gallery-container {
  height: 525px;
  width: 100%;
  cursor: default;
}

#gallery-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.gallery-info-header div,
.gallery-name,
#gallery-new-partners div,
.gallery-discover-museum div {
  @include roboto;
}

.gallery-image {
  position: relative;
  height: 100%;
  width: 60%;
  background-color: $image-background;
}
  .gallery-image img {
    @include image-scale;
    object-fit: cover;
  }
  .gallery-image-caption {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 50%;
    font-size: 12px;
    color: $light-text;
    background-color: rgba(black, 0.5);
    padding: 10px;
  }
  .gallery-image-label {
    @include flex-center;
    position: absolute;
    bottom: 0;
    height: 30px;
    width: 100%;
    color: $light-text;
    background-color: rgba($g-color, 0.5);
    padding: 5px;
  }
  
.gallery-info {
  height: 100%;
  width: 40%;
  font-weight: bold;
  color: $light-text;
  background-color: $g-color;
  padding: 50px 60px;
}

.gallery-info-header {
  @include panel-header;
  @include link-styles;
  @include scroll-top;
  display: flex;
  justify-content: space-between;
  color: $light-text;
  margin-bottom: 20px;
}
.gallery-numbers-container {
  @include panel-details;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
  .gallery-number {
    display: flex;
    flex-direction: column;
    margin: 0 15px;
  }
    .gallery-number:first-child {
      margin-left: 0;
    }
  .label {
    font-weight: normal;
  }
.gallery-description {
  @include panel-details;
  font-weight: normal;
  margin-bottom: 30px;
}
.gallery-featured {
  @include panel-details;
  font-weight: normal;
  margin-bottom: 10px;
}
.gallery-featured-link {
  @include panel-link;
  @include link-styles;
  display: flex;
  justify-content: space-between;
  color: $light-text;
  margin-bottom: 25px;
}

#gallery-more-container,
#gallery-discover-museums {
  display: flex;
  flex-direction: column;
}
  #gallery-more-container {
    margin-bottom: 10px;
  }
  #gallery-more,
  #gallery-discover-related {
    @include panel-details;
    font-weight: normal;
    margin-bottom: 10px;
  }
  #gallery-new-partners,
  .gallery-discover-museum {
    @include panel-details;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    color: $light-text;
    text-decoration: none;
    margin-bottom: 10px;
  }
  #gallery-discover-related {
    color: $light-grey;
  }
  .gallery-discover-museum {
    color: $light-grey;
  }

@media only screen and (max-width: 1349px) {
  .gallery-image {
    width: 50%;
  }
  .gallery-info {
    width: 50%;
  }
}

@media only screen and (max-width: 1199px) {
  .gallery-info {
    padding: 40px 50px;
  }
  .gallery-info-header {
    @include scroll-top-increased;
  }
  .gallery-image-caption {
    max-width: 75%;
  }
}

@media only screen and (max-width: 974px) {
  #gallery-container {
    height: 500px;
  }
  .gallery-info-header {
    @include panel-header-reduced;
  }
  .gallery-featured-link {
    @include panel-link-reduced;
  }
  .gallery-numbers-container,
  .gallery-description,
  .gallery-featured,
  #gallery-more,
  #gallery-discover-related,
  #gallery-new-partners,
  .gallery-discover-museum {
    @include panel-details-reduced;
  }
}

@media only screen and (max-width: 874px) {
  #gallery-container {
    height: auto;
  }
  #gallery-wrapper {
    flex-direction: column;
  }
    .gallery-image {
      height: 450px;
      width: 100%;
    }
    .gallery-info {
      width: 100%;
      padding: 30px 100px;
    }
  .gallery-numbers-container {
    width: 275px;
  }
}

@media only screen and (max-width: 599px) {
  .gallery-image {
    height: 400px;
  }
  .gallery-info {
    padding: 30px 60px;
  }
}

@media only screen and (max-width: 499px) {
  .gallery-image {
    height: 350px;
  }
  .gallery-info-header {
    @include scroll-top-decreased;
  }
  .gallery-info {
    padding: 30px;
  }
}

@media only screen and (max-width: 424px) {
  .gallery-image {
    height: 300px;
  }
  .gallery-info-header {
    @include panel-header-smallest;
  }
  .gallery-featured-link {
    @include panel-link-smallest;
  }
  .gallery-numbers-container {
    width: 100%;
  }
}

</style>
