<template>

<div id="exhibitions-wrapper">

   <div id="exhibitions-top-links-container">
    <BackComponent />
    <router-link id="exhibitions-cycles-link" :to="{ name: 'cycles', params: { page: '1'} }">
      See also our Exhibition Cycles <font-awesome-icon :icon="['fas', 'share']" />
    </router-link>
  </div>

  <div id="page-navigation" class="page-navigation-wrapper" v-if="pageInfo.last_page > 1">
    <PagesComponent :pageInfo="pageInfo" :navigatePage="navigatePage" :navigatePageDirect="navigatePageDirect"></PagesComponent>
  </div>

  <LoaderComponent v-if="!loaded" />
  <div v-else class="exhibitions-container">
    <div id="exhibitions-title">MWNF EXHIBITIONS</div>
    <div class="exhibitions-note-container" v-if="note">
      <div class="exhibitions-note-label" 
        :class="{ 'margin': !noteShow  }"
        @click="noteShow = !noteShow">
        <span v-if="noteShow"><font-awesome-icon :icon="['fas', 'chevron-down']" /> </span>
        <span v-else><font-awesome-icon :icon="['fas', 'chevron-right']" /> </span>
        {{ $i18n.t('exhibitionVisitorInfoLabel') }}
      </div>
      <div class="exhibitions-note" v-if="noteShow" v-html="note"></div>
    </div>
    <div class="exhibition-page-panel" v-for="(exhibition, key) in exhibitions" :key="key">
      <div class="exhibition-image">
        <img :src="exhibition.portalImage" />
        <!-- Optional "NEW" indicator -->
        <div class="exhibition-new-sticker" v-if="exhibition.newSticker">
          <div class="exhibition-new-triangle"></div>
          <div class="exhibition-new">NEW</div>
        </div>
      </div>
      <div class="exhibition-information">
        <div class="exhibition-title-wrapper">
          <a class="exhibition-title" v-if="exhibition && exhibition.title && exhibition.url"
            :href="exhibition.url" :target="exhibition.videoExhibition ? '_blank' : '_self'">
            <div class="exhibition-top-wrapper">
              <div class="exhibition-title-roboto">
                {{ exhibition.title }} 
              </div>
              <div class="exhibition-subtitle">
                {{ exhibition.subtitle }}
              </div>
            </div>
            <font-awesome-icon :icon="['fas', 'arrow-right']" />
          </a>
          <div class="exhibition-title" v-else-if="exhibition && exhibition.title">
            <div class="exhibition-top-wrapper">
              <div class="exhibition-title-roboto">
                {{ exhibition.title }} 
              </div>
              <div class="exhibition-subtitle">
                {{ exhibition.subtitle }}
              </div>
            </div>
          </div>
        </div>
        <div class="exhibition-info-wrapper">
          <div class="exhibition-heading" v-if="exhibition && exhibition.description"
            v-html="exhibition.description"></div>
          <div class="exhibition-description" v-if="exhibition && !exhibition.description"
            v-html="truncate(200, exhibition.about)"></div>
          <div class="exhibition-full-description"
            v-if="exhibition && !exhibition.description && exhibition.about.length > 200"
            @click="showDescription = true, exhibitionID = key">
            Read More.
          </div>
          <div class="exhibition-image-caption italic" v-if="exhibition.exhImageCaption">Image: {{ exhibition.exhImageCaption }}</div>
        </div>
        <div class="exhibition-description-popup" v-if="showDescription && exhibitionID === key">
          <div class="exhibition-popup-close" @click="showDescription = false">
            <font-awesome-icon :icon="['fas', 'times']"/>
          </div>
          <div class="exhibition-popup-text" v-html="exhibition.about"></div>
        </div>
      </div>
    </div>
  </div>

  <div id="page-navigation" class="page-navigation-wrapper" v-if="pageInfo.last_page > 1">
    <PagesComponent :pageInfo="pageInfo" :navigatePage="navigatePage" :navigatePageDirect="navigatePageDirect"></PagesComponent>
  </div>

</div>

</template>



<script>

import { loadLocaleMessages } from "../i18n.js";
import LoaderComponent from "../components/LoaderComponent.vue";
import PagesComponent from "../components/PagesComponent.vue";
import BackComponent from "../components/BackComponent.vue";
import functions from "../mixins/functions.js";
import media from "../mixins/media.js";
export default {
  name: "ExhibitionsPage",
  components: {
    "PagesComponent": PagesComponent,
    "BackComponent": BackComponent,
    "LoaderComponent": LoaderComponent
  },
  mixins: [functions, media],
  data() {
    return {
      note: "",
      noteShow: false,
      exhibitions: [],
      showDescription: false,
      exhibitionID: null,
      pageInfo: {},
      pages: {},
      loaded: false,
    }
  },
  watch: {
    $route(to, from) {
      this.getExhibitions();
    }
  },
  methods: {
    getExhibitions: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + `/new-exhibition-list?page=${this.$route.params.page}` }).then(result => {
        this.note = result.data.data.visitorInformation;
        this.exhibitions = result.data.data.listExhibitions;
        this.pageInfo = result.data.data.meta;
        this.pages = result.data.data.links;
        this.loaded = true;
      }, error => {
        console.error(error);
      });
    },
     getPageNumber: function(url) {
      let start = url.lastIndexOf("=") + 1;
      return url.substring(start);
    },
    navigatePage: function(page) {
      let number;
      if (page === "first") {
        number = this.getPageNumber(this.pages.first);
      } else if (page === "last") {
        number = this.getPageNumber(this.pages.last);
      } else if (page === "next") {
        number = this.getPageNumber(this.pages.next);
      } else if (page === "previous") {
        number = this.getPageNumber(this.pages.prev);
      }
      this.$router.push({ name: "projects", params: { page: number } });
    },
    // Navigate directly to a page by clicking a number
    navigatePageDirect: function(page) {
      this.$router.push({ name: "projects", params: { page: page } });
    },
  },
  mounted() {
    this.getExhibitions();
  },
   // Load i18n messages in correct language
  beforeRouteEnter(to, from, next) {
    loadLocaleMessages("en").then(() => next()); 
  },
  beforeRouteUpdate(to, from, next) {
    loadLocaleMessages("en").then(() => next());
  }
}

</script>



<style lang="scss">

.italic {
  font-style: italic;
}

#exhibitions-wrapper {
  width: 100%;
  padding: 50px 100px;
}

#exhibitions-top-links-container {
  display: flex;
  width: 100%;
}
  #exhibitions-cycles-link {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    font-size: 14px;
    color: black;
    font-weight: bold;
    text-decoration: none;
    padding: 2px 8px 2px 0;
    .fa-share {
      margin-left: 4px;
    }
  }

.loader-container {
  height: 350px;
  width: 100%;
}

.exhibitions-container {
  @include flex-center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

#exhibitions-title {
  @include panel-header;
  @include roboto;
  justify-self: flex-start;
  width: 100%;
  font-weight: bold;
}

.exhibitions-note-container {
  width: 100%;
  // margin-bottom: 30px;
}
  .exhibitions-note-label {
    text-decoration: underline;
    background-color: $exh-color;
    padding: 10px;
    cursor: pointer;
  }
    .exhibitions-note-label.margin {
      margin-bottom: 30px;
    }
  .exhibitions-note {
    // background-color: $exh-light-color;
    padding: 10px;
    margin-bottom: 30px;
  }

.exhibition-page-panel {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
}

// $projects-color is fallback
// For every new Project, add to _variables.scss
// Look for .isl/.bar/.awe classes to find all the places to add new colors

.exhibition-image {
  // display: flex;
  // flex-basis: 0px;
  // flex-grow: 1;
  position: relative;
  height: 250px;
  // max-height: 100%;
  // height: 100%;
  width: 35%;
  background-color: $exh-color;
  border-right: 20px solid $exh-color;
  border-left: 20px solid $exh-color;
}
  .exhibition-image img {
    @include image-scale;
    object-fit: cover;
  }

.exhibition-new-sticker {
  height: 100px;
  width: 100px;
  position: absolute;
  top: 0;
  right: -20px;
}
  .exhibition-new-triangle {
    position: absolute;
    top: 0;
    right: 0;
    height: 0;
    width: 0;
    border-top: 100px solid red;
    border-left: 100px solid transparent;
  }
  .exhibition-new {
    @include flex-center;
    height: 50px;
    width: 50px;
    position: absolute;
    top: 0;
    right: -15%;
    transform: rotate(45deg);
    transform-origin: top left;
    color: $light-text;
    font-size: 175%;
    font-weight: bold;
  }

.exhibition-information {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 65%;
  padding-left: 30px;
}

.exhibition-title-roboto {
  @include roboto;
}

.exhibition-title-wrapper {
  padding: 8px;
}
// Text color matters - if darker color, $light-text (white)
// If lighter color, $dark-text (black)
.exhibition-title-wrapper,
.exhibition-title-wrapper > * {
  color: $dark-text;
  background-color: $exh-color;
}
  .exhibition-featured {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .exhibition-title {
    @include link-styles;
    @include panel-link;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    font-weight: bold;
  }
    .exhibition-subtitle {
      font-size: 14px;
      font-weight: normal;
    }
    .exhibition-exhibitions {
      font-size: 18px;
      margin-top: 5px;
    }

.exhibition-info-wrapper {  
  height: 100%;
  padding: 18px 8px;
}
  .exhibition-info-wrapper,
  .exhibition-info-wrapper > * {
    background-color: $exh-light-color;
  }

.exhibition-languages-header {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}
.exhibition-languages {
  @include panel-details;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
  .exhibition-language {
    display: flex;
  }
  .exhibition-comma {
    margin-right: 3px;
  }

.exhibition-heading,
.exhibition-description,
.exhibition-image-caption {
  @include panel-details;
  margin-bottom: 5px;
}
  .exhibition-image-caption {
    margin-top: 20px;
  }
  .exhibition-full-description {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }

.exhibition-sponsors {
  @include panel-details;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  margin-top: 15px;
}
  .exhibition-sponsor {
    display: flex;
  }
  .exhibition-sponsor-link {
    display: flex;
  }

.exhibition-description-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: $projects-color;
  border: 2px solid white;
  border-radius: 5px;
  padding: 10px;
  z-index: 50;
}
  .exhibition-description-popup.isl {
    background-color: $dia-color;
  }
  .exhibition-description-popup.bar {
    background-color: $dba-color;
  }
  .exhibition-description-popup.awe {
    background-color: $sh-color;
  }
  .exhibition-description-popup.usa {
    background-color: $usa-color;
  } 
  .exhibition-description-popup.rus {
    background-color: $rus-color;
  }
  .exhibition-popup-close {
    @include flex-center;
    position: absolute;
    top: -20px;
    right: -20px;
    height: 40px;
    width: 40px;
    background-color: $projects-color;
    border: 2px solid white;
    border-radius: 100%;
    cursor: pointer;
  }
    .exhibition-popup-close.isl {
      color: $dia-text;
      background-color: $dia-color;
    }
    .exhibition-popup-close.bar {
      color: $light-text;
      background-color: $dba-color;
    }
    .exhibition-popup-close.awe {
      color: $light-text;
      background-color: $sh-color;
    }
    .exhibition-popup-close.usa {
      color: $dark-text;
      background-color: $usa-color;
    }
    .exhibition-popup-close.rus {
      color: $dark-text;
      background-color: $rus-color;
    }
  .exhibition-popup-text {
    @include panel-details;
    background-color: $background-color;
    padding: 20px;
  }

@media only screen and (max-width: 1099px) {
  #exhibitions-wrapper {
    padding: 20px 50px 50px 50px;
  }
}

@media only screen and (max-width: 749px) {
  .exhibition-page-panel {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .exhibition-image {
    width: 100%;
    margin-bottom: 10px;
  }
  .exhibition-information {
    min-height: 0;
    width: 100%;
    padding-left: 0;
  }
}

@media only screen and (max-width: 599px) {
  #exhibitions-wrapper {
    padding: 10px 30px 30px 30px;
  }
}

@media only screen and (max-width: 499px) {
  #exhibitions-wrapper {
    padding: 10px 20px 20px 20px;
  }
}

</style>
