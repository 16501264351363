<template>

<div id="friends-container">
  <LoaderComponent v-if="!loaded" />
  <div id="friends-wrapper" v-else>
    <div id="friends-list-container">
      <div id="friends-back-container">
        <BackComponent />
        <select id="friends-task-select" name="friends-task"
          v-model="task" @change="filterByTask($event)">
          <option selected disabled value="">Filter by Volunteer Task</option>
          <option 
            v-if="task.length > 0 && task !== 'See All'"
            key="reset"
            :value="apiLink + '/view-friends-mwnf'">
            See All
          </option>
          <option v-for="item in taskList" :key="item.name"
            :value="item.filters.href">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div id="friends-list-title">{{ info.pageHeading }}</div>
      <div class="volunteer-popup-toggle" @click="showVolunteerAbout = true, showVolunteerBecome = false">
        <font-awesome-icon :icon="['fas', 'share']" />Read About MWNF Volunteers
      </div>
      <div id="volunteer-about-popup" v-if="showVolunteerAbout">
        <div id="volunteer-about-popup-close"
          @click="showVolunteerAbout = false">
          <font-awesome-icon :icon="['fas', 'times']"/>
        </div>
        <div id="volunteer-about" v-html="$i18n.t('volunteerAboutText')"></div>
      </div>
      <div class="volunteer-popup-toggle" @click="showVolunteerBecome = true, showVolunteerAbout = false">
        <font-awesome-icon :icon="['fas', 'share']" />Become a MWNF Volunteer
      </div>
      <div id="volunteer-become-popup" v-if="showVolunteerBecome">
        <div id="volunteer-become-popup-close"
          @click="showVolunteerBecome = false">
          <font-awesome-icon :icon="['fas', 'times']"/>
        </div>
        <div id="volunteer-become" v-html="$i18n.t('volunteerBecomeText')"></div>
      </div>
      <div id="friends-list">
        <div v-for="(friend, index) in friends" :key="friend.name" class="friend">
          <div class="friend-image">
            <img v-if="friend.image" :src="friend.image" />
            <img v-else src="../assets/profile-placeholder.png" />
          </div>
          <div>{{ friend.first_name }} {{ friend.family_name }}</div>
          <div>{{ friend.location }}</div>
          <div>{{ friend.profession }}</div>
          <div v-if="friend.contact_email">
            <span>{{ $i18n.t('volunteerContact') }}: </span>
            {{ friend.contact_email }}
          </div>
          <div v-if="friend.volunteer_task">
            <div class="volunteer-tasks">{{ $i18n.t('volunteerTaskHeader') }}</div>
            <div>{{ friend.volunteer_task }}</div>
          </div>
          <div v-if="friend.from_period">
            {{ friend.from_period }}
            <span v-if="friend.from_period"> - </span>
            <span v-if="friend.to_period">{{ friend.to_period }}</span>
            <span v-else>{{ $i18n.t('volunteerDateOngoing') }}</span>
          </div>
          <div v-if="friend.volunteer_task_more_info"
            class="friend-more-info-toggle"
            @click="toggleMoreInfo(index)">
            {{ $i18n.t('volunteerMoreHeader') }}
          </div>
          <div class="friend-more-info" 
            v-if="moreIndex === index"
            v-html="friend.volunteer_task_more_info">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>


<script>

import { loadLocaleMessages } from "../i18n.js";
import media from "../mixins/media.js";
import LoaderComponent from "./LoaderComponent.vue";
import BackComponent from "./BackComponent.vue";
export default { 
  name: "VolunteersPage",
  components: {
    "LoaderComponent": LoaderComponent,
    "BackComponent": BackComponent
  },
  mixins: [media],
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      info: {},
      friends: [],
      loaded: false,
      task: "",
      taskList: {},
      apiLink: process.env.VUE_APP_API_ENDPOINT,
      moreIndex: null,
      showVolunteerAbout: false,
      showVolunteerBecome: false,
    }
  },
  watch: {
    $route(to, from) {
      console.log("route change");
      console.log("from: ", from.hash, "to: ", to.hash);
      if (from.hash && !to.hash && this.task.length > 0) {
        this.task = "";
        this.getFriendsInfo();
        this.getFriendsTaskList();
      }
    }
  },
  methods: {
    getFriendsInfo: function() {
      console.log(`${process.env.VUE_APP_API_ENDPOINT}/view-friends-mwnf`);
        return axios({ method: "GET", "url": `${process.env.VUE_APP_API_ENDPOINT}/view-friends-mwnf` }).then(result => {
          this.info = result.data.data;
          this.friends = result.data.items;
          this.loaded = true;
        }, error => {
          console.error(error);
        });
    },
    getFriendsTaskList: function() {
      console.log(`${process.env.VUE_APP_API_ENDPOINT}/friends-mwnf-task`);
      return axios({ method: "GET", "url": `${process.env.VUE_APP_API_ENDPOINT}/friends-mwnf-task` }).then(result => {
        this.taskList = result.data.data.volunteer_tasks;
      }, error => {
        console.error(error);
      });
    },
    filterByTask: function(e) {
      if (!this.$route.hash) {
        this.$router.replace({ name: "volunteers", hash: "#" });
      }
      return axios({ method: "GET", "url": e.target.value }).then(result => {
        this.info = result.data.data;
        this.friends = result.data.items;
      }, error => {
        console.error(error);
      });
    },
    toggleMoreInfo(index) {
      if (this.moreIndex === index) {
        this.moreIndex = null;
      } else {
        this.moreIndex = index;
      }
    }
  },
  mounted() {
    this.getFriendsInfo();
    this.getFriendsTaskList();
  },
  // Load i18n messages in correct language
  beforeRouteEnter(to, from, next) {
    loadLocaleMessages("en").then(() => next()); 
  },
  beforeRouteUpdate(to, from, next) {
    loadLocaleMessages("en").then(() => next());
  }
}

</script>



<style scoped lang="scss">

#friends-container {
  @include flex-center;
  flex-direction: column;
  width: 100%;
  color: $light-text;
  background-color: $about-color;
  padding: 50px;
}
#friends-wrapper {
  @include flex-center;
  width: 100%;
}
.loader-container {
  height: 100%;
  background-color: $about-color;
}

#friends-information-wrapper {
  @include flex-center;
  flex-direction: column;
}
  #friends-information-container {
    @include flex-center;
    flex-direction: column;
    width: 55%;
    background-color: rgba($mwnf-color, 0.1);
    padding: 50px;
  }
    #friends-information-container :deep(a) {
      color: $light-text;
    }
    #friends-information-container .fa-share {
      font-size: 75%;
      margin-right: 5px;
    }
    #friends-information-container a {
      text-decoration: underline;
      cursor: pointer;
    }
  #friends-title {
    @include about-title;
    @include flex-center;
    text-align: center;
    margin-bottom: 15px;
  }
  #friends-logo-link {
    width: 150px;
  }
    #friends-logo-link img {
      @include image-scale;
      object-fit: contain;
    }
  #friends-information {
    margin: 20px 0;
  }

#friends-list-container {
  @include flex-center;
  flex-direction: column;
  position: relative;
  width: 100%;
}
  #friends-back-container {
    @include flex-center;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
    #friends-task-select {
      width: 250px;
    }
  #friends-list-title {
    @include about-title;
    @include flex-center;
    margin-bottom: 15px;
  }
  #friends-list-link {
    color: $light-text;
    margin-bottom: 15px;
  }
    #friends-list-link .fa-share {
      font-size: 75%;
      margin-right: 5px;
    }

  .volunteer-popup-toggle {
    color: $light-text;
    text-decoration: underline;
    margin-bottom: 15px;
    cursor: pointer;
  }
    .volunteer-popup-toggle .fa-share {
      font-size: 75%;
      margin-right: 5px;
    }
  #volunteer-about-popup,
  #volunteer-become-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(1350px - 30%);
    color: $mwnf-color;
    background-color: white;
    border: 2px solid $about-color;
    border-radius: 5px;
    padding: 10px;
    z-index: 50;
  }
    #volunteer-about-popup :deep(a),
    #volunteer-become-popup :deep(a) {
      color: $mwnf-color;
    }
    #volunteer-about-popup-close,
    #volunteer-become-popup-close {
      @include flex-center;
      position: absolute;
      top: -20px;
      right: -20px;
      height: 40px;
      width: 40px;
      background-color: white;
      border: 2px solid $about-color;
      border-radius: 100%;
      cursor: pointer;
    }

  #friends-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
    .friend {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      width: calc(25% - 10px);
      text-align: center;
      background-color: rgba($mwnf-color, 0.1);
      padding: 20px 10px;
      margin: 5px;
      word-break: break-word;
      hyphens: auto;
    }
      .friend-image {
        height: 200px;
        width: 200px;
        margin-bottom: 10px;
      }
        .friend-image img {
          @include image-scale;
          object-fit: contain;
        }
      .volunteer-tasks {
        font-weight: bold;
        margin-top: 10px;
      }
      .friend-more-info-toggle {
        text-decoration: underline;
        cursor: pointer;
      }
      .friend-more-info {
        text-align: left;
        margin: 10px 0;
      }

@media only screen and (max-width: 1349px) {
  #volunteer-about-popup,
  #volunteer-become-popup {
    width: 80%;
  }
}

@media only screen and (max-width: 1049px) {
  .friend {
    width: calc(33.3% - 10px);
  }
}

@media only screen and (max-width: 949px) {
  #friends-container {
    padding: 30px;
  }
  #friends-information-container {
    padding: 30px;
  }
  .friend-image {
    height: 175px;
    width: 175px;
  }
}

@media only screen and (max-width: 749px) {
  .friend {
    width: calc(50% - 10px);
  }
}

@media only screen and (max-width: 599px) {
  #friends-list {
    font-size: 90%;
  }
  .friend {
    width: 100%;
  }
}

@media only screen and (max-width: 449px) {
  #friends-task-select {
    width: 200px;
  }
}

</style>