export default { 
  name: "functions",
  methods: {
    // Removes extra spaces/periods
    audit: function(object) {
      for (let field in object) {
        let string = object[field];
        if (typeof string === "number") {
          continue;
        } else if (string && string.slice(-1) === "." ||
          string && string.slice(-1) === " ") {
          string = string.substring(0, string.length - 1);
          if (string.slice(-1) === "." || string.slice(-1) === " ") {
            string = string.substring(0, string.length - 1);
          }
          object[field] = string;
        }
      }
    },
    // Truncates description text and adds "..."
    truncate: function(chars, text) {
      if (text) {
        if (text.length <= chars) {
          return text;
        } else {
          let space = " ";
          let slice = text.substring(0, text.lastIndexOf(space, chars));
          slice = slice + "...";
          return slice;
        }
      }
    }
  }
}