import { createApp } from "vue"
import App from "./App.vue"
import router from "./route.js"
import i18n from "./i18n.js"


/* <font-awesome-icon :icon="['fas', 'chevron-circle-left']" /> */
import { library } from "@fortawesome/fontawesome-svg-core";
import { 
  faSearch,
  faTimes, 
  faChevronCircleLeft, 
  faChevronCircleRight, 
  faChevronLeft, 
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faBars,
  faArrowRight,
  faArrowLeft,
  faArrowDown,
  faReply,
  faShare,
  faFileDownload,
  faFilePdf,
  faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { 
  faYoutubeSquare, 
  // faTwitterSquare, 
  faSquareXTwitter,
  faFacebookSquare, 
  faInstagram, 
  faWeibo,
  faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faSearch,
  faTimes,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faBars,
  faArrowRight,
  faArrowLeft,
  faArrowDown,
  faReply,
  faShare,
  faFileDownload,
  faFilePdf,
  faExternalLinkSquareAlt,
  faCopyright, 
  faYoutubeSquare, 
  // faTwitterSquare,
  faSquareXTwitter, 
  faFacebookSquare, 
  faInstagram, 
  faWeibo,
  faLinkedin,
  faInfoCircle);

let app = createApp(App)
.use(router)
.use(i18n)
.component("font-awesome-icon", FontAwesomeIcon)

// app.config.globalProperties.$global = {};
app.config.performance = true;
app.mount("#app")
