<template>

<div id="back">
  <button @click="$router.go(-1)"><font-awesome-icon :icon="['fas', 'reply']" />Back</button>
  <button v-if="!$route.path.includes('chronology') && 
    !$route.path.includes('support') && 
    !$route.path.includes('volunteers') &&
    !$route.path.includes('newsletter') &&
    !$route.path.includes('board') &&
    !$route.path.includes('press')"
    @click="$router.push({ name: 'home' })">
    <font-awesome-icon :icon="['fas', 'reply']" />Home
  </button>
</div>

</template>


<script>

export default { 
  name: "BackComponent",
}

</script>



<style scoped lang="scss">

#back {
  @include flex-center;
  justify-content: flex-start;
  width: 50%;
  font-size: 14px;
  padding: 0 50px 30px 0px;
}
  #back .fa-reply {
    padding-right: 4px;
  }
  #back button {
    @include button-styles;
    color: black;
    font-weight: bold;
    padding: 2px 8px 2px 0;
    margin-right: 10px;
  }
    #chronology-container #back button,
    #board-container #back button,
    #support-container #back button,
    #friends-back-container #back button,
    #newsletters-container #back button,
    #press-lounge-container #back button {
      color: $light-text;
    }
    #board-container #back,
    #support-container #back {
    // #support-container #back,
    // #friends-list-container #back {
      padding-bottom: 0;
    }

@media only screen and (max-width: 599px) {
  #back {
    padding: 0 0 30px 0;
  }
}

</style>