<template>

<div id="about-container">
  <div id="about-header">
    <div id="carousel-container">
      <transition-group id="carousel" tag="div" name="carousel">
        <div class="about-banner-images" v-for="image in bannerImages" :key="image.path" v-show="image.show">
          <a class="image-container" 
            :href="setBannerLink(image.link)"
            target="_blank">
            <img class="banner-image" :src="image.path" />
          </a>
        </div>
      </transition-group>
      <div id="carousel-controls" v-if="!lessThan675">
        <span class="slideshow-bullet" 
          v-for="(image, index) in bannerImages" :key="image.path" 
          @click="resetTimerOnClick(index)" 
          :class="{ highlight: bannerImages[index].show }"></span>
      </div>
    </div>
    <div id="about-header-text-wrapper">
      <div id="about-header-container">
        <div id="about-header-text">ABOUT MWNF</div>
        <div v-if="$route.path !== '/about'" id="about-header-page-name">
          <span class="header-separator" v-if="!lessThan1099">&nbsp;| </span><span>{{ pageName }}</span>
        </div>
      </div>
      <div id="about-header-caption"
        v-if="bannerImages[currentBanner] &&
          bannerImages[currentBanner].more.en.caption && 
          bannerImages[currentBanner].more.en.caption.length > 0 &&
          !lessThan675"
        v-html="bannerImages[currentBanner].more.en.caption"></div>
    </div>
  </div>
  <div id="about-menu">
    <div class="about-menu-item" v-for="menuItem in menuItems" :key="menuItem.label">
      <router-link :to="menuItem.data === 'profile' ? `/about` : { name: menuItem.data }"
        v-if="!lessThan599 || (lessThan599 && showMenu) || (menu[menuItem.data] && lessThan599)"
        :class="{ 'selected': menu[menuItem.data] }"
        @click="handleResponsiveAboutMenu(menuItem.data)">
        {{ menuItem.label }}
        <font-awesome-icon :icon="['fas', 'chevron-down']" v-if="[menuItem.data] && lessThan599 && !showMenu" />
        <font-awesome-icon :icon="['fas', 'chevron-up']" v-if="menu[menuItem.data] && lessThan599 && showMenu" />
      </router-link>
    </div>
  </div>
  <router-view></router-view>
</div>

</template>



<script>

import media from "../mixins/media.js";
export default {
  name: "AboutSection",
  // props: ["confirm"],
  mixins: [media],
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      lessThan599: window.matchMedia("(max-width: 599px)").matches,
      lessThan675: window.matchMedia("(max-width: 675px)").matches,
      lessThan1099: window.matchMedia("(max-width: 1099px)").matches,
      // profile: {}
      bannerImages: [],
      currentBanner: 0,
      interval: "",
      timeout: "",
      pageName: "",
      mwnf_website: process.env.VUE_APP_MWNF_WEBSITE,
      bannerLink: "",
      menu: {
        profile: false,
        chronology: false,
        board: false,
        honcom: false,
        support: false,
        volunteers: false,
        contact: false,
        newsletter: false,
        commemoration: false,
        language: false,
        legal: false,
        credits: false,
        press: false,
        cookies: false
      },
      showMenu: false,
      menuItems: [
        {
          label: "Profile",
          data: "profile"
        },
        {
          label: "Chronology",
          data: "chronology"
        },
        {
          label: "Board",
          data: "board"
        },
        {
          label: "Honorary Committee",
          data: "honcom"
        },
        {
          label: "Support",
          data: "support"
        },
        {
          label: "MWNF Volunteers",
          data: "volunteers"
        },
        {
          label: "Contact",
          data: "contact"
        },
        {
          label: "Newsletter",
          data: "newsletters"
        },
      ]
    }
  },
   watch: {
    "$route" (to, from) {
      // this.checkRoute();
      this.setPageName();
    },
  },
  methods: {
    // checkRoute: function() {
    //   if (this.$route.name === "newsletter") {
    //     this.onSignUpPage = true;
    //   } else {
    //     this.onSignUpPage = false;
    //   }
    // },
    handleResponsiveAboutMenu(page) {
      if (!this.lessThan599) {
        return;
      }
      if (this.menu[page]) {
        this.showMenu = !this.showMenu;
      } else {
        this.showMenu = false;
      }
    },
    setBannerLink: function(link) {
      if (link.includes("http")) {
        return link;
      } else {
        // this needs to be changed to only include a / for the live site
        return `${this.mwnf_website}/${link}`;
      }
    },
    getBannerImages: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/atrium_banner" }).then(result => {
        this.bannerImages = result.data.data;
        for (let i = 0; i < this.bannerImages.length; i++) {
          if (i === 0) {
            // this.$set(this.bannerImages[i], "show", true);
            this.bannerImages[i].show = true;
          } else {
            // this.$set(this.bannerImages[i], "show", false);
            this.bannerImages[i].show = false;
          }
        }
        this.$nextTick(function () {
          this.advanceSlide(0);
        });
      }, error => {
        console.error(error);
      });
    },
    // Hide/show images on a timer
    advanceSlide: function (index) {
      let slide = index;
      this.interval = setInterval(() => { 
        if (slide < this.bannerImages.length - 1) {
          this.showImage(slide + 1);
          slide += 1;
        } else {
          slide = 0;
          this.showImage(slide);
        }
       }, 8000);
    },
    // Hide images, show next in rotation
    showImage: function (index) {
      for (let i = 0; i < this.bannerImages.length; i++) {
        this.bannerImages[i].show = false;
      }
      this.currentBanner = index;
      this.bannerImages[index].show = true;
    },
    // If a slide "dot" is clicked, reset the timer
    resetTimerOnClick: function (index) {
      clearTimeout(this.timeout);
      clearInterval(this.interval);
      this.showImage(index);
      this.timeout = setTimeout(()=>{this.advanceSlide(index)}, 2000);
    },
    setPageName: function () {
      for (let item in this.menu) {
        this.menu[item] = false;
      }
      if (this.$route.path.includes("chronology")) {
        this.pageName = "CHRONOLOGY";
        this.menu.chronology = true;
      } else if (this.$route.path.includes("board")) {
        this.pageName = "BOARD";
        this.menu.board = true;
      } else if (this.$route.path === "/about/honorary-committee") {
        this.pageName = "HONORARY COMMITTEE";
        this.menu.honcom = true;
      } else if (this.$route.path.includes("support")) {
        this.pageName = "SUPPORT";
        this.menu.support = true;
      } else if (this.$route.path.includes("volunteers")) {
        this.pageName = "MWNF VOLUNTEERS";
        this.menu.volunteers = true;
      } else if (this.$route.path === "/about/contact") {
        this.pageName = "CONTACT";
        this.menu.contact = true;
      } else if (this.$route.path.includes("newsletter")) {
        this.pageName = "NEWSLETTER";
        this.menu.newsletter = true;
      } else if (this.$route.path === "/about/commemoration") {
        this.pageName = "IN COMMEMORATION";
        this.menu.commemoration = true;
      } else if (this.$route.path === "/about/language-policy") {
        this.pageName = "LANGUAGE POLICY";
        this.menu.language = true;
      } else if (this.$route.path === "/about/legal-notice") {
        this.pageName = "LEGAL NOTICE";
        this.menu.legal = true;
      } else if (this.$route.path === "/about/credits") {
        this.pageName = "CREDITS";
        this.menu.credits = true;
      } else if (this.$route.path.includes("press-lounge")) {
        this.pageName = "PRESS LOUNGE";
        this.menu.press = true;
      } else if (this.$route.path === "/about/cookies") {
        this.pageName = "COOKIES";
        this.menu.cookies = true;
      } else {
        this.pageName = "";
        this.menu.profile = true;
      }
    }
  },
  mounted() {
    // this.getProfile();
    this.getBannerImages();
    this.setPageName();
  }
}

</script>



<style lang="scss">

#about-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#about-header {
  @include flex-center;
  display: flex;
  justify-content: center;
  position: relative;
  height: 300px;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  // padding: 0 100px;
}
  #about-header-text-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
    height: 75px;
    width: 100%;
    font-weight: bold;
    color: $light-text;
    background-color: rgba(black, 0.4);
    z-index: 1;
  }
    #about-header-container {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
    }
    #about-header-text {
      // display: flex;
      // align-items: center;
      // align-self: flex-end;
      // height: 75px;
      // width: 100%;
      font-size: 40px;
      // font-weight: bold;
      // color: $light-text;
      // background-color: rgba(black, 0.4);
      padding-left: 30px;
      // z-index: 1;
    }
    #about-header-page-name {
      font-size: 40px;
      white-space: pre;
    }
    #about-header-caption {
      max-width: 400px;
      font-size: 12px;
      font-weight: normal;
      text-align: right;
      padding-right: 30px;
    }

#carousel-container {
  @include flex-center;
  flex-direction: column-reverse;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
  #carousel {
    position: relative;
    overflow: hidden;
    height: 100%;
    // height: 32vh;
    // max-height: 275px;
    width: 100%;
  }
    .about-banner-images {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @include flex-center;
      height: 100%;
      width: 100%;
    }
      // Classes for transition-group
      // (Animations)
      .carousel-enter-active,
      .carousel-leave-active {
        transition: all 1s;
      }
      .carousel-enter,
      .carousel-leave-to {
        opacity: 0;
      }
    .image-container {
      @include flex-center;
      height: 100%;
      width: 100%;
      // margin-right: 10px;
    }
      .banner-image {
        @include image-scale;
        object-fit: cover;
      }
#carousel-controls {
  @include flex-center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  // Match sizing to image and descriptive text, include 20px of margin
  width: 100%;
  margin: 20px;
}
  .slideshow-bullet {
    height: 14px;
    width: 14px;
    border-radius: 100%;
    background-color: $about-color;
    box-shadow: 0px 0px 3px white;
    margin-right: 18px;
    cursor: pointer;
  }
    .highlight {
      background-color: $footer-color-1;
    }
// #about-header-page-name span {
//   color: $bright-yellow;
// }
  // #about-header::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   height: 100%;
  //   width: 100%;
  //   background-color: rgba(black, 0.4);
  // }

#about-menu {
  position: sticky;
  top: 100px;
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  color: $light-text;
  background-color: $mwnf-color;
  padding: 8px 0;
  z-index: 50;
}
  #about-menu a {
    color: $light-text;
    text-decoration: none;
  }
    #about-menu .selected {
      color: $bright-yellow;
    }

@media only screen and (max-width: 1199px) {
  #about-header-text {
    font-size: 35px;
  }
  #about-header-page-name {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1099px) {
  #about-header {
    height: 250px;
  }
    .slideshow-bullet {
      height: 12px;
      width: 12px;
    }
  #about-header-container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  #about-header-text {
    font-size: 25px;
  }
  #about-header-page-name {
    font-size: 25px;
    padding-left: 30px;
  }
  #about-header-caption {
    max-width: 350px;
  }
  #about-menu {
    top: 130px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    font-size: 15px;
    background-color: $footer-color-1;
    padding: 0;
  }
    #about-menu a {
      @include flex-center;
      background-color: $mwnf-color;
      padding: 4px;
    }
    #about-menu div:nth-child(-n+4) a {
      margin-bottom: 4px;
    }
}

@media only screen and (max-width: 799px) {
  #about-header {
    height: 200px;
  }
    .slideshow-bullet {
      height: 10px;
      width: 10px;
      margin-right: 22px;
    }
  #about-menu {
    font-size: 14px;
  }
}

@media only screen and (max-width: 599px) {
  #about-menu {
    top: 70px;
    display: flex;
    flex-direction: column;
    // display: flex;
    // flex-wrap: wrap;
  }
    // #about-menu a {
    //   width: 33.33%;
    // }
    // #about-menu a:nth-child(-n+6) {
    //   margin-bottom: 4px;
    // }
    #about-menu .fa-chevron-down,
    #about-menu .fa-chevron-up {
      margin-left: 8px;
    }
    #about-menu div:nth-child(-n+8) a {
      margin-bottom: 2px;
    }
      #about-menu div:last-child a {
        margin-bottom: 0;
      }
}

</style>
