<template>

<div id="results-container">
  <BackComponent />
  <div id="results-search-container">
    <form id="results-search" @submit.prevent>
      <input id="results-search-input" 
        type="search" 
        v-model="input"
        placeholder='Enter search term(s) here'>
      <button class="results-search-button" 
        type="submit"
        @click="search($event)">
        <font-awesome-icon :icon="['fas', 'search']" id="results-search-icon"/>
      </button>
    </form>
    <router-link 
      id="results-important-information-link"
      :to="{ name: 'importantinformation' }">
      Important Information
    </router-link>
  </div>
  <div class="gcse-searchresults-only" data-gname="results">
  </div>
</div>

</template>



<script>

import BackComponent from "../components/BackComponent.vue";
import media from "../mixins/media.js";
export default {
  name: "ResultsPage",
  components: {
    "BackComponent": BackComponent
  },
  mixins: [media],
  data() {
    return {
      input: this.$route.query.q
    }
  },
  watch: {
    "$route" (to, from) {
      this.google();
      this.input = this.$route.query.q;
    },
  }, 
  methods: {
    google: function() {
      let query = this.$route.query.q;
      let cse = google.search.cse.element;
      let results = cse.getElement("results");
      results.execute(query);
    },
    search: function(e) {
      e.preventDefault();
      if (this.input === "") {
        alert("Please enter a search term!");
        return;
      }
      if (this.input === this.$route.query.q) {
        alert("The results for this search term are already being displayed!");
        return;
      }
      this.$router.push({ name: "results", query: { q: this.input } });
    }
  },
  mounted() {
    // Custom Google search
    window.__gcse = {
      initializationCallback: () => { this.google(); }
    };

    let script = document.createElement("script");
    script.src = "https://cse.google.com/cse.js?cx=76134fa4492fd2bdf";
    script.async = true;
    script.id="script";
    document.head.appendChild(script);
  }
}

</script>



<style lang="scss">

#results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 50px 100px;
}

#results-search-container {
  @include flex-center;
  flex-direction: column;
  position: relative;
  height: 100px;
  width: 75%;
}
  #results-search {
    @include flex-center;
    width: 100%;
    position: relative;
    margin-bottom: 10px;
  }
    #results-search-input {
      height: 50px;
      width: 100%;
      border: 1px solid $shadow-grey;
      border-radius: 5px;
      box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
      padding: 6px 10px;
      margin-right: 5px;
    }
      #results-search-input::placeholder {
        font-weight: normal;
        color: $shadow-grey;
      }
      #results-search-input:focus {
        color: $mwnf-color;
      }
      .results-search-button {
        @include button-styles;
        height: 50px;
        width: 50px;
        background-color: $mwnf-color;
        color: $light-text;
        border-radius: 5px;
      }
    #results-important-information-link {
      color: $dark-text;
    }
  
  #___gcse_0 {
    width: 75%;
  }

@media only screen and (max-width: 974px) {
  #results-search-container,
  #___gcse_0 {
    width: 100%;
  }
}

@media only screen and (max-width: 749px) {
  #results-container {
    padding: 20px 50px 50px 50px;
  }
}

@media only screen and (max-width: 599px) {
  #results-container {
    padding: 10px 30px 30px 30px;
  }
}

@media only screen and (max-width: 499px) {
  #results-container {
    padding: 10px 20px 20px 20px;
  }
}

</style>
