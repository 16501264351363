export default { 
  name: "media",
  methods: {
    checkMediaQuery: function() {
      this.lessThan1099 = window.matchMedia("(max-width: 1099px)").matches;
      this.lessThan974 = window.matchMedia("(max-width: 974px)").matches;
      this.lessThan849 = window.matchMedia("(max-width: 849px)").matches;
      this.lessThan724 = window.matchMedia("(max-width: 724px)").matches;
      this.lessThan749 = window.matchMedia("(max-width: 724px)").matches;
      this.lessThan675 = window.matchMedia("(max-width: 675px)").matches;
      this.lessThan599 = window.matchMedia("(max-width: 599px)").matches;
      this.lessThan499 = window.matchMedia("(max-width: 499px)").matches;
      this.lessThan399 = window.matchMedia("(max-width: 399px)").matches;
    },
    hoverHandler: function(type, index) {
      if (type === "over") {
        return this.hover = true, this.show = index;
      } else if (type === "leave") {
        return this.hover = false, this.show = -1;
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.checkMediaQuery);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkMediaQuery);
  } 
}