<template>

<div id="press-lounge-container">
  <LoaderComponent v-if="!loaded" />
  <div id="press-lounge-wrapper" v-else>
    <div id="press-lounge-home-wrapper" v-if="!$route.params.activity">
      <div id="press-lounge-home-title">{{ pageTitle }}</div>
      <div class="press-lounge-home-item"
        v-for="item in items" :key="item.link">
        <div class="press-lounge-date-wrapper press-lounge-home-date">
          <div>{{ item.date_from }}<span v-if="item.date_to && item.date_to.length > 0"> / </span>{{ item.date_to }}</div>
        </div>
        <router-link :to="{ name: 'press', params: { activity: getItemId(item.link) } }">
          <font-awesome-icon :icon="['fas', 'share']" />{{ item.title }}
        </router-link>
      </div>
    </div>
    <div id="press-lounge-activity-wrapper" v-else-if="$route.params.activity && !$route.params.files">
      <BackComponent />
      <div id="press-lounge-home-title">{{ pageTitle }}</div>
      <div class="press-lounge-activity-item"
        v-for="item in items" :key="item.link">
        <div class="press-lounge-date-wrapper">
          <div>{{ item.date_from }}<span v-if="item.date_to && item.date_to.length > 0"> / </span>{{ item.date_to }}</div>
        </div>
        <router-link :to="{ name: 'press', params: { activity: $route.params.activity, files: getItemId(item.link) } }">
          <font-awesome-icon :icon="['fas', 'share']" />{{ item.title }}
        </router-link>
      </div>
    </div>
    <div id="press-lounge-files-wrapper" v-else>
      <BackComponent />
      <div id="press-lounge-files-container" v-if="files && files.length > 0">
        <div class="press-lounge-title">Files</div>
        <div class="press-lounge-files-item"
          v-for="file in files" :key="file.file_link">
          <a :href="file.file_link" target="_blank">
            <font-awesome-icon :icon="['fas', 'external-link-square-alt']" />{{ file.title }}
            <span v-if="file.file_type === 'pdf'">
              <font-awesome-icon :icon="['fas', 'file-pdf']" />
            </span>
            <span v-else>
              <font-awesome-icon :icon="['fas', 'file-download']" />
            </span>
          </a>
          <div>{{ file.text }}</div>
          <div>File type: {{ file.file_type.toUpperCase() }}</div>
          <div>{{ file.file_size }}</div>
        </div>
      </div>
      <div id="press-lounge-images-container" v-if="images && images.length > 0">
        <div class="press-lounge-title">Images</div>
        <div id="press-lounge-images-wrapper">
          <div class="press-lounge-images-item"
            v-for="image in images" :key="image.image">
            <a :href="image.imgLarge" target="_blank">
              <img :src="image.imgSmall" />
            </a>
            <div>{{ image.title }}</div>
            <div>{{ image.text }}</div>
            <div>{{ image.file_size }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>


<script>

import LoaderComponent from "./LoaderComponent.vue";
import BackComponent from "../components/BackComponent.vue";
import media from "../mixins/media.js";
export default { 
  name: "PressLounge",
  mixins: [media],
  components: {
    "LoaderComponent": LoaderComponent,
    "BackComponent": BackComponent
  },
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      pageTitle: "",
      items: [],
      files: [],
      images: [],
      loaded: false
    }
  },
  watch: {
    "$route" (to, from) {
      this.loaded = false;
      this.getPressLoungeInfo();
    },
  },
  methods: {
    getPressLoungeInfo: function() {
      let api;
      if (this.$route.params.activity) {
        if (this.$route.params.files) {
          api = `/atrium_presslounge_pfiles/${this.$route.params.activity}/${this.$route.params.files}`;
        } else {
          api = `/atrium_presslounge_activity/${this.$route.params.activity}`;
        }
      } else {
        api = "/atrium_presslounge";
      }
      console.log(process.env.VUE_APP_API_ENDPOINT + api);
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + api }).then(result => {
        this.pageTitle = result.data.data.pageTitle;
        if (!api.includes("pfiles")) {
          this.items = result.data.items;
        } else {
          this.files = result.data.files;
          this.images = result.data.images;
        }
        this.loaded = true;
      }, error => {
        console.error(error);
      });
    },
    getItemId: function (text) {
      let index = text.lastIndexOf("/") + 1;
      let id = text.substring(index);
      return id;
    }
  },
  mounted() {
    this.getPressLoungeInfo();
  }
}

</script>



<style scoped lang="scss">

#press-lounge-container {
  @include flex-center;
  flex-direction: column;
  width: 100%;
  color: $light-text;
  background-color: $about-color;
  padding: 50px;
}
  #press-lounge-container .fa-share {
    font-size: 75%;
    margin-right: 5px;
  }
  #press-lounge-container .fa-external-link-square-alt {
    margin-right: 5px;
  }
  #press-lounge-container .fa-file-pdf,
  #press-lounge-container .fa-file-download {
    margin-left: 5px;
  }
  #press-lounge-container :deep(a) {
    color: $light-text;
  }

.loader-container {
  height: 100%;
  background-color: $about-color;
}

#press-lounge-wrapper {
  @include flex-center;
  width: 100%;
}

#press-lounge-home-title {
  @include flex-center;
  @include about-title;
}
.press-lounge-home-item,
.press-lounge-activity-item {
  margin-bottom: 20px;
}
.press-lounge-home-date {
  color: $bright-yellow;
}

#press-lounge-files-wrapper {
  @include flex-center;
  flex-direction: column;
  width: 75%;
}

.press-lounge-title {
  @include about-title;
}
#press-lounge-images-container .press-lounge-title {
  @include flex-center;
}

#press-lounge-files-container {
  // align-self: flex-start;
  margin-bottom: 30px;
}
.press-lounge-files-item {
  margin-bottom: 10px;
}

#press-lounge-images-container {
  background-color: rgba($mwnf-color, 0.1);
  padding: 30px;
}
#press-lounge-images-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  margin-bottom: 30px;
}
  .press-lounge-images-item {
    @include flex-center;
    flex-direction: column;
  }
    .press-lounge-images-item a img {
      @include image-scale;
      height: auto;
      object-fit: cover;
      margin-bottom: 5px;
    }

@media only screen and (max-width: 1199px) {
  #press-lounge-files-wrapper {
    width: 90%;
  }
}

@media only screen and (max-width: 999px) {
  #press-lounge-files-wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 899px) {
  #press-lounge-images-wrapper {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 674px) {
  #press-lounge-home-title {
    @include about-title-reduced;
  }
  .press-lounge-title {
    @include about-title-reduced;
  }
  .press-lounge-home-item,
  .press-lounge-activity-item,
  .press-lounge-date-wrapper,
  .press-lounge-files-item {
    @include about-text-reduced;
  }
  #press-lounge-images-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 499px) {
  #press-lounge-container {
    padding: 30px;
  }
}

</style>