<template>

<div id="honcom-container">
  <LoaderComponent v-if="!loaded" />
  <div id="honcom-content" v-else>
    <!-- <div class="honcom-title">{{ honcom.pageTitle }}</div> -->
    <div class="honcom-text" v-html="honcom.pageContent"></div>
    <div id="honcom-committee-header" class="honcom-title">{{ honcom.committee_member }}</div>

    <div id="honcom-members-container">
      <div v-for="member in members" :key="member.name" class="honcom-member">
        <div class="honcom-member-image-container">
          <img class="honcom-member-image" :src="member.imgSmall" />
        </div>
        <div class="honcom-member-info">
          <div class="honcom-member-name">{{ member.Name }}</div>
          <div class="honcom-member-position" v-html="member.Position"></div>
          <div class="honcom-member-description">{{ member.Description }}</div>
          <div class="honcom-member-url" v-html="member.Url"></div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>


<script>

import LoaderComponent from "./LoaderComponent.vue";
import media from "../mixins/media.js";
export default { 
  name: "HonoraryCommittee",
  mixins: [media],
  components: {
    "LoaderComponent": LoaderComponent
  },
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      honcom: {},
      members: [],
      loaded: false
    }
  },
  methods: {
    getHonComInfo: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/atrium_committe_honour" }).then(result => {
        this.honcom = result.data.data;
        this.members = result.data.items;
        this.loaded = true;
      }, error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.getHonComInfo();
  }
}

</script>



<style scoped lang="scss">

#honcom-container {
  @include flex-center;
  width: 100%;
  color: $light-text;
  background-color: $about-color;
  padding: 50px;
}
.loader-container {
  height: 100%;
  background-color: $about-color;
}
#honcom-content {
  width: 50%;
}
.honcom-title {
  @include about-title;
}
.honcom-text {
  @include about-text;
  margin-bottom: 15px;
}

#honcom-committee-header {
  margin-top: 30px;
}

.honcom-member {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}
  .honcom-member-image-container {
    height: 180px;
    width: 30%;
  }
    .honcom-member-image img {
      @include image-scale;
      object-fit: contain;
    }
  .honcom-member-info {
    width: 70%;
  }
    .honcom-member-name {
      font-weight: bold;
    }
    .honcom-member-position {
      margin-bottom: 10px;
    }
    .honcom-member-url :deep(a) {
      color: $light-text;
    }

@media only screen and (max-width: 1199px) {
  #honcom-content {
    width: 60%;
  }
}

@media only screen and (max-width: 999px) {
  #honcom-content {
    width: 70%;
  }
}

@media only screen and (max-width: 849px) {
  #honcom-content {
    width: 80%;
  }
}

@media only screen and (max-width: 749px) {
  #honcom-content {
    width: 90%;
  }
  .honcom-member {
    @include flex-center;
    flex-direction: column;
    background-color: rgba($mwnf-color, 0.1);
    padding: 20px;
  }
    .honcom-member-image-container {
      width: auto;
      margin-bottom: 20px;
    }
    .honcom-member-info {
      align-self: flex-start;
      width: 100%;
    }
}

@media only screen and (max-width: 599px) {
  #honcom-content {
    width: 100%;
  }
}

@media only screen and (max-width: 499px) {
  #honcom-container {
    padding: 30px;
  }
}

</style>