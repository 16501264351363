<template>

<div id="new-container" v-if="announcements && announcements.length > 0">
  <LoaderComponent v-if="!loaded" />
  <div v-else id="new-wrapper">
    <div id="new-announcements-container">
      <div class="new-announcement" v-for="announcement in announcements" :key="announcement.imagePath">
        <div class="new-image-container">
          <img :src="announcement.imagePath" />
          <!-- Optional "NEW" indicator -->
          <div class="new-image-new-sticker">
            <div class="new-image-new-triangle"></div>
            <div class="new-image-new">NEW</div>
          </div>
        </div>
        <div class="new-panel-information">
          <a class="new-panel-link" :href="announcement.titleUrl" target="_blank">
            <div class="new-panel-category-container">
              <div class="new-panel-category">{{ announcement.category }}</div>
              <div v-html="announcement.title"></div>
              <div>{{ announcement.subTitle }}</div>
            </div>
            <font-awesome-icon class="new-panel-arrow" :icon="['fas', 'arrow-right']" />
          </a>
          <div class="new-panel-text-container">
            <div class="new-panel-text" v-html="announcement.description"></div>
            <a v-if="announcement.seeAllUrl"
              class="new-panel-text new-panel-see-all" :href="announcement.seeAllUrl">
              See all {{ announcement.category }} <font-awesome-icon class="new-panel-see-all-arrow" :icon="['fas', 'arrow-right']" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>



<script>

import LoaderComponent from "./LoaderComponent.vue";
import functions from "../mixins/functions.js";
import media from "../mixins/media.js";
export default {
  name: "NewPanel",
  mixins: [functions, media],
  components: {
    "LoaderComponent": LoaderComponent
  },
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      announcements: [],
      loaded: false,
      mwnf_website: process.env.VUE_APP_MWNF_WEBSITE,
      api_website: process.env.VUE_APP_API_ENDPOINT,
      gallery_partners_website: process.env.VUE_APP_GALLERYPARTNERS_WEBSITE,
      cookies_website: process.env.VUE_APP_MWNF_COOKIES,
    }
  },
  methods: {
    getAnnouncements: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/new-category" }).then(result => {
        this.announcements = result.data.data;
        this.loaded = true;
      }, error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.getAnnouncements();
  }
}

</script>



<style lang="scss">

#new-container {
  height: 100%;
  width: 100%;
  cursor: default;
  margin-bottom: 10px;
}

#new-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 100px;
}

// #new-announcements-container {
//   height: 525px;
// }

.new-announcement {
  display: flex;
  // height: calc(100% / 3 - 20px);
  height: 155px;
  margin-bottom: 20px;
}
  .new-image-container {
    position: relative;
    height: 100%;
    width: 50%;
    margin-right: 20px;
  }
    .new-image-container img {
      @include image-scale;
      object-fit: cover;
    }
    .new-image-new-sticker {
      height: 75px;
      width: 75px;
      position: absolute;
      top: 0;
      right: 0;
    }
      .new-image-new-triangle {
        position: absolute;
        top: 0;
        right: 0;
        height: 0;
        width: 0;
        border-top: 75px solid $new-light-color;
        border-left: 75px solid transparent;
      }
      .new-image-new {
        @include flex-center;
        height: 35px;
        width: 35px;
        position: absolute;
        top: 0;
        right: -15%;
        transform: rotate(45deg);
        transform-origin: top left;
        color: $light-text;
        font-size: 100%;
        font-weight: bold;
      }
  .new-panel-information {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
    background-color: $new-light-color;
  }
    .new-panel-link {
      @include link-styles;
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: white;
      background-color: $new-dark-color;
      padding: 10px;
    }
      .new-panel-arrow {
        @include panel-link;
        align-self: center;
      }
    .new-panel-category {
      @include roboto;
      font-weight: bold;
      color: $new-light-color;
      text-shadow: black 0 0 1px;
      padding-bottom: 5px;
    }
    .new-panel-text-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      padding: 10px;
    }
      .new-panel-text {
        @include panel-details;
      }
      .new-panel-see-all {
        @include link-styles;
        display: flex;
        justify-content: flex-end;
        color: black;
      }
        .new-panel-see-all:hover {
          text-decoration: underline;
        }
        .new-panel-see-all .fa-arrow-right {
          margin-left: 12px;
        }


@media only screen and (max-width: 1349px) {
  #new-wrapper {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 974px) {
  #new-wrapper {
    padding: 0 25px;
  }
  .new-panel-text {
    @include panel-details-reduced;
  }
}

@media only screen and (max-width: 874px) {
  #new-container {
    margin-bottom: 10px;
  }
  #new-announcements-container {
    flex-direction: column;
    height: auto;
  }
  .new-announcement {
    flex-direction: column;
    height: 250px;
    width: 100%;
  }
  .new-image-container {
    height: 40%;
    width: 100%;
  }
  .new-panel-information {
    height: 60%;
    width: 100%;
  }
  .new-panel-link {
    height: auto;
  }
}

@media only screen and (max-width: 724px) {
  .new-image-container {
    height: 35%;
  }
  .new-panel-information {
    height: 65%;
  }
}

@media only screen and (max-width: 599px) {
  .new-panel-link {
    @include panel-details;
  }
}

@media only screen and (max-width: 424px) {
  .new-panel-text {
    @include panel-details-reduced;
  }
}

</style>
