import { createApp } from "vue"
import { createRouter, createWebHistory } from 'vue-router'

import HomepagePage from "./pages/HomepagePage.vue";
import ResultsPage from "./pages/ResultsPage.vue";
import ImportantInformation from "./pages/ImportantInformation.vue";
import ExhibitionsPage from "./pages/ExhibitionsPage.vue";
import CyclesPage from "./pages/CyclesPage.vue";
import ResourcesPage from "./pages/ResourcesPage.vue";
import SignUp from "./pages/SignUp.vue";

import AboutSection from "./pages/AboutSection.vue";
import ProfileComponent from "./components/ProfileComponent.vue";
import ChronologyPage from "./components/ChronologyPage.vue";
import BoardPage from "./components/BoardPage.vue";
// import ChronologyInner from "./components/ChronologyInner.vue";
import HonoraryCommittee from "./components/HonoraryCommittee.vue";
import SupportPage from "./components/SupportPage.vue";
import VolunteersPage from "./components/VolunteersPage.vue";
import ContactPage from "./components/ContactPage.vue";
import NewslettersPage from "./components/NewslettersPage.vue";
import CommemorationPage from "./components/CommemorationPage.vue";
import LanguagePolicy from "./components/LanguagePolicy.vue";
import LegalPage from "./components/LegalPage.vue";
import CreditsPage from "./components/CreditsPage.vue";
import PressLounge from "./components/PressLounge.vue";
import CookiesPage from "./components/CookiesPage.vue";


const routes = [
  { 
    path: "/",
    name: "home",
    components: {
      default: HomepagePage
    },
    props: true
  },
  {
    path: "/results",
    name: "results",
    components: {
      default: ResultsPage
    }
  },
  {
    path: "/important-information",
    name: "importantinformation",
    components: {
      default: ImportantInformation
    }
  },
  {
    path: "/exhibitions/:page",
    name: "exhibitions",
    components: {
      default: ExhibitionsPage
    }
  },
  {
    path: "/cycles/:page",
    name: "cycles",
    components: {
      default: CyclesPage
    }
  },
  {
    path: "/resources/:page/:language",
    name: "resources",
    components: {
      default: ResourcesPage
    }
  },
  {
    path: "/sign-up",
    name: "signup",
    components: {
      default: SignUp
    },
    props: true
  },
  {
    path: "/about",
    // name: "about",
    components: {
      default: AboutSection
    },
    children: [
      {
        path: "",
        component: ProfileComponent
      },
      {
        path: "chronology/:type?/:code?",
        name: "chronology",
        component: ChronologyPage
      },
      {
        path: "board/:profile?",
        name: "board",
        component: BoardPage
      },
      {
        path: "honorary-committee",
        name: "honcom",
        component: HonoraryCommittee
      },
      {
        path: "support/:subpage?",
        name: "support",
        component: SupportPage
      },
      {
        // path: "volunteers/:list?",
        path: "volunteers",
        name: "volunteers",
        component: VolunteersPage
      },
      {
        path: "contact",
        name: "contact",
        component: ContactPage
      },
      {
        path: "newsletter/:page([0-9]+)?/:newsletter?",
        name: "newsletters",
        component: NewslettersPage
      },
      {
        path: "newsletter-archive/:page?/:newsletter?",
        name: "archive",
        component: NewslettersPage
      },
      {
        path: "commemoration",
        name: "commemoration",
        component: CommemorationPage
      },
      {
        path: "language-policy",
        name: "language",
        component: LanguagePolicy
      },
      {
        path: "legal-notice",
        name: "legal",
        component: LegalPage
      },
      {
        path: "credits",
        name: "credits",
        component: CreditsPage
      },
      {
        path: "press-lounge/:activity?/:files?",
        name: "press",
        component: PressLounge
      },
      {
        path: "cookies",
        name: "cookies",
        component: CookiesPage
      },
    ],
    props: true
  },
  {
    path: "/error",
    name: "error",
    components: {
      default: Error
    }
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/error"
  }
];

// export default new VueRouter({
//   base: publicPath,
//   mode: "history",
//   routes,
//   scrollBehavior(to, from, savedPosition) {
//     console.log(to);
//     if (to.hash) {
//       setTimeout(() => {
//         const element = document.getElementById(to.hash.replace(/#/, ""))
//         if (element && element.scrollIntoView) {
//           element.scrollIntoView({ block: 'start', behavior: 'smooth' });
//         }
//       }, 1000);
//       return { el: to.hash, behavior: 'smooth', };
//     } else if (!to.path.includes("chronology") && savedPosition) {
//       return savedPosition;
//     } else {
//       return window.scrollTo({ top: 0, behavior: "smooth" });
//     }
//   }
// });

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_ROUTE),
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log(to);
    if (to.hash) {
      setTimeout(() => {
        const element = document.getElementById(to.hash.replace(/#/, ""))
        if (element && element.scrollIntoView) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }, 1000);
      return { el: to.hash, behavior: 'smooth', };
    } else if (!to.path.includes("chronology") && savedPosition) {
      return savedPosition;
    } else {
      return window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
});

export default router;