<template>

<div id="explore-container">
  <LoaderComponent v-if="!loaded" />
  <div v-else id="explore-wrapper">
    <div id="explore-main-panel">
      <div class="explore-image"
        v-if="explore && explore.image"
        @mouseover="showCaption = true"
        @mouseleave="showCaption = false">
        <img :src="explore.image.image" />
        <div class="explore-image-caption" v-if="showCaption">
          <span class="explore-image-caption-name"
            v-html="explore.image.item.exItemName">
          </span>
          <span>, </span>
          <span class="explore-image-caption-location">{{ explore.image.item.exLocation }}, </span>
          <span class="explore-image-caption-country">{{ explore.image.item.exCountry }}</span>
        </div>
      </div>
      <div class="explore-info">
        <a id="explore" class="explore-info-header" :href="explore.url" name="explore">
          <div>EXPLORE WITH MWNF</div>
          <font-awesome-icon :icon="['fas', 'arrow-right']" />
        </a>
        <div class="explore-description">{{ explore.description }}</div>
        <div class="explore-by-container">
          <div class="explore-by">EXPLORE BY</div>
          <div class="explore-by-links">
            <a class="explore-by-link" :href="explore.theme">
              <div class="explore-by-text">THEME</div>
              <font-awesome-icon :icon="['fas', 'arrow-right']" />
            </a>
            <a class="explore-by-link" :href="explore.country">
              <div class="explore-by-text">COUNTRY</div>
              <font-awesome-icon :icon="['fas', 'arrow-right']" />
            </a>
            <a class="explore-by-link" :href="explore.itinerary">
              <div class="explore-by-text">ITINERARY</div>
              <font-awesome-icon :icon="['fas', 'arrow-right']" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div id="see-also-wrapper">
      <a id="see-also" name="see-also">
        <div>SEE ALSO</div>
      </a>
      <div id="explore-small-panels">
        <div class="explore-small-panel"
          v-for="(panel, key, index) in panels" :key="panel.url">
          <a class="small-panel-link" :href="panel.url">
            <img :src="`${panel.image}`" />
            <div class="small-panel-title"
              :class="`panel-${index}`"
              v-html="panel.title.toUpperCase()">
            </div>
            <div class="small-panel-text">{{ panel.description }}</div>
            <div class="small-panel-arrow">
              <font-awesome-icon :icon="['fas', 'arrow-right']" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

</template>



<script>

import LoaderComponent from "./LoaderComponent.vue";
import functions from "../mixins/functions.js";
import media from "../mixins/media.js";
export default {
  name: "ExploreComponent",
  mixins: [functions, media],
  components: {
    "LoaderComponent": LoaderComponent
  },
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      explore: {},
      panels: {},
      showCaption: false,
      loaded: false,
    }
  },
  methods: {
    getExplore: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/explore" }).then(result => {
        let data = result.data.data;
        this.explore = data.explore;
        this.audit(this.explore.image.item);
        this.panels = data.exploreBottom;
        this.loaded = true;
      }, error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.getExplore();
  }
}

</script>



<style lang="scss">

#explore-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  // margin-top: 30px;
  margin-bottom: 50px;
  cursor: default;
}

.loader-container {
  height: 100%;
}

.explore-info-header div,
.explore-by-link div,
.small-panel-text {
  @include roboto;
}

#explore-main-panel {
  display: flex;
  height: 500px;
  width: 100%;
  margin-bottom: 30px;
}

.explore-image {
  position: relative;
  height: 100%;
  width: 60%;
  background-color: $image-background;
}
  .explore-image img {
    @include image-scale;
    object-fit: cover;
  }
  .explore-image-caption {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 50%;
    font-size: 12px;
    color: $light-text;
    background-color: rgba(black, 0.5);
    padding: 10px;
  }
.explore-info {
  height: 100%;
  width: 40%;
  font-weight: bold;
  color: $light-text;
  background-color: $explore-color;
  padding: 50px 60px;
}

.explore-info-header {
  @include panel-header;
  @include link-styles;
  @include scroll-top;
  display: flex;
  justify-content: space-between;
  line-height: 100%;
  color: $light-text;
  margin-bottom: 30px;
}
.explore-description {
  @include panel-details;
  font-weight: normal;
  margin-bottom: 30px;
}
.explore-by {
  @include panel-details;
  font-weight: normal;
  margin-bottom: 10px;
}
.explore-by-links {
  @include panel-link;
  display: flex;
  flex-direction: column;
}
  .explore-by-link {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px 0;
  }
  .explore-by-links a {
    @include link-styles;
    color: $light-text;
  }

#see-also-wrapper {
  padding: 20px 100px 0 100px;
}
  #see-also {
    @include panel-header;
    @include scroll-top;
    display: flex;
    font-weight: bold;
    margin-bottom: 30px;
  }

#explore-small-panels {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 100px;
  height: 150px;
  width: 100%;
}

.explore-small-panel {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 0;
}
.small-panel-link {
  display: block;
  @include link-styles;
  height: 100%;
  width: 100%;
  color: $light-text;
  padding: 20px;
  background-color: rgba(black, 0.3);
}
.explore-small-panel img {
  @include image-scale;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -1;
}
.small-panel-title {
  @include panel-details;
  margin-bottom: 20px;
}
  .panel-0 {
    display: flex;
    flex-direction: column;
  }
    .panel-0 span {
      font-weight: bold;
      color: $dark-text;
      background-color: $dia-color;
    }
.small-panel-text {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}
.small-panel-arrow {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

@media only screen and (max-width: 1349px) {
  .explore-image {
    width: 50%;
  }
  .explore-info {
    width: 50%;
  }
}

@media only screen and (max-width: 1274px) {
  #see-also-wrapper {
    padding: 20px 60px 0 60px;
  }
  #explore-small-panels {
    grid-gap: 75px;
  }
}

@media only screen and (max-width: 1199px) {
  .explore-info-header {
    @include scroll-top-increased;
  }
  .explore-info {
    padding: 40px 50px;
  }
  .explore-image-caption {
    max-width: 75%;
  }
  #see-also {
    @include scroll-top-increased;
  }
  #explore-small-panels {
    grid-gap: 60px;
  }
}

@media only screen and (max-width: 1099px) {
  #explore-small-panels {
    grid-gap: 50px;
  }
}

@media only screen and (max-width: 974px) {
  #explore-main-panel {
    height: 475px;
  }
  .explore-info-header,
  #see-also {
    @include panel-header-reduced;
  }
  .explore-description,
  .explore-by,
  .small-panel-title {
    @include panel-details-reduced;
  }
  .explore-by-links {
    @include panel-link-reduced;
  }
  #explore-small-panels {
    grid-gap: 30px;
  }
}

@media only screen and (max-width: 874px) {
  #explore-main-panel {
    flex-direction: column;
    height: auto;
  }
    .explore-image {
      height: 450px;
      width: 100%;
    }
    .explore-info {
      width: 100%;
      padding: 30px 100px;
    }
  #see-also-wrapper {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 749px) {
  #explore-container {
    margin-bottom: 0;
  }
  #explore-small-panels {
    display: flex;
    flex-direction: column;
    height: auto;
  }
    .explore-small-panel {
      height: 150px;
    }
    .small-panel-link {
      padding-left: 100px;
      padding-right: 100px;
    }
}

@media only screen and (max-width: 599px) {
  .explore-image {
    height: 400px;
  }
  .explore-info-header,
  #see-also {
    @include scroll-top-decreased;
  }
  .explore-info {
    padding: 30px 60px;
  }
  .small-panel-link {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media only screen and (max-width: 599px) {
  .explore-image {
    height: 350px;
  }
  .explore-info {
    padding: 30px;
  }
  .small-panel-link {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 424px) {
  #explore-main-panel {
    margin-bottom: 20px;
  }
  .explore-image {
    height: 300px;
  }
  .explore-info-header,
  #see-also {
    @include panel-header-smallest;
  }
  .explore-by-links {
    @include panel-link-smallest;
  }
  #see-also-wrapper {
    padding: 0 20px;
    margin-bottom: 20px;
  }
  .small-panel-link {
    padding-left: 15px;
    padding-right: 15px;
  }
    .small-panel-title {
      margin-bottom: 10px;
    }
    .small-panel-text {
      margin-bottom: 0;
    }
}

</style>
