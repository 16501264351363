<template>

<div id="virtual-museums-container">
  <LoaderComponent v-if="!loaded" />
  <div v-else id="virtual-museums-wrapper">
    <div class="vm-image" 
      v-if="currentVM && currentVM.image && currentVM.image.image"
      @mouseover="showCaption = true"
      @mouseleave="showCaption = false">
      <transition name="fade" mode="out-in">
        <img :src="currentVM.image.image" :key="currentVM.image.image" />
      </transition>
      <div class="vm-image-caption" v-if="showCaption">
        <span class="vm-image-caption-name"
          v-html="currentVM.image.item.itemName">
        </span>
        <span>, </span>
        <span v-if="currentVM.image.item.caption && 
          currentVM.image.item.caption.length > 0">{{ currentVM.image.item.caption }}, </span>
        <span v-if="currentVM.image.item.dynasty && currentVM.image.item.dynasty.length > 0"
          class="vm-image-caption-dynasty"
          v-html="currentVM.image.item.dynasty">
        </span>
        <span v-if="currentVM.image.item.dynasty && currentVM.image.item.dynasty.length > 0">, </span>
        <span v-if="currentVM.image.item.holdingMuseum && currentVM.image.item.holdingMuseum.length > 0"
          class="vm-image-caption-holding">{{ currentVM.image.item.holdingMuseum }}</span>
        <span v-if="currentVM.image.item.holdingMuseum && currentVM.image.item.holdingMuseum.length > 0">, </span>
        <span class="vm-image-caption-location">{{ currentVM.image.item.location }}, </span>
        <span class="vm-image-caption-country">{{ currentVM.image.item.country }}</span>
      </div>
      <div class="vm-image-label" :class="setVMColor">
        {{ currentVM.name.toUpperCase() }}
      </div>
    </div>
    <div ref="vmInfo" class="vm-info" :class="setVMColor">
      <a id="virtual-museums" class="vm-info-header" name="virtual-museums">VIRTUAL MUSEUMS</a>
      <div class="vm-info-vms"
        :class="[{ 'selected': currentVM.name === vm.name }, setSelectedColor(vm)]"
        v-for="vm in vms" :key="vm.url">
        <div class="vm-name-container">
          <a :href="vm.url">
            <div class="vm-info-vm">{{ vm.name.toUpperCase() }}</div>
            <font-awesome-icon :icon="['fas', 'arrow-right']" />
          </a>
          <div class="show-vm-details"
            v-if="currentVM.name !== vm.name"
            @click="changeCurrentVM(vm)">Show Description</div>
        </div>
        <transition name="change" mode="out-in">
          <div class="vm-info-container" v-if="currentVM.name === vm.name">
            <div class="vm-numbers-container">
              <a class="vm-number" :href="vm.artefacts.url">
                <div class="number">{{ vm.artefacts.number }}</div>
                <div class="label">Artefacts</div>
              </a>
              <a class="vm-number" :href="vm.countries.url">
                <div class="number">{{ vm.countries.number }}</div>
                <div class="label">Countries</div>
              </a>
              <a class="vm-number" :href="vm.exhibitions.url">
                <div class="number">{{ vm.exhibitions.number }}</div>
                <div class="label">Exhibitions</div>
              </a>
            </div>
            <div class="vm-description">{{ vm.description }}</div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</div>

</template>



<script>

import LoaderComponent from "./LoaderComponent.vue";
import functions from "../mixins/functions.js";
import media from "../mixins/media.js";
export default {
  name: "VirtualMuseums",
  mixins: [functions, media],
  components: {
    "LoaderComponent": LoaderComponent
  },
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      vms: {},
      currentVM: {},
      showCaption: false,
      loaded: false,
      refresh: null
    }
  },
  computed: {
    setVMColor: function() {
      return {
        "dia": this.currentVM.name === "Discover Islamic Art",
        "dba": this.currentVM.name === "Discover Baroque Art",
        "sh": this.currentVM.name === "Sharing History"
      }
    }
  },
  methods: {
    setSelectedColor: function(vm) {
      return {
        "dia": vm.name === "Discover Islamic Art",
        "dba": vm.name === "Discover Baroque Art",
        "sh": vm.name === "Sharing History",
        "unselected-dia": this.currentVM.name === "Discover Islamic Art" && this.currentVM.name !== vm.name,
        "unselected-dba": this.currentVM.name === "Discover Baroque Art" && this.currentVM.name !== vm.name,
        "unselected-sh": this.currentVM.name === "Sharing History" && this.currentVM.name !== vm.name
      }
    },
    changeCurrentVM: function(vm) {
      this.currentVM = vm;
    },
    // Get VM-specific information
    getVMs: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/virtual-museums" }).then(result => {
        console.log(result.data.data);
        this.vms = result.data.data;
        // // Image object for preloading
        for (let vm in this.vms) {
          // Preload museum images for smooth transitions
          let image = new Image();
          image.src = this.vms[vm].image.image;
          // Add name of museum
          if (vm === "dia") {
            this.vms[vm].name = "Discover Islamic Art";
          } else if (vm === "dba") {
            this.vms[vm].name = "Discover Baroque Art";
          } else if (vm === "sh") {
            this.vms[vm].name = "Sharing History";
          }
          this.audit(this.vms[vm].image.item);
        }
        // Assign random object as current "selection"
        let keys = Object.keys(this.vms);
        let random = keys[Math.floor(Math.random() * keys.length)];
        this.currentVM = this.vms[random];
        this.loaded = true;
      }, error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.getVMs();
  }
}

</script>



<style lang="scss">

#virtual-museums-container {
  height: 525px;
  width: 100%;
  margin-bottom: 30px;
  cursor: default;
}

.loader-container {
  height: 100%;
}

#virtual-museums-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.vm-info-header,
.vm-info-vm {
  @include roboto;
}

.vm-image {
  position: relative;
  height: 100%;
  width: 60%;
  background-color: $image-background;
}
  .vm-image img {
    @include image-scale;
    object-fit: cover;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
    opacity: 1;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0.5;
  }
  .vm-image-caption {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 50%;
    font-size: 12px;
    color: $light-text;
    background-color: rgba(black, 0.5);
    padding: 10px;
  }
  .vm-image-label {
    @include flex-center;
    position: absolute;
    bottom: 0;
    height: 30px;
    width: 100%;
    color: $light-text;
    padding: 5px;
  }
    .vm-image-label.dia {
      background-color: rgba($dia-color, 0.5);
    }
    .vm-image-label.dba {
      background-color: rgba($dba-color, 0.5);
    }
    .vm-image-label.sh {
      background-color: rgba($sh-color, 0.5);
    }

.vm-info {
  height: 100%;
  width: 40%;
  font-weight: bold;
  color: $light-text;
  padding: 50px 60px;
}
  .vm-info.dia {
    color: $dia-text;
    background-color: $dia-color;
  }
  .vm-info.dba {
    background-color: $dba-color;
  }
  .vm-info.sh {
    background-color: $sh-color;
  }

.vm-info-header {
  @include panel-header;
  @include scroll-top;
  display: block;
}
.vm-info-vms {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin: 0 0 20px 0;
}
  .vm-info-vms.unselected-dia * {
    color: rgb(146, 126, 114);
  }
  .vm-info-vms.unselected-dba * {
    color: rgb(97, 121, 148);
  }
  .vm-info-vms.unselected-sh * {
    color: rgb(196, 133, 144);
  }

.vm-info-vms {
  display: flex;
  flex-direction: column;
}
  .vm-name-container {
    @include panel-link;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
    .vm-name-container a {
      @include link-styles;
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: $light-text;
      margin-bottom: 5px;
    }
      .vm-info.dia .vm-name-container a {
        color: $dia-text;
      }
  .show-vm-details {
    font-size: 15px;
    text-decoration: underline;
    cursor: pointer;
  }

.vm-info-container {
  @include panel-details;
  display: flex;
  flex-direction: column;
  height: 120px;
  cursor: default;
}
  .change-enter-active, .change-leave-active {
    transition: height .5s;
    opacity: 0;
  }
  .change-enter, .change-leave-to {
    height: 0;
  }
  .vm-name-container {
    margin-bottom: 10px;
  }
  .vm-numbers-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
    .vm-number {
      display: flex;
      flex-direction: column;
      color: $light-text;
      text-decoration: none;
    }
      .vm-number:hover {
        @include link-hover-styles;
      }
      .vm-info.dia a {
        color: $dia-text;
      }
    .label {
      font-weight: normal;
    }
  .vm-description {
    font-weight: normal;
  }

@media only screen and (max-width: 1349px) {
  .vm-image {
    width: 50%;
  }
  .vm-info {
    width: 50%;
  }
}

@media only screen and (max-width: 1199px) {
  .vm-info {
    padding: 40px 50px;
  }
  .vm-info-header {
    @include scroll-top-increased;
  }
  .vm-image-caption {
    max-width: 75%;
  }
}

@media only screen and (max-width: 974px) {
  #virtual-museums-container {
    height: 500px;
  }
  .vm-info-header {
    @include panel-header-reduced;
  }
  .vm-name-container {
    @include panel-link-reduced;
  }
  .vm-info-container {
    @include panel-details-reduced;
  }
}

@media only screen and (max-width: 874px) {
  #virtual-museums-container {
    height: auto;
  }
  #virtual-museums-wrapper {
    flex-direction: column;
  }
    .vm-image {
      height: 450px;
      width: 100%;
    }
    .vm-info {
      width: 100%;
      padding: 30px 100px;
    }
  .vm-numbers-container {
    width: 275px;
  }
  .vm-info-container {
    height: 100px;
  }
    // Repeat needed for transition animation apparently
    .change-enter-active, .change-leave-active {
      transition: height .5s;
      opacity: 0;
    }
    .change-enter, .change-leave-to {
      height: 0;
    }
}

@media only screen and (max-width: 599px) {
  .vm-image {
    height: 400px;
  }
  .vm-info-header {
    @include scroll-top-decreased;
  }
  .vm-info {
    padding: 30px 60px;
  }
}

@media only screen and (max-width: 499px) {
  .vm-image {
    height: 350px;
  }
  .vm-info {
    padding: 30px;
  }
}

@media only screen and (max-width: 424px) {
  .vm-image {
    height: 300px;
  }
  .vm-info-header {
    @include panel-header-smallest;
  }
  .vm-name-container {
    @include panel-link-smallest;
  }
  .vm-numbers-container {
    width: 100%;
  }
  .vm-info-container {
    height: 115px;
  }
    // Repeat needed for transition animation apparently
    .change-enter-active, .change-leave-active {
      transition: height .5s;
      opacity: 0;
    }
    .change-enter, .change-leave-to {
      height: 0;
    }
}

</style>
