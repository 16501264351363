<template>

<div id="exhibitions-container">
  <div id="exhibitions-panel-wrapper" v-if="exhibitions && exhibitions.length > 0">
    <a class="exhibitions" name="exhibitions">EXHIBITIONS</a>
    <div id="exhibition-panels">
      <div class="exhibition-panel" v-for="exhibition in exhibitions" :key="exhibition.exhibitionId">
        <div class="exhibition-panel-image">
          <img :src="exhibition.image" />
        </div>
        <div class="exhibition-panel-information">
          <div class="exhibition-panel-information-titles">
            <a class="exhibition-panel-information-titles-link" :href="exhibition.url"
              :target="exhibition.videoExhibition ? '_blank' : '_self'">
              <div class="exhibition-panel-information-titles-wrapper">
                <div class="exhibition-panel-information-title">{{ exhibition.title }}</div>
                <div class="exhibition-panel-information-subtitle">{{ exhibition.subtitle }}</div>
              </div>
              <font-awesome-icon class="exhibition-panel-information-arrow" :icon="['fas', 'arrow-right']" />
            </a>
          </div>
          <div class="exhibition-panel-information-text">
            <div v-if="exhibition.description" v-html="exhibition.description"></div>
            <div v-else v-html="truncate(250, exhibition.about)"></div>
          </div>
        </div>
      </div>
      <div class="all-projects">
        <router-link :to="{ name: 'exhibitions', params: { page: '1'} }">
          <div>See All Exhibitions</div>
          <font-awesome-icon :icon="['fas', 'arrow-right']" />
        </router-link>
      </div>
    </div>
  </div>

  <a class="exhibitions" name="exhibitions">EXHIBITION CYCLES</a>
  <div id="exhibition-cycles-panel">
    <div id="exhibition-cycles-image"
      :class="setBackgroundColor">
      <img :src="exhibition.portalImage" />
      <!-- Optional "NEW" indicator -->
      <div class="exhibition-cycles-new-sticker" v-if="exhibition.newSticker">
        <div class="exhibition-cycles-new-triangle"></div>
        <div class="exhibition-cycles-new">NEW</div>
      </div>
      <div class="exhibition-cycles-image-label" :class="setBackgroundColor" v-if="exhibition.projectName">
        {{ exhibition.projectName.toUpperCase() }}
      </div>
    </div>
    <div id="exhibition-cycles-information">
      <div id="exhibition-cycles-title-wrapper" :class="setBackgroundColor">
        <div id="exhibition-cycles-featured" v-if="exhibition.projectName">FEATURED EXHIBITION</div>
        <a id="exhibition-cycles-title" v-if="exhibition.exhibitionName"
          :href="`${exhibition.link}en`">
          <div>
            {{ exhibition.exhibitionName }}
          </div>
          <font-awesome-icon :icon="['fas', 'arrow-right']" />
        </a>
      </div>
      <div id="exhibition-cycles-info-wrapper" :class="setBackgroundColor">
        <div id="exhibition-cycles-languages-container">
          <div id="exhibition-cycles-languages-header">Available Languages</div>
          <div id="exhibition-cycles-languages">
            <span class="exhibition-cycles-language"
              v-for="(language, key, index) in exhibition.languages" 
              :key="key">
              <a :href="`${exhibition.link}${key}`">{{ language }}</a>
              <span class="exhibition-cycles-comma"
                v-if="Object.keys(exhibition.languages).length > 1 &&
                index !== Object.keys(exhibition.languages).length - 1">
                ,&nbsp;
              </span>
            </span>
          </div>
        </div>
        <div id="exhibition-cycles-description" v-html="truncate(200, exhibition.description)"></div>
        <div id="exhibition-cycles-full-description"
          v-if="exhibition.description && exhibition.description.length > 200"
          @click="showDescription = true">
          Read More.
        </div>
      </div>
      <div id="exhibition-cycles-description-popup" v-if="showDescription"
        :class="setBackgroundColor">
        <div id="exhibition-cycles-popup-close"
          :class="setBackgroundColor"
          @click="showDescription = false">
          <font-awesome-icon :icon="['fas', 'times']"/>
        </div>
        <div id="exhibition-cycles-popup-text" v-html="exhibition.description"></div>
      </div>
      <div id="all-exhibitions-container">
        <a v-for="(project, key) in projects" :key="project.name" 
          :href="project.url"
          :class="key"
          class="all-exhibition">
          <div class="all-exhibition-cycles-number-container">
            <div class="all-exhibition-cycles-number">{{ project.exhibitions }}</div>
            <div class="all-exhibition-cycles-number-label">Exhibitions</div>
          </div>
          <div class="all-exhibition-cycles-museum-container">
            <div class="all-exhibition-cycles-museum">{{ project.name.toUpperCase() }}</div>
            <div class="pointer" v-if="project.projectNewStatus">
              <div class="pointer-new">NEW</div>
            </div>
            <font-awesome-icon v-else :icon="['fas', 'arrow-right']" />
          </div>
        </a>
      </div>
      <div class="all-projects cycles" v-if="showProjectsLink">
        <router-link :to="{ name: 'cycles', params: { page: '1'} }">
          <div>See All Exhibition Cycles</div>
          <font-awesome-icon :icon="['fas', 'arrow-right']" />
        </router-link>
      </div>
    </div>
  </div>
</div>

</template>



<script>

import functions from "../mixins/functions.js";
import media from "../mixins/media.js";
export default {
  name: "ExhibitionsComponent",
  mixins: [functions, media],
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      projects: {},
      exhibition: {},
      showDescription: false,
      showProjectsLink: false,
      exhibitions: [],
    }
  },
  computed: {
    // New Projects must be added here (applies classes with Project colors)
    setBackgroundColor: function() {
      return {
        "isl": this.exhibition.projectId === "ISL",
        "bar": this.exhibition.projectId === "BAR",
        "awe": this.exhibition.projectId === "AWE",
        "usa": this.exhibition.projectId === "USA",
        "rus": this.exhibition.projectId === "RUS"
      }
    }
  },
  methods: {
    getExhibitionCycles: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/exhibitions" }).then(result => {
        this.projects = result.data.data.projects;
        this.exhibition = result.data.data.exhibition;
        if (this.exhibition.projectId === "ISL" ||
          this.exhibition.projectId === "BAR") {
          this.exhibition.link += "?lng=";
        }
        if (result.data.data.projectsCount > 3) {
          this.showProjectsLink = true;
        }
      }, error => {
        console.error(error);
      });
    },
    getExhibitions: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/new-exhibitions" }).then(result => {
        this.exhibitions = result.data.data.exhibitions;
      }, error => {
        console.error(error);
      });
    }
  },
  mounted() {
    this.getExhibitions();
    this.getExhibitionCycles();
  }
}

</script>



<style lang="scss">

.italic {
  font-style: italic;
}

.fa-arrow-right {
  margin-left: 4px;
}

#exhibitions-container {
  min-height: 450px;
  width: 100%;
  padding: 50px 100px;
}

.exhibition-panel-information-title,
#exhibition-cycles-title div,
.all-exhibition-cycles-museum {
  @include roboto;
}

#exhibitions-panel-wrapper {
  width: 100%;
}

.exhibitions {
  @include panel-header;
  @include scroll-top;
  display: block;
  width: 100%;
  font-weight: bold;
}

#exhibition-panels {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.exhibition-panel {
  display: flex;
  position: relative;
  min-height: 200px;
  height: 100%;
  width: 100%;
  margin-bottom: 30px;
}
  .exhibition-panel-image {
    @include flex-center;
    flex-direction: column;
    position: relative;
    width: calc(50% + 32px);
    img {
      @include image-scale;
      position: absolute;
      object-fit: cover;
    }
  }
  .exhibition-panel-information {
    display: flex;
    flex-direction: column;
    // flex-grow: 1;
    width: 50%;
    margin-left: 30px;
  }
    .exhibition-panel-information-titles {
      background-color: $exh-color;
      padding: 10px;
    }
    .exhibition-panel-information-titles-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      color: black;
    }
      .exhibition-panel-information-title {
        @include panel-link;
        font-weight: bold;
      }
      .exhibition-panel-information-arrow {
        @include panel-link;
      }
    .exhibition-panel-information-text {
      @include panel-details;
      height: 100%;
      background-color: $exh-light-color;
      padding: 10px;
    }

#exhibition-cycles-panel {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

// $projects-color is fallback
// For every new Project, add to _variables.scss
// Look for .isl/.bar/.awe classes to find all the places to add new colors

#exhibition-cycles-image {
  position: relative;
  height: 450px;
  width: 50%;
  background-color: $projects-color;
  border-right: 20px solid $projects-color;
  border-left: 20px solid $projects-color;
}
  #exhibition-cycles-image.isl {
    background-color: $dia-color;
    border-right: 20px solid $dia-color;
    border-left: 20px solid $dia-color;
  }
  #exhibition-cycles-image.bar {
    background-color: $dba-color;
    border-right: 20px solid $dba-color;
    border-left: 20px solid $dba-color;
  }
  #exhibition-cycles-image.awe {
    background-color: $sh-color;
    border-right: 20px solid $sh-color;
    border-left: 20px solid $sh-color;
  }
  #exhibition-cycles-image.usa {
    background-color: $usa-color;
    border-right: 20px solid $usa-color;
    border-left: 20px solid $usa-color;
  }
  #exhibition-cycles-image.rus {
    background-color: $rus-color;
    border-right: 20px solid $rus-color;
    border-left: 20px solid $rus-color;
  }
  #exhibition-cycles-image img {
    @include image-scale;
    object-fit: contain;
  }

.exhibition-cycles-new-sticker {
  height: 150px;
  width: 150px;
  position: absolute;
  top: 0;
  right: -20px;
}
  .exhibition-cycles-new-triangle {
    position: absolute;
    top: 0;
    right: 0;
    height: 0;
    width: 0;
    border-top: 150px solid red;
    border-left: 150px solid transparent;
  }
  .exhibition-cycles-new {
    @include flex-center;
    height: 75px;
    width: 75px;
    position: absolute;
    top: 0;
    right: -15%;
    transform: rotate(45deg);
    transform-origin: top left;
    color: $light-text;
    font-size: 200%;
    font-weight: bold;
  }

.exhibition-cycles-image-label {
  @include flex-center;
  position: absolute;
  bottom: 0;
  height: 30px;
  width: 100%;
  color: $dark-text;
  background-color: rgba($projects-color, 0.5);
  padding: 5px;
}
  .exhibition-cycles-image-label.isl {
    background-color: rgba($dia-color, 0.5);
  }
 .exhibition-cycles-image-label.bar {
    color: $light-text;
    background-color: rgba($dba-color, 0.5);
  }
  .exhibition-cycles-image-label.awe {
    color: $light-text;
    background-color: rgba($sh-color, 0.5);
  }
  .exhibition-cycles-image-label.usa {
    color: $dark-text;
    background-color: rgba($usa-color, 0.5);
  }
  .exhibition-cycles-image-label.rus {
    color: $dark-text;
    background-color: rgba($rus-color, 0.5);
  }

#exhibition-cycles-information {
  width: 50%;
  padding-left: 30px;
}

#exhibition-cycles-title-wrapper {
  padding: 8px;
}
// Text color matters - if darker color, $light-text (white)
// If lighter color, $dark-text (black)
#exhibition-cycles-title-wrapper,
#exhibition-cycles-title-wrapper > * {
  color: $dark-text;
  background-color: $projects-color;
}
#exhibition-cycles-title-wrapper.isl,
#exhibition-cycles-title-wrapper.isl > * {
  color: $dia-text;
  background-color: $dia-color;
}
#exhibition-cycles-title-wrapper.bar,
#exhibition-cycles-title-wrapper.bar > * {
  color: $light-text;
  background-color: $dba-color;
}
#exhibition-cycles-title-wrapper.awe,
#exhibition-cycles-title-wrapper.awe > * {
  color: $light-text;
  background-color: $sh-color;
}
#exhibition-cycles-title-wrapper.usa,
#exhibition-cycles-title-wrapper.usa > * {
  color: $dark-text;
  background-color: $usa-color;
}
#exhibition-cycles-title-wrapper.rus,
#exhibition-cycles-title-wrapper.rus > * {
  color: $dark-text;
  background-color: $rus-color;
}
  #exhibition-cycles-featured {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  #exhibition-cycles-title {
    @include link-styles;
    @include panel-link;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    font-weight: bold;
  }
    #exhibition-cycles-title span {
      font-size: 14px;
    }

#exhibition-cycles-info-wrapper {
  padding: 18px 8px 8px 8px;
}
  #exhibition-cycles-info-wrapper,
  #exhibition-cycles-info-wrapper > * {
    background-color: $projects-light-color;
  }
  #exhibition-cycles-info-wrapper.isl,
  #exhibition-cycles-info-wrapper.isl > * {
    background-color: $dia-light-color;
  }
  #exhibition-cycles-info-wrapper.bar,
  #exhibition-cycles-info-wrapper.bar > * {
    background-color: $dba-light-color;
  }
  #exhibition-cycles-info-wrapper.awe,
  #exhibition-cycles-info-wrapper.awe > * {
    background-color: $sh-light-color;
  }
  #exhibition-cycles-info-wrapper.usa,
  #exhibition-cycles-info-wrapper.usa > * {
    background-color: $usa-light-color;
  }
  #exhibition-cycles-info-wrapper.rus,
  #exhibition-cycles-info-wrapper.rus > * {
    background-color: $rus-light-color;
  }

#exhibition-cycles-languages-header {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}
#exhibition-cycles-languages {
  @include panel-details;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
  .exhibition-cycles-language {
    display: flex;
  }
  .exhibition-cycles-comma {
    margin-right: 3px;
  }

#exhibition-cycles-description {
  @include panel-details;
  margin-bottom: 10px;
}
  #exhibition-cycles-full-description {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }

#exhibition-cycles-description-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: $projects-color;
  border: 2px solid white;
  border-radius: 5px;
  padding: 10px;
  z-index: 50;
}
  #exhibition-cycles-description-popup.isl {
    background-color: $dia-color;
  }
  #exhibition-cycles-description-popup.bar {
    background-color: $dba-color;
  }
  #exhibition-cycles-description-popup.awe {
    background-color: $sh-color;
  }
  #exhibition-cycles-description-popup.usa {
    background-color: $usa-color;
  } 
  #exhibition-cycles-description-popup.rus {
    background-color: $rus-color;
  }
  #exhibition-cycles-popup-close {
    @include flex-center;
    position: absolute;
    top: -20px;
    right: -20px;
    height: 40px;
    width: 40px;
    background-color: $projects-color;
    border: 2px solid white;
    border-radius: 100%;
    cursor: pointer;
  }
    #exhibition-cycles-popup-close.isl {
      color: $dia-text;
      background-color: $dia-color;
    }
    #exhibition-cycles-popup-close.bar {
      color: $light-text;
      background-color: $dba-color;
    }
    #exhibition-cycles-popup-close.awe {
      color: $light-text;
      background-color: $sh-color;
    }
    #exhibition-cycles-popup-close.usa {
      color: $dark-text;
      background-color: $usa-color;
    }
    #exhibition-cycles-popup-close.rus {
      color: $dark-text;
      background-color: $rus-color;
    }
  #exhibition-cycles-popup-text {
    @include panel-details;
    background-color: $background-color;
    padding: 20px;
  }

#all-exhibitions-container {
  margin-top: 20px;
  margin-bottom: 30px;
}

.all-exhibition {
  @include link-styles;
  @include panel-link;
  display: flex;
  width: 100%;
  color: black;
  font-weight: bold;
  padding: 10px;
  border-bottom: 2px solid black;
}
  // Project ID is class, add class and colors here
  .all-exhibition:hover {
    color: $dark-text;
    background-color: $projects-color;
  }
  .all-exhibition.dia:hover {
    color: $dark-text;
    background-color: $dia-color;
  }
  .all-exhibition.dba:hover {
    color: $light-text;
    background-color: $dba-color;
  }
  .all-exhibition.sh:hover {
    color: $light-text;
    background-color: $sh-color;
  }
  .all-exhibition.usa:hover {
    color: $dark-text;
    background-color: $usa-color;
  }
  .all-exhibition.rus:hover {
    color: $dark-text;
    background-color: $rus-color;
  }
  .all-exhibition-cycles-number-container {
    padding-right: 20px;
  }
  .all-exhibition-cycles-number-label {
    @include panel-details;
    font-weight: normal;
  }
  .all-exhibition-cycles-museum-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .all-exhibition-cycles-museum {
    width: calc(100% - 75px);
  }
  .all-exhibition-cycles-museum,
  .all-exhibition .fa-arrow-right {
    align-self: center;
  }

.pointer {
  @include flex-center;
  align-self: center;
  position: relative;
  height: 40px;
  width: 75px;
  background: red;
}
  .pointer:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid white;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
  .pointer:before {
    content: "";
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid red;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
    // Add background color to pointer according to project (class name)
    .all-exhibition:hover .pointer:after {
      border-left: 20px solid $projects-color;
    }
    .all-exhibition.dia:hover .pointer:after {
      border-left: 20px solid $dia-color;
    }
    .all-exhibition.dba:hover .pointer:after{
      border-left: 20px solid $dba-color;
    }
    .all-exhibition.sh:hover .pointer:after {
      border-left: 20px solid $sh-color;
    }
    .all-exhibition.usa:hover .pointer:after {
      border-left: 20px solid $usa-color;
    }
    .all-exhibition.rus:hover .pointer:after {
      border-left: 20px solid $rus-color;
    }
  .pointer-new {
    position: absolute;
    left: 22px;
    color: $light-text;
  }

.all-projects {
  @include panel-link;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 30px;
}
  .all-projects a {
    @include link-styles;
    display: flex;
    color: black;
  }
.all-projects.cycles {
  @include panel-link;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 30px;
}
  .all-projects.cycles a {
    @include link-styles;
    display: flex;
    justify-content: space-between;
    color: black;
  }

@media only screen and (max-width: 1274px) {
  #exhibitions-container {
    padding: 50px 60px;
  }
}

@media only screen and (max-width: 1199px) {
  #exhibitions-container {
    padding: 50px 60px;
  }
  .exhibitions {
    @include scroll-top-increased;
  }
}

@media only screen and (max-width: 974px) {
  .exhibitions {
    @include panel-header-reduced;
  }
  #exhibition-cycles-title,
  .all-exhibition,
  #all-projects {
    @include panel-link-reduced;
  }
  #exhibition-cycles-languages,
  #exhibition-cycles-description,
  #exhibition-cycles-popup-text,
  .all-exhibition-cycles-number-label,
  .exhibition-panel-information-text {
    @include panel-details-reduced;
  }
  .pointer-new {
    left: 26px;
  }
}

@media only screen and (max-width: 874px) {
  #exhibitions-container {
    padding: 30px;
  }
  .exhibition-panel,
  #exhibition-cycles-panel {
    flex-direction: column;
  }
    .exhibition-panel-image {
      width: 100%;
    }
    #exhibition-cycles-image {
      height: 425px;
      width: 100%;
      margin-bottom: 15px;
    }
    .exhibition-panel-information {
      width: 100%;
      margin-left: 0;
    }
    #exhibition-cycles-information {
      width: 100%;
      padding-left: 0;
    }
    #exhibition-cycles-title-wrapper,
    #exhibition-cycles-info-wrapper,
    .all-exhibition,
    #all-projects {
      padding-right: 50px;
      padding-left: 50px;
    }
}

@media only screen and (max-width: 874px) {
  .all-projects {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 599px) {
  #exhibition-cycles-image {
    height: 375px;
  }
  #exhibition-cycles-title-wrapper,
  #exhibition-cycles-info-wrapper,
  .all-exhibition,
  #all-projects {
    padding-right: 15px;
    padding-left: 15px;
  }
  .exhibitions {
    @include scroll-top-decreased;
  }
}

@media only screen and (max-width: 499px) {
  #exhibition-cycles-image {
    height: 350px;
  }
  .exhibition-cycles-new-sticker {
    height: 100px;
    width: 100px;
  }
    .exhibition-cycles-new-triangle {
      border-top: 100px solid red;
      border-left: 100px solid transparent;
    }
    .exhibition-cycles-new {
      height: 50px;
      width: 50px;
      font-size: 175%;
    }
}

@media only screen and (max-width: 424px) {
  #exhibitions-container {
    padding: 20px;
  }
  #all-exhibitions-container {
    margin-bottom: 20px;
  }
  .all-projects {
    @include panel-link-smallest;
    margin-bottom: 30px;
  }
  .all-exhibition {
    padding-right: 5px;
    padding-left: 5px;
  }
    .all-exhibition-cycles-number-container {
      padding-right: 15px;
    }
  .pointer {
    width: 60px;
  }
    .pointer-new {
      left: 24px;
    }
}

</style>
