<template>

<div id="social-container">
  <div id="social-wrapper">
    <div id="connect-with-us">Connect with us</div>
    <div id="social-link-wrapper">
      <a class="social-link"
        href="http://www.facebook.com/pages/Museum-With-No-Frontiers/370544525423"
        target="_blank">
        <font-awesome-icon :icon="['fab', 'facebook-square']" />
      </a>
      <a class="social-link"
        href="https://twitter.com/mwnf_org/"
        target="_blank">
        <font-awesome-icon :icon="['fab', 'square-x-twitter']" />
      </a>
       <a class="social-link"
        href="https://www.instagram.com/museumwithnofrontiers/"
        target="_blank">
        <font-awesome-icon :icon="['fab', 'instagram']" />
      </a>
      <a class="social-link" 
        href="https://www.youtube.com/channel/UC5X2a5jDFyIfTEV5prWNF5Q"
        target="_blank">
        <font-awesome-icon :icon="['fab', 'youtube-square']" />
      </a>
      <a class="social-link"
        href="https://www.weibo.com/6540990409/profile?topnav=1&wvr=6&is_all=1"
        target="_blank">
        <font-awesome-icon :icon="['fab', 'weibo']" />
      </a>
      <a class="social-link"
        href="https://www.linkedin.com/company/museum-with-no-frontiers/"
        target="_blank">
        <font-awesome-icon :icon="['fab', 'linkedin']" />
      </a>
    </div>
  </div>
  <div id="newsletter-wrapper">
    <div v-if="onSignUpPage" id="newsletter-unavailable-message">
      <span v-if="signedUp">Thank you!</span>
      <span v-else>Please fill out the form above.</span>
    </div>
    <form v-else>
      <div id="newsletter-label"><a id="newsletter-link" :href="newsletterLink">Newsletter >></a></div>
      <input type="text" 
        id="newsletter-email" 
        name="email" 
        v-model="email"
        placeholder="Enter email to sign up for newsletters">
      <button id="newsletter-email-submit"
        type="submit"
        @click="submit($event)">
        Submit
      </button>
      <div v-if="showNewsletterMessage"
        id="newsletter-message">
        {{ newsletterMessage }}
      </div>
    </form>
    <!-- <a id="newsletter-link" :href="newsletterLink" target="_blank">
      Go to Current Newsletter <font-awesome-icon :icon="['fas', 'external-link-square-alt']" />
    </a> -->
  </div>
</div>

</template>



<script>

import media from "../mixins/media.js";
export default {
  name: "SocialComponent",
  props: ["confirm"],
  mixins: [media],
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      socials: {},
      newsletterLink: "https://www.museumwnf.org/about/newsletter",
      email: "",
      onSignUpPage: null,
      showNewsletterMessage: false,
      newsletterMessage: "",
      signedUp: null,
    }
  },
   watch: {
    "$route" (to, from) {
      this.showNewsletterMessage = false;
      this.newsletterMessage = "";
      this.checkRoute();
    },
    "confirm" () {
      this.signedUp = this.confirm;
    }
  },
  methods: {
    checkRoute: function() {
      if (this.$route.name === "signup") {
        this.onSignUpPage = true;
      } else {
        this.onSignUpPage = false;
      }
    },
    getSocials: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/social" }).then(result => {
        this.socials = result.data.data;
      }, error => {
        console.error(error);
      });
    },
    // getCurrentNewsletter: function() {
    //   return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/newsletter-current" }).then(result => {
    //     this.newsletterLink = result.data.data.actual_page;
    //   }, error => {
    //     console.error(error);
    //   });
    // },
    submit: function(e) {
      e.preventDefault();
      this.$emit("newsletter", this.email);
      return axios({ method: "POST",
        url: process.env.VUE_APP_API_ENDPOINT + "/newsletter",
        data: {
          email: this.email
        } 
      }).then(response => {
        if (response.data === "You Already Subscribed Newsletter.") {
          this.newsletterMessage = "You have already subscribed to the MWNF newsletter.";
          this.showNewsletterMessage = true;
        } else {
          this.$router.push({ name: "signup" });
        }
        // Clear the entered email from the newsletter sign-up at the bottom of the page
        this.email = "";
      }, error => {
        this.newsletterMessage = "Please enter a valid email.";
        this.showNewsletterMessage = true;
        console.error(error);
      });
    }
  },
  mounted() {
    this.getSocials();
    // this.getCurrentNewsletter();
    this.checkRoute();
  }
}

</script>



<style lang="scss">

#social-container {
  display: flex;
  height: 300px;
  width: 100%;
  color: $light-text;
  background-color: $mwnf-color;
}

#social-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 45%;
  padding: 100px;
  padding-right: 20px;
}
#connect-with-us {
  @include panel-header;
  font-weight: bold;
  margin-bottom: 30px;
}
#social-link-wrapper {
  display: flex;
}
  .social-link {
    font-size: 30px;
    color: $light-text;
    margin: 0 10px;
  }
  .social-link:first-child {
    margin-left: 0;
  }

#newsletter-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 55%;
  padding: 100px;
  padding-left: 20px;
}

#newsletter-wrapper form {
  position: relative;
  // height: 100%;
  width: 100%;
}
#newsletter-label {
  @include panel-header;
  font-weight: bold;
  margin-bottom: 18px;
}
  #newsletter-link {
    color: $light-text;
    text-decoration: none;
  }
    #newsletter-link:hover {
      text-decoration: underline;
    }
#newsletter-email {
  height: 50px;
  width: 75%;
  padding: 10px;
  color: $light-text;
  background-color: rgb(65, 55, 136);
  border-radius: 5px 0 0 5px;
  border-style: none;
}
  #newsletter-email:focus {
    outline: none;
  }
  #newsletter-email::placeholder {
    color: $light-text;
    opacity: 0.6;
  }
#newsletter-email-submit {
  @include button-styles;
  display: inline-block;
  height: 50px;
  width: 25%;
  background-color: $bright-yellow;
  border-radius: 0 5px 5px 0;
}

#newsletter-message {
  @include panel-details;
  position: absolute;
  top: 100%;
  left: 0;
  color: $bright-yellow;
  margin-top: 10px;
}

// #newsletter-link {
//   @include panel-details;
//   color: $light-text;
//   text-decoration: none;
//   padding-top: 5px;
// }

@media only screen and (max-width: 1274px) {
  #social-wrapper {
    padding-left: 60px;
  }
  #newsletter-wrapper {
    padding-right: 60px;
  }
}

@media only screen and (max-width: 974px) {
  #social-container {
    height: 250px;
  }
  #connect-with-us,
  #newsletter-label {
    @include panel-header-reduced;
  }
  #newsletter-wrapper form {
    @include panel-details;
  }
}

@media only screen and (max-width: 874px) {
  #social-wrapper {
    width: 40%;
    padding: 0 30px;
  }
  #newsletter-wrapper {
    width: 60%;
    padding: 0 30px;
    // padding-right: 30px;
  }
  #social-link-wrapper {
    display: grid;
    grid-template-rows: repeat(2, 40px);
    grid-template-columns: repeat(3, 50px);
  }
    .social-link {
      font-size: 24px;
      margin-left: 0;
    }
}

@media only screen and (max-width: 599px) {
  #social-container {
    flex-direction: column;
  }
  #social-wrapper,
  #newsletter-wrapper {
    height: 50%;
    width: 100%;
    align-items: center;
    padding: 20px;
  }
  #newsletter-wrapper {
    padding-bottom: 50px;
  }
    #social-wrapper {
      padding-bottom: 0;
    }
  #connect-with-us,
  #newsletter-label {
    margin-bottom: 15px;
  }
  #social-link-wrapper {
    display: flex;
  }
    .social-link {
      margin: 0 10px;
    }
      .social-link:first-child {
        margin-left: 0;
      }
  #newsletter-label {
    @include flex-center;
  }
  #newsletter-message {
    @include flex-center;
    width: 100%;
    margin-top: 5px;
  }
  // #newsletter-link {
  //   align-self: flex-start;
  // }
}

@media only screen and (max-width: 424px) {
  #connect-with-us,
  #newsletter-label {
    @include panel-header-smallest;
  }
}

</style>
