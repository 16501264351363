<template>

<div class="loader-container">
  <!-- Contains CSS animation for loader "icon" -->
  <div class="loader"></div>
  <div class="loading">Loading...</div>
</div>

</template>



<script>

export default { name: "LoaderComponent" }

</script>



<style lang="scss">

.loader-container {
  @include flex-center;
  flex-direction: column;
  width: 100%;
  background-color: white;
}
  .loader {
    height: 50px;
    width: 50px;
    border: 5px solid $background-color;
    border-top: 5px solid $mwnf-color;
    border-radius: 50%;
    margin: 15px;
    animation: spin 1.5s ease-in-out infinite;
  }
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

</style>
