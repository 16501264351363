<template>

<div id="contact-container">
  <LoaderComponent v-if="!loaded" />
  <div v-html="contact.pageContent" v-else></div>
</div>

</template>


<script>

import LoaderComponent from "./LoaderComponent.vue";
import media from "../mixins/media.js";
export default { 
  name: "ContactPage",
  mixins: [media],
  components: {
    "LoaderComponent": LoaderComponent
  },
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      contact: {},
      loaded: false
    }
  },
  methods: {
    getContactInfo: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/atrium_ourteam" }).then(result => {
        this.contact = result.data.data;
        this.loaded = true;
      }, error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.getContactInfo();
  }
}

</script>



<style scoped lang="scss">

#contact-container {
  @include flex-center;
  width: 100%;
  color: $light-text;
  background-color: $about-color;
  padding: 50px;
}
.loader-container {
  height: 100%;
  background-color: $about-color;
}
  #contact-container :deep(a) {
    color: $light-text;
  }

</style>