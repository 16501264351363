<template>

<div id="projects-wrapper">

  <div id="cycles-top-links-container">
    <BackComponent />
    <router-link id="cycles-exhibitions-link" :to="{ name: 'exhibitions', params: { page: '1'} }">
      See also our Exhibitions <font-awesome-icon :icon="['fas', 'share']" />
    </router-link>
  </div>

  <div id="page-navigation" class="page-navigation-wrapper" v-if="pageInfo.last_page > 1">
    <PagesComponent :pageInfo="pageInfo" :navigatePage="navigatePage" :navigatePageDirect="navigatePageDirect"></PagesComponent>
  </div>

  <LoaderComponent v-if="!loaded" />
  <div v-else id="projects-container">
    <div id="projects-title">MWNF EXHIBITION CYCLES</div>
    <div class="project-panel" v-for="(project, key) in projects" :key="key">
      <div class="project-image"
        :class="setBackgroundColor(key)">
        <img :src="project.image" />
        <!-- Optional "NEW" indicator -->
        <div class="project-new-sticker" v-if="project.newStatus">
          <div class="project-new-triangle"></div>
          <div class="project-new">NEW</div>
        </div>
      </div>
      <div class="project-information">
        <div class="project-title-wrapper" :class="setBackgroundColor(key)">
          <a class="project-title" v-if="project.title"
            :href="project.url">
            <div class="project-top-wrapper">
              <div class="project-title-roboto">
                {{ project.title.toUpperCase() }} 
              </div>
              <div class="project-subtitle">
                {{ project.subTitle }}
              </div>
              <div class="project-exhibitions">
                {{ project.exhibitions }} Exhibition<span v-if="project.exhibitions > 1">s</span>
              </div>
            </div>
            <font-awesome-icon :icon="['fas', 'arrow-right']" />
          </a>
        </div>
        <div class="project-info-wrapper" :class="setBackgroundColor(key)">
          <div class="project-languages-container">
            <div class="project-languages-header">Available Languages</div>
            <div class="project-languages">
              <span class="project-language"
                v-for="(language, key, index) in project.languages" :key="key">
                <div>{{ language }}</div>
                <span class="project-comma"
                  v-if="Object.keys(project.languages).length > 1 &&
                  index !== Object.keys(project.languages).length - 1">
                  ,&nbsp;
                </span>
              </span>
            </div>
          </div>
          <div class="project-description" v-html="truncate(200, project.description)"></div>
          <div class="project-full-description" v-if="project.description.length > 200"
            @click="showDescription = true, projectID = key">
            Read More.
          </div>
          <!-- Hide sponsor information until API is updated -->
          <!-- <div class="project-sponsors">
            <span class="project-sponsor"
              v-for="(sponsor, index) in project.sponsors" :key="sponsor.name">
              <a :href="sponsor.web" class="project-sponsor-link">
                <span>{{ sponsor.name }}</span>
              </a>
              <span class="project-comma"
                v-if="project.sponsors.length > 1 &&
                index !== project.sponsors.length - 1">,&nbsp;</span>
            </span>
          </div> -->
        </div>
        <div class="project-description-popup" v-if="showDescription && projectID === key"
          :class="setBackgroundColor(key)">
          <div class="project-popup-close"
            :class="setBackgroundColor(key)"
            @click="showDescription = false">
            <font-awesome-icon :icon="['fas', 'times']"/>
          </div>
          <div class="project-popup-text" v-html="project.description"></div>
        </div>
      </div>
    </div>
  </div>

  <div id="page-navigation" class="page-navigation-wrapper" v-if="pageInfo.last_page > 1">
    <PagesComponent :pageInfo="pageInfo" :navigatePage="navigatePage" :navigatePageDirect="navigatePageDirect"></PagesComponent>
  </div>

</div>

</template>



<script>

import LoaderComponent from "../components/LoaderComponent.vue";
import PagesComponent from "../components/PagesComponent.vue";
import BackComponent from "../components/BackComponent.vue";
import functions from "../mixins/functions.js";
import media from "../mixins/media.js";
export default {
  name: "CyclesPage",
  components: {
    "PagesComponent": PagesComponent,
    "BackComponent": BackComponent,
    "LoaderComponent": LoaderComponent
  },
  mixins: [functions, media],
  data() {
    return {
      projects: {},
      showDescription: false,
      projectID: null,
      pageInfo: {},
      pages: {},
      loaded: false,
    }
  },
  watch: {
    $route(to, from) {
      this.getProjects();
    }
  },
  methods: {
    getProjects: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + `/projects?page=${this.$route.params.page}` }).then(result => {
        this.projects = result.data.data.projects;
        this.pageInfo = result.data.data.meta;
        this.pages = result.data.data.links;
        this.loaded = true;
      }, error => {
        console.error(error);
      });
    },
    // New Projects must be added here (applies classes with Project colors)
    setBackgroundColor: function(id) {
      return {
        "isl": id === "dia",
        "bar": id === "dba",
        "awe": id === "sh",
        "usa": id === "usa",
        "rus": id === "rus"
      }
    },
     getPageNumber: function(url) {
      let start = url.lastIndexOf("=") + 1;
      return url.substring(start);
    },
    navigatePage: function(page) {
      let number;
      if (page === "first") {
        number = this.getPageNumber(this.pages.first);
      } else if (page === "last") {
        number = this.getPageNumber(this.pages.last);
      } else if (page === "next") {
        number = this.getPageNumber(this.pages.next);
      } else if (page === "previous") {
        number = this.getPageNumber(this.pages.prev);
      }
      this.$router.push({ name: "projects", params: { page: number } });
    },
    // Navigate directly to a page by clicking a number
    navigatePageDirect: function(page) {
      this.$router.push({ name: "projects", params: { page: page } });
    },
  },
  mounted() {
    this.getProjects();
  }
}

</script>



<style lang="scss">

#projects-wrapper {
  width: 100%;
  padding: 50px 100px;
}

#cycles-top-links-container {
  display: flex;
  width: 100%;
}
  #cycles-exhibitions-link {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    font-size: 14px;
    color: black;
    font-weight: bold;
    text-decoration: none;
    padding: 2px 8px 2px 0;
    .fa-share {
      margin-left: 4px;
    }
  }

.loader-container {
  height: 350px;
  width: 100%;
}

#projects-container {
  @include flex-center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

#projects-title {
  @include panel-header;
  @include roboto;
  justify-self: flex-start;
  width: 100%;
  font-weight: bold;
}

.project-panel {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
}

// $projects-color is fallback
// For every new Project, add to _variables.scss
// Look for .isl/.bar/.awe classes to find all the places to add new colors

.project-image {
  position: relative;
  height: 250px;
  width: 35%;
  background-color: $projects-color;
  border-right: 20px solid $projects-color;
  border-left: 20px solid $projects-color;
}
  .project-image.isl {
    background-color: $dia-color;
    border-right: 20px solid $dia-color;
    border-left: 20px solid $dia-color;
  }
  .project-image.bar {
    background-color: $dba-color;
    border-right: 20px solid $dba-color;
    border-left: 20px solid $dba-color;
  }
  .project-image.awe {
    background-color: $sh-color;
    border-right: 20px solid $sh-color;
    border-left: 20px solid $sh-color;
  }
  .project-image.usa {
    background-color: $usa-color;
    border-right: 20px solid $usa-color;
    border-left: 20px solid $usa-color;
  }
  .project-image.rus {
    background-color: $rus-color;
    border-right: 20px solid $rus-color;
    border-left: 20px solid $rus-color;
  }
  .project-image img {
    @include image-scale;
    object-fit: contain;
  }

.project-new-sticker {
  height: 100px;
  width: 100px;
  position: absolute;
  top: 0;
  right: -20px;
}
  .project-new-triangle {
    position: absolute;
    top: 0;
    right: 0;
    height: 0;
    width: 0;
    border-top: 100px solid red;
    border-left: 100px solid transparent;
  }
  .project-new {
    @include flex-center;
    height: 50px;
    width: 50px;
    position: absolute;
    top: 0;
    right: -15%;
    transform: rotate(45deg);
    transform-origin: top left;
    color: $light-text;
    font-size: 175%;
    font-weight: bold;
  }

.project-information {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // min-height: 250px;
  width: 65%;
  padding-left: 30px;
}

.project-title-roboto {
  @include roboto;
}

.project-title-wrapper {
  padding: 8px;
}
// Text color matters - if darker color, $light-text (white)
// If lighter color, $dark-text (black)
.project-title-wrapper,
.project-title-wrapper > * {
  color: $dark-text;
  background-color: $projects-color;
}
.project-title-wrapper.isl,
.project-title-wrapper.isl > * {
  color: $dia-text;
  background-color: $dia-color;
}
.project-title-wrapper.bar,
.project-title-wrapper.bar > * {
  color: $light-text;
  background-color: $dba-color;
}
.project-title-wrapper.awe,
.project-title-wrapper.awe > * {
  color: $light-text;
  background-color: $sh-color;
}
.project-title-wrapper.usa,
.project-title-wrapper.usa > * {
  color: $dark-text;
  background-color: $usa-color;
}
.project-title-wrapper.rus,
.project-title-wrapper.rus > * {
  color: $dark-text;
  background-color: $rus-color;
}
  .project-featured {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .project-title {
    @include link-styles;
    @include panel-link;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    font-weight: bold;
  }
    .project-subtitle {
      font-size: 14px;
      font-weight: normal;
    }
    .project-exhibitions {
      font-size: 18px;
      margin-top: 5px;
    }

.project-info-wrapper {  
  height: 100%;
  padding: 18px 8px;
}
  .project-info-wrapper,
  .project-info-wrapper > * {
    background-color: $projects-light-color;
  }
  .project-info-wrapper.isl,
  .project-info-wrapper.isl > * {
    background-color: $dia-light-color;
  }
  .project-info-wrapper.bar,
  .project-info-wrapper.bar > * {
    background-color: $dba-light-color;
  }
  .project-info-wrapper.awe,
  .project-info-wrapper.awe > * {
    background-color: $sh-light-color;
  }
  .project-info-wrapper.usa,
  .project-info-wrapper.usa > * {
    background-color: $usa-light-color;
  }
  .project-info-wrapper.rus,
  .project-info-wrapper.rus > * {
    background-color: $rus-light-color;
  }

.project-languages-header {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}
.project-languages {
  @include panel-details;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
  .project-language {
    display: flex;
  }
  .project-comma {
    margin-right: 3px;
  }

.project-description {
  @include panel-details;
  margin-bottom: 5px;
}
  .project-full-description {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }

.project-sponsors {
  @include panel-details;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  margin-top: 15px;
}
  .project-sponsor {
    display: flex;
  }
  .project-sponsor-link {
    display: flex;
  }

.project-description-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: $projects-color;
  border: 2px solid white;
  border-radius: 5px;
  padding: 10px;
  z-index: 50;
}
  .project-description-popup.isl {
    background-color: $dia-color;
  }
  .project-description-popup.bar {
    background-color: $dba-color;
  }
  .project-description-popup.awe {
    background-color: $sh-color;
  }
  .project-description-popup.usa {
    background-color: $usa-color;
  } 
  .project-description-popup.rus {
    background-color: $rus-color;
  }
  .project-popup-close {
    @include flex-center;
    position: absolute;
    top: -20px;
    right: -20px;
    height: 40px;
    width: 40px;
    background-color: $projects-color;
    border: 2px solid white;
    border-radius: 100%;
    cursor: pointer;
  }
    .project-popup-close.isl {
      color: $dia-text;
      background-color: $dia-color;
    }
    .project-popup-close.bar {
      color: $light-text;
      background-color: $dba-color;
    }
    .project-popup-close.awe {
      color: $light-text;
      background-color: $sh-color;
    }
    .project-popup-close.usa {
      color: $dark-text;
      background-color: $usa-color;
    }
    .project-popup-close.rus {
      color: $dark-text;
      background-color: $rus-color;
    }
  .project-popup-text {
    @include panel-details;
    background-color: $background-color;
    padding: 20px;
  }

@media only screen and (max-width: 1099px) {
  #projects-wrapper {
    padding: 20px 50px 50px 50px;
  }
}

@media only screen and (max-width: 749px) {
  .project-panel {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .project-image {
    width: 100%;
    margin-bottom: 10px;
  }
  .project-information {
    min-height: 0;
    width: 100%;
    padding-left: 0;
  }
}

@media only screen and (max-width: 599px) {
  #projects-wrapper {
    padding: 10px 30px 30px 30px;
  }
}

@media only screen and (max-width: 499px) {
  #projects-wrapper {
    padding: 10px 20px 20px 20px;
  }
}

</style>
