<template>

<div id="commemoration-container">
  <LoaderComponent v-if="!loaded" />
  <div id="commemoration-wrapper" v-else>
    <div id="commemoration-title">{{ info.pageHeading }}</div>
    <div id="commemoration-description" v-html="info.pageText"></div>
    <div class="colleague"
      v-for="colleague in commemoration" :key="colleague.name">
      <div class="colleague-image">
        <img :src="colleague.image" />
      </div>
      <div class="colleague-info">
        <div>{{ colleague.name }}</div>
        <div>{{ colleague.date }}</div>
        <div v-html="colleague.description"></div>
      </div>
    </div>
  </div>
</div>

</template>


<script>

import LoaderComponent from "./LoaderComponent.vue";
import media from "../mixins/media.js";
export default { 
  name: "CommemorationPage",
  mixins: [media],
  components: {
    "LoaderComponent": LoaderComponent
  },
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      info: {},
      commemoration: [],
      loaded: false
    }
  },
  methods: {
    getCommemorationInfo: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/commemoration" }).then(result => {
        this.info = result.data.data;
        this.commemoration = result.data.items;
        this.commemoration.sort(function (a, b) { return b.deathDate - a.deathDate });
        this.loaded = true;
      }, error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.getCommemorationInfo();
  }
}

</script>



<style scoped lang="scss">

#commemoration-container {
  @include flex-center;
  flex-direction: column;
  width: 100%;
  color: $light-text;
  background-color: $about-color;
  padding: 50px;
}
#commemoration-wrapper {
  @include flex-center;
  flex-direction: column;
}
.loader-container {
  height: 100%;
  background-color: $about-color;
}

#commemoration-title {
  @include about-title;
  @include flex-center;
  margin-bottom: 15px;
}

// #commemoration-description {

// }

.colleague {
  display: flex;
  width: 50%;
  padding: 20px 0;
}
  .colleague :deep(a) {
    color: $light-text;
  }
  .colleague-image {
    height: 150px;
    width: 120px;
  }
    .colleague-image img {
      @include image-scale;
      object-fit: cover;
    }
  .colleague-info {
    width: calc(100% - 120px);
    padding-left: 20px;
  }

@media only screen and (max-width: 1199px) {
  .colleague {
    width: 75%;
  }
}

@media only screen and (max-width: 799px) {
  .colleague {
    width: 100%;
  }
}

@media only screen and (max-width: 574px) {
  .colleague {
    @include flex-center;
    flex-direction: column;
  }
  .colleague-image {
    margin-bottom: 20px;
  }
  .colleague-info {
    width: 100%;
    padding-left: 0;
  }
}

@media only screen and (max-width: 449px) {
  #commemoration-container {
    padding: 30px;
  }
}

</style>