<template>

<div id="related-panels-container">
  <a id="related" name="related">RELATED</a>
  <div id="related-panels">
    <div class="related-panel" v-for="panel in panels" :key="panel.header">
      <a class="related-panel-url" :href="panel.url">
        <div class="related-panel-image">
          <img :src="`${panel.image}`" />
        </div>
        <div class="related-panel-texts">
          <div class="related-panel-header">{{ panel.header.toUpperCase() }}</div>
          <div class="related-panel-text">{{ panel.text }}</div>
          <div class="related-panel-arrow">
            <font-awesome-icon :icon="['fas', 'arrow-right']" />
          </div>
        </div>
      </a>
    </div>
  </div>
</div>

</template>



<script>

import media from "../mixins/media.js";
export default {
  name: "RelatedComponent",
  mixins: [media],
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      panels: []
    }
  },
  methods: {
    getPanels: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/panel" }).then(result => {
        this.panels = result.data.data;
      }, error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.getPanels();
  }
}

</script>



<style lang="scss">

#related-panels-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 450px;
  width: 100%;
  padding: 50px 100px 0 100px;
}

.related-panel-header {
  @include roboto;
}

#related {
  @include panel-header;
  @include scroll-top;
  font-weight: bold;
  margin-bottom: 30px;
}
#related-panels {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 100px;
  height: 100%;
  width: 100%;
}
  .related-panel {
    height: 100%;
    width: 100%;
  }
    .related-panel-url {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      color: $light-text;
      text-decoration: none;
    }

.related-panel-image {
  height: 60%;
  width: 100%;
  background-color: $shadow-grey;
}
  .related-panel-image img {
    @include image-scale;
    object-fit: cover;
  }
.related-panel-texts {
  height: 40%;
  width: 100%;
  background-color: $mwnf-color;
  padding: 15px 30px;
}

.related-panel-header {
  @include panel-link;
  font-weight: bold;
}
.related-panel-text {
  @include panel-details;
  margin-bottom: 10px;
}
.related-panel-arrow {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  color: $bright-yellow;
}

@media only screen and (max-width: 1274px) {
  #related-panels-container {
    padding: 50px 60px 0 60px;
  }
  #related-panels {
    grid-gap: 75px;
  }
}

@media only screen and (max-width: 1199px) {
  #related {
    @include scroll-top-increased;
  }
  #related-panels {
    grid-gap: 60px;
  }
}

@media only screen and (max-width: 1099px) {
  #related-panels {
    grid-gap: 50px;
  }
}

@media only screen and (max-width: 974px) {
  #related {
    @include panel-header-reduced;
  }
  .related-panel-header {
    @include panel-link-reduced;
  }
  .related-panel-text {
    @include panel-details-reduced;
  }
  #related-panels {
    grid-gap: 30px;
  }
  .related-panel-image {
    height: 68%;
  }
  .related-panel-texts {
    height: 32%;
    padding: 12px 20px;
  }
}

@media only screen and (max-width: 874px) {
  #related-panels-container {
    height: 400px;
    padding: 30px 30px 0 30px;
  }
    .related-panel-image {
      height: 65%;
    }
    .related-panel-texts {
      height: 35%;
    }
}

@media only screen and (max-width: 749px) {
  #related-panels-container {
    height: auto;
  }
  #related-panels {
    display: flex;
    flex-direction: column;
  }
    .related-panel {
      position: relative;
      height: 250px;
    }
    .related-panel-image {
      height: 100%;
    }
    .related-panel-texts {
      position: absolute;
      bottom: 0;
      height: 30%;
      background-color: rgba($mwnf-color, 0.8);
      padding: 15px 100px;
    }
      .related-panel-arrow {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 100px;
        height: 100%;
      }
}

@media only screen and (max-width: 599px) {
  #related {
    @include scroll-top-decreased;
  }
  .related-panel-texts {
    padding: 15px 60px;
  }
    .related-panel-arrow {
      right: 60px;
    }
}

@media only screen and (max-width: 499px) {
  .related-panel {
    height: 200px;
  }
  .related-panel-texts {
    padding: 10px 50px;
  }
    .related-panel-text {
      margin: 0;
    }
    .related-panel-arrow {
      right: 50px;
    }
}

@media only screen and (max-width: 424px) {
  #related-panels-container {
    padding: 20px 20px 0 20px;
  }
  .related-panel-texts {
    padding: 10px 20px;
  }
    .related-panel-arrow {
      right: 20px;
    }
}

</style>
