<template>

<div id="learn-container">
  <a id="learning" name="learning">LEARNING RESOURCES</a>
  <div id="learn-panel-container">
    <div id="learn-image">
      <img :src="resource.image" />
      <!-- Optional "NEW" indicator -->
      <div class="learn-new-sticker" v-if="resource.newSticker">
        <div class="learn-new-triangle"></div>
        <div class="learn-new">NEW</div>
      </div>
    </div>
    <div id="learn-resource-container" :dir="resource.displayLang === 'ar' ? 'rtl' : 'ltr'">
      <div id="learn-resource-featured">FEATURED RESOURCE</div>
      <a id="learn-resource-title"
        v-if="resource.title"
        :href="resource.resourceLink" target="_blank" download>
        <div id="learn-resource-type-title"
          :class="{ 'rtl' : resource.displayLang === 'ar' }">
          <span v-if="resource.resourceType === 'Videos'">
            <font-awesome-icon :icon="['fab', 'youtube-square']" />
          </span>
          <span v-else-if="resource.resourceType === 'PDF'">
            <font-awesome-icon :icon="['fas', 'file-pdf']" />
          </span>
          <span v-else-if="resource.resourceType === 'Books'">
            <font-awesome-icon :icon="['fas', 'external-link-square-alt']" />
          </span>
          <span v-else>
            <font-awesome-icon :icon="['fas', 'file-download']" />
          </span>
          <!-- {{ resource.title.toUpperCase() }} -->
          {{ resource.title }}
        </div>
        <font-awesome-icon v-if="resource.displayLang === 'ar'" :icon="['fas', 'arrow-left']" />
        <font-awesome-icon v-else :icon="['fas', 'arrow-right']" />
      </a>
      <div id="learn-resource-subtitle"
        v-if="resource.subTitle && resource.subTitle.length > 0">
        {{ resource.subTitle }}
      </div>
      <div id="learn-resource-description" 
        v-html="truncate(200, resource.description)"></div>
      <div id="learn-full-description"
        v-if="resource.description && resource.description.length > 200"
        @click="showDescription = true">
        Read More.
      </div>
      <div id="learn-description-popup" v-if="showDescription">
        <div id="learn-popup-close"
          @click="showDescription = false">
          <font-awesome-icon :icon="['fas', 'times']"/>
        </div>
        <div id="learn-popup-text" v-html="resource.description"></div>
      </div>
      <div id="learn-resource-type-container" class="learn-resource-line">
        <div class="learn-resource-label">Type | </div>
        <div class="learn-resource">{{ resource.resourceType }}</div>
      </div>
      <div id="learn-resource-authors-container" class="learn-resource-line"
        v-if="resource.author && resource.author.length > 0">
        <div class="learn-resource-label">Author(s) | </div>
        <div class="learn-resource">{{ resource.author }}</div>
      </div>
      <div id="learn-resource-reference-container" class="learn-resource-line">
        <div class="learn-resource-label">Topic | </div>
        <div class="learn-resource">{{ resource.reference }}</div>
      </div>
      <div id="learn-resource-languages-container" class="learn-resource-line">
        <div class="learn-resource-label">Languages | </div>
        <div id="learn-resource-languages">
          <div class="learn-resource learn-resource-language"
            v-for="(language, index) in resource.languages" :key="language">
            {{ language }}
            <span v-if="Object.keys(resource.languages).length > 1 && index < Object.keys(resource.languages).length">, </span>
          </div>
        </div>
      </div>
      <div id="learn-resource-target-container" class="learn-resource-line"
        v-if="resource.target && resource.target.length > 0">
        <div class="learn-resource-label">Target | </div>
        <div class="learn-resource">{{ resource.target }}</div>
      </div>
      <div id="all-learn-resources">
        <router-link :to="{ name: 'resources', params: { page: '1', language: 'all'} }">
          <!-- <div>SEE ALL RESOURCES</div> -->
          <div>See All Resources</div>
          <font-awesome-icon v-if="resource.displayLang === 'ar'" :icon="['fas', 'arrow-left']" />
          <font-awesome-icon v-else :icon="['fas', 'arrow-right']" />
        </router-link>
      </div>
    </div>
  </div>
</div>

</template>



<script>

import functions from "../mixins/functions.js";
import media from "../mixins/media.js";
export default {
  name: "LearnComponent",
  mixins: [functions, media],
  data() {
    return {
      lessThan399: window.matchMedia("(max-width: 399px)").matches,
      resource: {},
      showDescription: false
    }
  },
  methods: {
    getResource: function() {
      return axios({ method: "GET", "url": process.env.VUE_APP_API_ENDPOINT + "/learn" }).then(result => {
        this.resource = result.data.data;
      }, error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.getResource();
  }
}

</script>



<style lang="scss">

#learn-container {
  width: 100%;
  background-color: $learning-color;
  padding: 50px 100px;
}

#learn-resource-type-title {
  @include roboto;
}

#learning {
  @include panel-header;
  @include scroll-top;
  display: block;
  font-weight: bold;
  margin-bottom: 15px;
}

#learn-panel-container {
  position: relative;
  display: flex;
  width: 100%;
}

#learn-image {
  @include flex-center;
  position: relative;
  height: 450px;
  width: 50%;
  background-color: rgba($mwnf-color, 1);
}
  #learn-image img {
    @include image-scale;
    object-fit: contain;
  }

.learn-new-sticker {
  height: 150px;
  width: 150px;
  position: absolute;
  top: 0;
  right: 0;
}
  .learn-new-triangle {
    position: absolute;
    top: 0;
    right: 0;
    height: 0;
    width: 0;
    border-top: 150px solid red;
    border-left: 150px solid transparent;
  }
  .learn-new {
    @include flex-center;
    height: 75px;
    width: 75px;
    position: absolute;
    top: 0;
    right: -15%;
    transform: rotate(45deg);
    transform-origin: top left;
    color: $light-text;
    font-size: 200%;
    font-weight: bold;
  }

#learn-resource-container {
  width: 50%;
  padding-left: 30px;
}

#learn-resource-featured {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}
  #learn-resource-featured span {
    @include italic;
  }
#learn-resource-title {
  @include panel-link;
  @include link-styles;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: $dark-text;
  margin-bottom: 5px;
}
  #learn-resource-title span {
    color: rgba($mwnf-color, 0.7);
    margin-right: 5px;
    margin-left: 0;
  }
  #learn-resource-title .rtl span {
    margin-right: 0;
    margin-left: 5px;
  }
#learn-resource-subtitle {
  font-size: 14px;
  margin-bottom: 20px;
}
#learn-resource-description {
  @include panel-details;
  margin-bottom: 20px;
}
  #learn-full-description {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 20px;
  }
  #learn-description-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    background-color: $mwnf-color;
    border: 2px solid white;
    border-radius: 5px;
    padding: 10px;
    z-index: 50;
  }
    #learn-popup-close {
      @include flex-center;
      position: absolute;
      top: -20px;
      right: -20px;
      height: 40px;
      width: 40px;
      color: $light-text;
      background-color: $mwnf-color;
      border: 2px solid white;
      border-radius: 100%;
      cursor: pointer;
    }
    #learn-popup-text {
      @include panel-details;
      background-color: $background-color;
      padding: 20px;
    }
  .learn-resource-line {
    @include panel-details;
    display: flex;
    width: 100%;
    white-space: pre;
    margin-bottom: 5px;
  }
  .learn-resource-label {
    font-weight: bold;
  }
  .learn-resource {
    white-space: break-spaces;
  }
  .learn-resource-author {
    @include panel-details;
  }
  #learn-resource-languages {
    @include panel-details;
    display: flex;
  }
    .learn-resource-language {
      margin: 0 10px;
    }
    .learn-resource-language:first-child {
      margin-left: 0;
    }
    .learn-resource-language:last-child {
      margin-right: 0;
    }
  #learn-resource-target {
    @include panel-details;
  }
  
  
#all-learn-resources {
  @include panel-link;
  width: 100%;
  margin-top: 20px;
}
  #all-learn-resources a {
    @include link-styles;
    display: flex;
    justify-content: space-between;
    color: black;
  }

@media only screen and (max-width: 1274px) {
  #learn-container {
    padding: 50px 60px;
  }
}

@media only screen and (max-width: 1199px) {
  #learning {
    @include scroll-top-increased;
  }
}

@media only screen and (max-width: 974px) {
  #learning {
    @include panel-header-reduced;
  }
  #learn-resource-title,
  #all-learn-resources {
    @include panel-link-reduced;
  }
  #learn-resource-description,
  #learn-popup-text,
  .learn-resource-line,
  .learn-resource-author,
  #learn-resource-languages,
  #learn-resource-target {
    @include panel-details-reduced;
  }
  #learn-image {
    height: 400px;
  }
}

@media only screen and (max-width: 874px) {
  #learn-container {
    padding: 30px;
  }
  #learn-panel-container {
    flex-direction: column;
  }
    #learn-image {
      width: 100%;
      margin-bottom: 15px;
    }
    #learn-resource-container {
      width: 100%;
      padding: 0 50px;
    }
}

@media only screen and (max-width: 599px) {
  #learning {
    @include scroll-top-decreased;
  }
  #learn-image {
    height: 375px;
  }
  #learn-resource-container {
    padding: 0;
  }
}

@media only screen and (max-width: 499px) {
  #learn-image {
    height: 350px;
  }
  .learn-new-sticker {
    height: 100px;
    width: 100px;
  }
    .learn-new-triangle {
      border-top: 100px solid red;
      border-left: 100px solid transparent;
    }
    .learn-new {
      height: 50px;
      width: 50px;
      font-size: 175%;
    }
}

@media only screen and (max-width: 424px) {
  #learn-container {
    padding: 30px 20px;
  }
  #learning {
    @include panel-header-smallest;
  }
  #learn-resource-title,
  #all-learn-resources {
    @include panel-link-smallest;
  }
}

</style>
