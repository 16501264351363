<template>

<!-- Component for paginated content -->
<div id="page-navigation-container">
  <button 
    id="page-first" class="page-navigation" 
    :class="{pageDisabled: isDisabled(1)}" 
    @click="navigatePage('first')" 
    :disabled="isDisabled(1)">&lt;&lt;<span v-if="!lessThan499"> First</span>
  </button>
  <button 
    id="page-previous" class="page-navigation" 
    :class="{pageDisabled: isDisabled(pageInfo['current_page'] - 1)}"
    @click="navigatePage('previous')" 
    :disabled="isDisabled(pageInfo['current_page'] - 1)">&lt;<span v-if="!lessThan499"> Previous</span>
  </button>
  <div id="page-numbers-container">
    <button 
      class="page-navigation" 
      :class="{pageDisabled: isDisabled(index + 1)}" 
      v-for="(page, index) in pageRange" :key="page + index" 
      @click="navigatePageDirect(page)" 
      :disabled="isDisabled(page)">{{ page }}
    </button>
  </div>
  <button 
    id="page-next" class="page-navigation" 
    :class="{pageDisabled: isDisabled(pageInfo['current_page'] + 1)}" 
    @click="navigatePage('next')" 
    :disabled="isDisabled(pageInfo['current_page'] + 1)"><span v-if="!lessThan499">Next </span>&gt;
  </button>
  <button 
    id="page-last" class="page-navigation" 
    :class="{pageDisabled: isDisabled(pageInfo['last_page'])}" 
    @click="navigatePage('last')" 
    :disabled="isDisabled(pageInfo['last_page'])"><span v-if="!lessThan499">Last </span>&gt;&gt;
  </button>
</div>

</template>



<script>

import media from "../mixins/media.js";
export default { 
  name: "PagesComponent",
  props: {
    pageInfo: Object, 
    navigatePage: Function, 
    navigatePageDirect: Function
  },
  mixins: [media],
  data() {
    return {
      lessThan849: window.matchMedia("(max-width: 849px)").matches,
      lessThan724: window.matchMedia("(max-width: 724px)").matches,
      lessThan599: window.matchMedia("(max-width: 599px)").matches,
      lessThan499: window.matchMedia("(max-width: 499px)").matches,
    }
  },
  computed: {
    // Display up to 15 pages at once
    // If possible, current page is the "middle" of range
    pageRange: function() {
      let min = 1;
      let mid = this.pageInfo["current_page"];
      let max;
      let returned = [];
      if (this.lessThan599) {
        if (this.pageInfo["last_page"] <= 5) {
          returned = [1, this.pageInfo["last_page"]];
        } else {
          returned = this.setRange(min, mid, max, 3, 2, 5);
        }
      } else if (this.lessThan724) {
        if (this.pageInfo["last_page"] <= 9) {
          returned = [1, this.pageInfo["last_page"]];
        } else {
          returned = this.setRange(min, mid, max, 5, 4, 9);
        }
      } else if (this.lessThan849) {
        if (this.pageInfo["last_page"] <= 11) {
          returned = [1, this.pageInfo["last_page"]];
        } else {
          returned = this.setRange(min, mid, max, 6, 5, 11);
        }
      } else {
        if (this.pageInfo["last_page"] <= 15) {
          returned = [1, this.pageInfo["last_page"]];
        } else {
          returned = this.setRange(min, mid, max, 8, 7, 15);
        }
      }
      min = returned[0];
      max = returned[1];
      let array = [];
      for (let i = min; i <= max; i++) {
        array.push(i);
      }
      return array;
    }
  },
  methods: {
    setRange: function(min, mid, max, middle, lesserHalf, maximum) {
      // Check if current page is greater than provided "middle" of range
      // Ex. "middle" of 15 is 8, "middle" of 9 is 5
      // If so, set min to the first number of range where the current page is in the "middle" position
      // Ex. out of 15 pages, if the current page is 10, it is greater than 8 (the "middle")
      // The starting number for a 15-page range with 10 in the "middle" is 3
      // 3, 4, 5, 6, 7, 8, 9, [10], 11, 12, 13, 14, 15, 16, 17
      // Else, min stays at 1 (set earlier)
      if (mid > middle) {
        min = mid - lesserHalf;
      }
      // Check if current page + half of the range will not exceed the total number of pages
      // If not, max is set to the last available page
      if ((mid + lesserHalf) < this.pageInfo["last_page"]) {
        // If numbers are within the initial page range,
        // the maximum stays the same until the mid-point is passed
        // Ex. out of 15 pages, current pages up to 8 will keep 15 as the max
        // Current page of 9 will change the max (to 16)
        if (min <= lesserHalf && mid <= lesserHalf) {
          max = maximum;
        } else {
          max = mid + lesserHalf;
        }
      // Change min to move "backwards" using the last page
      // in order to always show the same number of pages
      } else {
        min = this.pageInfo["last_page"] - (maximum - 1);
        max = this.pageInfo["last_page"];
      }
      return [min, max];
    },
    // Disable buttons for current/invalid page(s)
    isDisabled: function(pageNumber) {
      let current = this.pageInfo["current_page"];
      if (pageNumber < 1) {
        return true;
      }
      if (pageNumber > this.pageInfo["last_page"]) {
        return true;
      }
      if (current === pageNumber) {
        return true;
      } else {
        return false;
      }
    },
  },
}

</script>



<style scoped lang="scss">

#top-page-navigation #page-navigation-container {
  margin-top: 30px;
  margin-bottom: 0;
}

#page-navigation-container {
  @include flex-center;
  margin-bottom: 30px;
}
  .page-navigation {
    @include button-styles;
    text-decoration: underline;
    padding: 5px;
    margin: 0 5px;
  }
    #newsletter-page-navigation button:not(:disabled) {
      color: $light-text;
    }
  #page-numbers-container {
    @include flex-center;
  }
  .pageDisabled {
    cursor: default;
  }

</style>